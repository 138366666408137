import { apiRequest } from "../utils/apiRequest";
import axios from "axios";
import uploadFileTypes from "./uploadFileTypes";
import { useAuth } from "../../utils/api";
import { PDFDocument } from "pdf-lib";

const { REACT_APP_API_ENDPOINT } = process.env;

const bulkUploadDocumentSuccess = (authData) => {
  return {
    type: "BULK_UPLOAD_DOCUMENT_SUCCESS",
    payload: authData,
  };
};

const bulkUploadDocumentError = (authData) => {
  return {
    type: "BULK_UPLOAD_DOCUMENT_FAILURE",
    payload: authData,
  };
};

const bulkUploadDocumentRender = (authData) => {
  return {
    type: "BULK_UPLOAD_DOCUMENT_RENDER",
    payload: authData,
  };
};

export const actionBulkUploadDocumentRender = () => async (dispatch) => {
  dispatch(bulkUploadDocumentRender());
};

export const bulkUploadDocument = (params) => async (dispatch) => {
  const bulkUpload = await apiRequest(
    dispatch,
    "BULK_UPLOAD_DOCUMENT",
    "post",
    "/annotation/document",
    params,
    "multipart/form-data; boundary=---011000010111000001101001"
  );
  if (bulkUpload.type === "BULK_UPLOAD_DOCUMENT_SUCCESS") {
    dispatch(bulkUploadDocumentSuccess(bulkUpload));
  } else {
    dispatch(bulkUploadDocumentError(bulkUpload));
  }
};

export const setUploadFile = (data) => ({
  type: uploadFileTypes.SET_UPLOAD_FILE,
  payload: data,
});

export const setUploadProgress = (id, progress, numPages) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id) => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  payload: id,
});

const reloadList = async (dispatch) => {
  let result = await apiRequest(
    dispatch,
    "DOCUMENT_LIST_UPDATE",
    "get",
    "/annotation/documents"
  );

  if (result.type === `DOCUMENT_LIST_UPDATE_SUCCESS`) {
    dispatch({
      type: "LIST_DOCUMENT_SUCCESS",
      payload: result.payload,
    });
  }
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const getNumPages = async (file) => {
  const arrayBuffer = await readFile(file);
  const pdf = await PDFDocument.load(arrayBuffer);
  return pdf.getPages();
};

export const uploadFile = (files) => async (dispatch) => {
  if (files.length) {
    files.forEach(async (file) => {
      const formPayload = new FormData();
      formPayload.append("file", file.file);
      const accessToken = await useAuth();
      let numPages = await getNumPages(file.file);
      formPayload.append("numPages", numPages.length);
      console.log(formPayload, numPages);
      try {
        let result = await axios({
          baseURL: REACT_APP_API_ENDPOINT,
          url: "/annotation/document",
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          data: formPayload,
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch(setUploadProgress(file.id, percentageProgress));
            // getNumPages(file.file).then((e) => {
            //   dispatch(
            //     setUploadProgress(file.id, percentageProgress, e.length)
            //   );
            // });
          },
        });
        dispatch(successUploadFile(file.id));
        reloadList(dispatch);
        dispatch(bulkUploadDocumentRender());
      } catch (error) {
        dispatch(failureUploadFile(file.id));
        console.log(error.response);
        dispatch(bulkUploadDocumentError(error));
      }
    });
  }
};
