import { cloneDeep } from "lodash";
import { compact } from "../../utils/annotation";

const initialState = {
  taskId: "",
  taskName: "",
  type: "",
  annotator: {
    id: "",
    name: "",
  },
  annotators: [],
  takenBoundaries: [],
  displayedAnnotators: [],
  documentId: "",
  sectionId: "",
  sectionName: "",
  lastUpdateDate: "",
  sections: {},
  notes: [],
  selectedSection: "",
  selectedBoundingBox: {},
  selectedPage: {},
  selectedField: {
    annotationItemIndex: null,
    keyMap: null,
    type: "",
  },
  annotatedField: {},
  formulatedField: {},
  taskStatus: "",
  annotationTable: [],
  chapterList: [],
  selectedChapter: {},
  isErrorSectionAnnotation: false,
  isErrorVariableAnnotation: false,
  sectionListScrollPosition: 0,
  variableFormScrollPosition: 0,
  reviewDetails: {
    incomplete: [],
    accepted: [],
    rejected: []
  },
  suggestions: {
    person: { }
  },
  movedAnnotationItem: {
    variableId: null,
    variableName: null,
    from: null,
    to: null,
    fieldName: null,
    annotationItemIndex: null
  },
  expandedAnnotationItems: [],
  expandedAnnotationFields: [],
  storedFormData: {},
  reportScrollPosition: 0,
  noteScrollPosition: 0,
};

const annotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASK_DATA_SUCCESS":
      return {
        ...state,
        taskId: action.payload.id,
        taskName: action.payload.name,
        documentId: action.payload.document.id,
        type: action.payload.dtype,
        annotator: {
          ...state.annotator,
          id: action.payload.annotator.id,
          name: action.payload.annotator.profile.name,
        },
        displayedAnnotators: [action.payload.annotator.id],
        sectionId: action.payload.section
          ? action.payload.section.sectionId || action.payload.section.id
          : null,
        sectionName: action.payload.section
          ? action.payload.section.name
          : null,
        lastUpdateDate: action.payload.updatedAt,
        taskStatus: action.payload.status,
      };
    case "GET_TASK_ANNOTATOR_LIST_SUCCESS":
      return {
        ...state,
        annotators: action.payload.reduce((acc, data) => {
          acc[data.annotatorId] = data.annotator;
          return acc;
        }, {}),
      };
    case "SET_TASK_STATUS":
      return {
        ...state,
        taskStatus: action.payload,
      };
    case "SET_SELECTED_SECTION":
      return {
        ...state,
        selectedSection: action.payload,
      };
    case "SETUP_SECTIONS":
      return {
        ...state,
        sections: action.payload,
      };
    case "SETUP_SELECTED_BOUNDING_BOX":
      return {
        ...state,
        selectedBoundingBox: action.payload,
      };
    case "SETUP_SELECTED_PAGE":
      return {
        ...state,
        selectedPage: action.payload,
      };
    case "SET_SELECTED_BOUNDING_BOX":
    case "SET_SELECTED_BOUNDING_BOX_REVIEW":
      return {
        ...state,
        selectedBoundingBox: action.payload,
      };
    case "ADD_SELECTED_BOUNDING_BOX":
      return {
        ...state,
        selectedBoundingBox: {
          ...state.selectedBoundingBox,
          [state.selectedSection]: state.selectedBoundingBox[
            state.selectedSection
          ].concat(action.payload),
        },
      };
    case "REMOVE_SELECTED_BOUNDING_BOX":
      return {
        ...state,
        selectedBoundingBox: {
          ...state.selectedBoundingBox,
          [state.selectedSection]: state.selectedBoundingBox[
            state.selectedSection
          ].filter((node) => node.id !== action.payload),
        },
      };
    case "SET_SELECTED_PAGE":
    case "SET_SELECTED_PAGE_REVIEW":
      return {
        ...state,
        selectedPage: action.payload,
      };
    case "SET_SECTION_ANNOTATION_DATA":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.sectionId]: {
            ...state.sections[action.payload.sectionId],
            annotation: action.payload.data,
          },
        },
      };
    case "SET_DISPLAYED_ANNOTATORS":
      return {
        ...state,
        displayedAnnotators: action.payload,
      };
    case "GET_TASK_NOTE_SUCCESS":
      return {
        ...state,
        notes: action.payload,
      };
    case "SET_VARIABLE_STRUCTURE":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.variableId]: {
            ...state.sections[action.payload.variableId],
            structure: action.payload.data,
          },
        },
      };
    case "SET_VARIABLE_ANNOTATION_ITEMS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.variableId]: {
            ...state.sections[action.payload.variableId],
            annotationItems: action.payload.data,
          },
        },
      };
    case "SET_SELECTED_VARIABLE_FIELD":
      return {
        ...state,
        selectedField: { ...state.selectedField, ...action.payload },
      };
    case "RESET_SELECTED_VARIABLE_FIELD":
      return {
        ...state,
        selectedField: initialState.selectedField,
      };
    case "SETUP_ANNOTATED_VARIABLE_FIELD":
      return {
        ...state,
        annotatedField: action.payload,
      };
    case "SET_ANNOTATED_VARIABLE_FIELD":
      return {
        ...state,
        annotatedField: {
          ...state.annotatedField,
          [action.payload.variableId]: action.payload.data,
        },
      };
    case "ADD_ANNOTATED_VARIABLE_FIELD":
      return {
        ...state,
        annotatedField: {
          ...state.annotatedField,
          [action.payload.variableId]: [
            ...state.annotatedField[action.payload.variableId],
            action.payload.data,
          ],
        },
      };
    case "SET_VARIABLE_ANNOTATION_ID":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.variableId]: {
            ...state.sections[action.payload.variableId],
            annotation: {
              ...state.sections[action.payload.variableId].annotation,
              id: action.payload.id,
            },
          },
        },
      };
    case "SET_VARIABLE_ANNOTATION_STATUS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.variableId]: {
            ...state.sections[action.payload.variableId],
            annotation: {
              ...state.sections[action.payload.variableId].annotation,
              status: action.payload.status,
            },
          },
        },
      };
    case "GET_TASK_DATA_REQUEST":
    case "RESET_TASK_DATA":
    case "LOGOUT_SUCCESS":
      return initialState;
    case "GET_ANNOTATION_TABLE_REQUEST":
      return state;
    case "GET_ANNOTATION_TABLE_SUCCESS":
      return {
        ...state,
        annotationTable: action.payload.tables,
      };
    case "ADD_ANNOTATION_TABLE_REQUEST":
      return state;
    case "ADD_ANNOTATION_TABLE_SUCCESS":
      return state;
    case "DELETE_ANNOTATION_TABLE_REQUEST":
      return state;
    case "DELETE_ANNOTATION_TABLE_SUCCESS":
      return state;
    case "GET_CHAPTER_LIST_OPTION_SUCCESS":
      return {
        ...state,
        chapterList: action.payload.children.map(child => ({ value: child.sectionId, label: child.name }))
      };
    case "SETUP_SELECTED_CHAPTER":
      return {
        ...state,
        selectedChapter: action.payload
      };
    case "CHANGE_SELECTED_CHAPTER":
      return {
        ...state,
        selectedChapter: {
          ...state.selectedChapter,
          [action.payload.sectionId]: action.payload.data
        }
      };
    case "GET_ANNOTATION_VARIABLE_SUCCESS":
      return {
        ...state,
        isErrorVariableAnnotation: false
      };
    case "GET_VARIABLE_DETAIL_FAILURE":
    case "GET_ANNOTATION_VARIABLE_FAILURE":
      return {
        ...state,
        isErrorVariableAnnotation: true
      };
    case "GET_TAKEN_BOUNDARIES_SUCCESS":
      return {
        ...state,
        takenBoundaries: action.payload
      };
    case "GET_ANNOTATION_CHAPTER_FAILURE":
    case "GET_ANNOTATION_SUBCHAPTER_FAILURE":
    case "GET_TAKEN_BOUNDARIES_FAILURE":
      return {
        ...state,
        isErrorSectionAnnotation: true
      };
    case "SET_TAKEN_BOUNDARIES":
      return {
        ...state,
        takenBoundaries: action.payload
      };
    case "SET_SECTION_LIST_SCROLL_POSITION":
      return {
        ...state,
        sectionListScrollPosition: action.payload
      };
    case "SET_VARIABLE_FORM_SCROLL_POSITION":
      return {
        ...state,
        variableFormScrollPosition: action.payload
      };
    case "CHECK_REVIEW_ANNOTATION_SUCCESS":
      return {
        ...state,
        reviewDetails: action.payload.details
      }
    case "GET_ANNOTATION_SUGGESTIONS_SUCCESS": {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          person: action.payload,
        }
      }
    }
    case "APPLY_ANNOTATION_SUGGESTION": {
      const { suggestionName, variableId, index } = action.payload;
      const suggestion = cloneDeep(state.suggestions.person[suggestionName]);
      const currentVariable = cloneDeep(state.sections[variableId].annotationItems);
      currentVariable[index] = suggestion;
      return {
        ...state,
        sections: {
          ...state.sections,
          [variableId]: {
            ...state.sections[variableId],
            annotationItems: currentVariable,
            staledAnnotatedField: true,
            staledAnnotatedFieldIndex: index,
          },
        }
      };
    }
    case "SET_ANNOTATED_FIELD_STALE_STATUS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.variableId]: {
            ...state.sections[action.payload.variableId],
            annotation: {
              ...state.sections[action.payload.variableId].annotation,
            },
            staledAnnotatedField: action.payload.status,
            staledAnnotatedFieldIndex: -1,
          },
        },
      };
    case "MOVE_ANNOTATION_ITEM_POSITION": {
      const { variableId, fromIndex, toIndex } = action.payload;
      const currentVariable = state.sections[variableId];
      const annotationItems = cloneDeep(currentVariable.annotationItems);
      const movedItem = cloneDeep(annotationItems[fromIndex]);
      annotationItems.splice(fromIndex, 1);
      annotationItems.splice(toIndex, 0, movedItem);
      return {
        ...state,
        sections: {
          ...state.sections,
          [variableId]: {
            ...state.sections[variableId],
            annotationItems,
          }
        },
        movedAnnotationItem: {
          variableId,
          variableName: currentVariable.name,
          from: fromIndex,
          to: toIndex,
        }
      }
    }
    case "CLEAR_MOVED_ANNOTATION_ITEM":
      return {
        ...state,
        movedAnnotationItem: {
          ...initialState.movedAnnotationItem
        }
      }
    case "SET_EXPANDED_ANNOTATION_ITEMS":
      return {
        ...state,
        expandedAnnotationItems: action.payload
      }
    case "SET_EXPANDED_ANNOTATION_FIELDS":
      return {
        ...state,
        expandedAnnotationFields: action.payload
      }
    case "SETUP_FORMULATED_VARIABLE_FIELD":
      return {
        ...state,
        formulatedField: action.payload,
      };
    case "SET_FORMULATED_VARIABLE_FIELD": {
      const { variableId, data } = action.payload;
      return {
        ...state,
        formulatedField: {
          ...state.formulatedField,
          [variableId]: data,
        },
      };
    }
    case "STORE_FORMULATED_VARIABLE_FIELD": {
      const { variableId, fieldName, formula } = action.payload;
      if (formula === "" && !state.formulatedField[variableId].hasOwnProperty(fieldName)) return state;
      return {
        ...state,
        formulatedField: {
          ...state.formulatedField,
          [variableId]: {
            ...state.formulatedField[variableId],
            [fieldName]: formula,
           },
        },
      };
    }
    case "MOVE_ANNOTATION_FIELD_POSITION": {
      const { variableId, fromIndex, toIndex, annotationItemIndex, fieldName } = action.payload;
      const currentVariable = state.sections[variableId];
      const annotationItems = cloneDeep(currentVariable.annotationItems);
      const currentItem = annotationItems[annotationItemIndex];
      currentItem.objAttr.map(field => {
        if (field.name === fieldName) {
          const children = cloneDeep(field.children);
          const movedItem = cloneDeep(children[fromIndex]);
          children.splice(fromIndex, 1);
          children.splice(toIndex, 0, movedItem);
          field.children = children;
        }
        return field;
      });
      annotationItems[annotationItemIndex] = currentItem;
      return {
        ...state,
        sections: {
          ...state.sections,
          [variableId]: {
            ...state.sections[variableId],
            annotationItems,
          }
        },
        movedAnnotationItem: {
          variableId,
          variableName: currentVariable.name,
          annotationItemIndex,
          fieldName: compact(fieldName),
          from: fromIndex,
          to: toIndex,
        }
      }
    }
    case "STORE_FORM_DATA":
      return {
        ...state,
        storedFormData: action.payload
      }
    case "CLEAR_STORED_FORM_DATA":
      return {
        ...state,
        storedFormData: {}
      }
    case "SET_SELECTED_PAGE_RANGE": {
      const { sectionId, selectedPages } = action.payload;
      return {
        ...state,
        selectedPage: {
          ...state.selectedPage,
          [sectionId]: state.selectedPage[sectionId].concat(selectedPages)
        }
      }
    }
    case "REMOVE_SELECTED_PAGE_RANGE": {
      const { sectionId, selectedPages } = action.payload;
      return {
        ...state,
        selectedPage: {
          ...state.selectedPage,
          [sectionId]: state.selectedPage[sectionId].filter((selected) => !selectedPages.includes(selected.id))
        }
      }
    }
    case "CHANGE_REPORT_TYPE": {
      const { id, reportType} = action.payload;
      return {
        ...state,
        notes: state.notes.map(note => {
          if (note._id === id ) {
            return { ...note, type: reportType }
          }
          return note
        })
      }
    }
    case "STORE_REPORT_SCROLL_POSITION":
      return {
        ...state,
        reportScrollPosition: action.payload
      }
    case "STORE_NOTE_SCROLL_POSITION":
      return {
        ...state,
        noteScrollPosition: action.payload
      }
    default:
      return state;
  }
};

export const annotationSelector = (state) => ({ ...state.annotation });

export default annotationReducer;
