import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
    thead: {
        backgroundColor: "var(--grey0)"
    }
})

function AdvancedTableHeader(props) {
  const {
    classes,
    selectionCounter,
    rowCount,
    onSelect,
    onSort,
    order,
    orderBy,
    columns,
    align = "left",
    displayHead = ""
  } = props;

  const createSortHandler = (property) => (event) => {
    onSort(event, property);
  };

  const defaultStyle = styles()
  const theadStyle = classes.thead ? classes.thead : defaultStyle.thead

  return (
    <TableHead className={theadStyle}>
      <TableRow style={{ backgroundColor: "var(--grey0)"}}>
        {props.onSelect && (
          <TableCell
            className={classes.checkbox}
          >
            <Checkbox
              color="primary"
              indeterminate={
                selectionCounter > 0 && selectionCounter < rowCount
              }
              checked={rowCount > 0 && selectionCounter === rowCount}
              onChange={onSelect}
              inputProps={{ "aria-label": "select all" }}
            />
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell
            key={column.id}
            align={column.align}
            width={column.width ? column.width : ""}
            padding={column.disablePadding ? "none" : "default"}
            sortDirection={orderBy === column.id ? order : false}
            className={`${classes.header} ${classes.cell}`}
            style={{ paddingLeft: (!props.onSelect && index === 0) ? 15 : "inherit" }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={createSortHandler(column.id, column.type || 'string')}
            >
              <span className={classes.header}>
                {column.label}
              </span>
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AdvancedTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  selectionCounter: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default AdvancedTableHeader;
