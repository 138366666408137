import PATH_URL from '../../routers/path';

const sidebarItems = {
  admin: [
    {
      label: 'Users',
      link: PATH_URL.USERS,
      icon: 'people',
    },
    {
      label: 'Tasks',
      link: PATH_URL.TASKS,
      icon: 'manually-entered-data',
    },
    {
      label: 'Variables',
      link: PATH_URL.VARIABLES,
      icon: 'variable',
    },
    {
      label: 'Documents',
      link: PATH_URL.DOCUMENTS,
      icon: 'document',
    },
    {
      label: 'Companies',
      link: PATH_URL.COMPANIES,
      icon: 'office',
    },
    {
      label: 'Templates',
      link: PATH_URL.TEMPLATES,
      icon: 'projects',
    },
    {
      label: 'Models',
      link: '#',
      icon: 'layers',
    },
  ],
  annotator: [
    {
      label: 'Tasks',
      link: PATH_URL.DASHBOARD,
      icon: 'dashboard',
    },
    {
      label: 'Notifications',
      link: '#',
      icon: 'notifications',
    },
    {
      label: 'Settings',
      link: '#',
      icon: 'cog',
    },
  ],
  reviewer: [
    {
      label: 'Tasks',
      link: PATH_URL.DASHBOARD,
      icon: 'dashboard',
    },
    {
      label: 'Manage Tasks',
      link: PATH_URL.TASKS,
      icon: 'manually-entered-data',
    },
    {
      label: 'Notifications',
      link: '#',
      icon: 'notifications',
    },
    {
      label: 'Settings',
      link: '#',
      icon: 'cog',
    },
  ],
};

export default sidebarItems;
