import { actionType } from "../reducers/annotatorTeam";
import { apiRequest } from "../utils/apiRequest";

export const actionTypeComment = {
  estimationSuccess: "ESTIMATION_DOCUMENT_SUCCESS",
  estimationError: "ESTIMATION_DOCUMENT_FAILURE",
  batcthEstimationSuccess: "ESTIMATION_BATCH_DOCUMENT_SUCCESS",
};

const estimationDocumentSuccess = (authData) => {
  return {
    type: actionTypeComment.estimationSuccess,
    payload: authData,
  };
};

const estimationDocumentError = (authData) => {
  return {
    type: actionTypeComment.estimationError,
    payload: authData,
  };
};

export const estimationDocument = (paramsId) => async (dispatch) => {
  const documentEstimation = await apiRequest(
    dispatch,
    "ESTIMATION_DOCUMENT",
    "get",
    `/annotation/document/${paramsId}/estimation`
  );
  if (documentEstimation.type === actionTypeComment.estimationSuccess) {
    dispatch(estimationDocumentSuccess(documentEstimation));
  } else {
    dispatch(estimationDocumentError(documentEstimation));
  }
};

export const batchEstimationDocument = (documents) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypeComment.batcthEstimationSuccess,
      payload: 0,
    });

    let result = await Promise.all(
      documents.map((item) => {
        return apiRequest(
          dispatch,
          "ESTIMATION_BATCH_DOCUMENT",
          "get",
          `/annotation/document/${item.id}/estimation`
        );
      })
    );

    let payload = 0;
    result.forEach((item) => (payload += item.payload.estimation));

    console.log({ payload, result });
    dispatch({
      type: actionTypeComment.batcthEstimationSuccess,
      payload,
    });
  };
};
