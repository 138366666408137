import { actionTypeComment } from "../actions/comment";
const initialState = {
  estimation: 0,
  estimationError: "",
  estimationTotal: 0,
};

const commentReducer = (state = initialState, action) => {
  let temp = null;
  switch (action.type) {
    case actionTypeComment.estimationSuccess:
      temp = action.payload.payload
        ? action.payload.payload.estimation
        : action.payload.estimation;

      return {
        ...state,
        estimation: action.payload,
        estimationTotal: temp,
      };
    case actionTypeComment.estimationError:
      return {
        ...state,
        estimationError: action.payload,
      };
    case actionTypeComment.batcthEstimationSuccess:
      return {
        ...state,
        estimationTotal: action.payload,
      };
    default:
      return state;
  }
};

export default commentReducer;
