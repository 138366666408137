import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Menu from '../../components/@setproduct-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from '@blueprintjs/core';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import AdvancedTableAction from '../../components/AdvancedTable/AdvancedTableAction';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns'
import {
  actionBulkUploadDocumentRender,
  setUploadFile,
  uploadFile,
} from '../../redux/actions/upload';
import ProgressBar from '../../components/@setproduct-ui/core/ProgressBar/ProgressBar';
import {
  getDocumentSummary,
  listDocument,
  listDocumentDelete,
  ocrListDocument,
  ocrListDocumentMultiple,
  selectDocument,
  changeDocumentFilter,
  changePageDataOnDocument
} from '../../redux/actions/document';
import styles from './style.module.css';
import { Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogDelete from '../../components/CustomDialog';
import DialogOcr from '../../components/CustomDialog';
import Select from 'react-select';
import Button from '../../components/@setproduct-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { createLoadingSelector } from '../../redux/api/loading';
import MetricCard from '../../components/MetricCard';
import MetricContainer from '../../components/MetricContainer';
import { useDropzone } from 'react-dropzone';
import { LoadingTable } from './Loading';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import stylesTables from '../../components/AdvancedTable/table.module.css';
import FlexWrapper from '../../components/FlexWrapper';
import SearchInput from '../../components/SearchInput';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import PATH_URL from '../../routers/path';
import { size, toArray } from 'lodash';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import {
  estimationDocument,
  batchEstimationDocument,
} from '../../redux/actions/comment';
import { currencyFormatter } from '../../utils/currencyFormatter';
import { createErrorMessageSelector } from '../../redux/api/error';

function convertFileSizeToReadableFormat(filesize) {
  // convert to human readable format.
  const i = Math.floor(Math.log(filesize) / Math.log(1024));
  return (
    (filesize / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
}

const OCR_STATUS = {
  0: 'Failed',
  1: 'Unprocessed',
  2: 'Queued',
  3: 'In Progress',
  4: 'Done'
}

function EnhancedMenu(props) {
  const {
    row,
    setFormData,
    handleChangeShowModalDelete,
    handleChangeShowModalOcr,
    onClose,
  } = props;
  const history = useHistory();
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu view="smooth" type="dense" color="default" className={styles.menu}>
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Preview Document"
          disabled={row.numPages === null}
          onClick={() => {
            history.push(`/d/document-preview/${row.id}`);
            return onClose();
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Run OCR"
          disabled={row.numPages === null}
          onClick={() => {
            handleChangeShowModalOcr(row);
            setFormData(row);
            return onClose();
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete"
          onClick={() => {
            handleChangeShowModalDelete(row);
            setFormData(row);
            return onClose();
          }}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}

const allowedFiles = ['application/pdf'];

const getDocumentListLoadingUpload = createLoadingSelector([
  'BULK_UPLOAD_DOCUMENT',
]);
const getDocumentListLoading = createLoadingSelector(['LIST_DOCUMENT']);
const deleteDocumentListLoading = createLoadingSelector(['LIST_DOCUMENT_DELETE']);
const ocrDocumentListLoading = createLoadingSelector(['LIST_DOCUMENT_OCR']);
const updateDocumentListLoading = createLoadingSelector([
  'DOCUMENT_LIST_UPDATE',
]);

const ocrDocumentError = createErrorMessageSelector(['LIST_DOCUMENT_OCR']);
const documentDelete = createErrorMessageSelector(['LIST_DOCUMENT_DELETE']);

function DocumentManagement(props) {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('task_title');
  const [menu, setMenu] = useState(null);
  const [textErrorUploadType, setTextErrorUploadType] = useState(false);
  const [formData, setFormData] = useState({});
  const [isShowModalDialogDelete, setIsShowModalDialogDelete] = useState(false);
  const [isShowModalDialogOcr, setIsShowModalDialogOcr] = useState(false);
  const [isShowSelectDialogOcr, setIsShowSelectDialogOcr] = useState(false);
  const [singleEstimate, setSingleEstimate] = useState(false);
  const classesTab = useTableStyle();

  const dispatch = useDispatch();

  const document = useSelector((state) => state.document);
  const documents = document.documents.list;
  const summary = document.documents.summary;
  const filter = document.documents.filter;
  const pageData = document.documents.pageData;
  const ocrDocument = document.ocrDocument;
  // const ocrDocumentError = document.ocrDocumentError
  const ocrDocumentMultiple = document.ocrDocumentMultiple;
  // const documentDelete = document.documentDelete
  const comment = useSelector((state) => state.comment);
  const uploadDocument = useSelector((state) => state.uploadDocument);
  const uploadError = uploadDocument.uploadError;
  const fileProgress = uploadDocument.fileProgress;
  const showProgress = uploadDocument.showProgress;
  const documentListLoadingUpload = useSelector((state) =>
    getDocumentListLoadingUpload(state)
  );
  const documentListLoading = useSelector((state) => getDocumentListLoading(state));
  const documentSummaryLoading = useSelector(createLoadingSelector(['GET_DOCUMENT_SUMMARY']));
  const documentDeleteLoading = useSelector((state) =>
    deleteDocumentListLoading(state)
  );
  const documentOcrLoading = useSelector((state) => ocrDocumentListLoading(state));
  const documentUpdateListLoading = useSelector((state) =>
    updateDocumentListLoading(state)
  );
  const selectedDocuments = useSelector(
    (state) => state.document.selectedDocuments
  );
  // const estimation = useSelector((state) => state.comment.estimation);
  const uploadedFileAmount = size(fileProgress);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(
      (file) => file.progress === 0
    );
    dispatch(uploadFile(fileToUpload));
    // eslint-disable-next-line
  }, [uploadedFileAmount]);

  useEffect(() => {
    dispatch(getDocumentSummary());
    dispatch(actionBulkUploadDocumentRender());
    setMenu(null); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ocrDocument && ocrDocument.type === 'LIST_DOCUMENT_OCR_SUCCESS') {
      dispatch(
        listDocument({
          ...filter,
          limit: pageData.limit,
          page: pageData.currentPageNumber,
        })
      );
      dispatch(actionBulkUploadDocumentRender());
      setIsShowModalDialogOcr(false);
      setMenu(null);
    }
  }, [dispatch, ocrDocument, filter, pageData.limit, pageData.currentPageNumber]);

  useEffect(() => {
    // console.log(documentDelete)
    if (documentDelete.type === 'LIST_DOCUMENT_DELETE_SUCCESS') {
      // dispatch(listDocument());
      // dispatch(actionBulkUploadDocumentRender());
      // setMenu(null);
    } // eslint-disable-next-line
  }, [documentDelete]);

  useEffect(() => {
    dispatch(
      listDocument({
        ...filter,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  function createData(
    id,
    filename,
    uploadedDate,
    type,
    numPages,
    filesize,
    ocr_status
  ) {
    return {
      id,
      filename,
      uploadedDate: format(new Date(uploadedDate), 'd/M/yyyy'),
      type,
      numPages: !numPages ? 'PARSING...' : numPages,
      filesize,
      ocr_status,
    };
  }

  const handleChangeShowModalDelete = () => {
    setIsShowModalDialogDelete(!isShowModalDialogDelete);
  };

  const handleChangeShowModalOcr = (params) => {
    setSingleEstimate(true);
    setIsShowModalDialogOcr(!isShowModalDialogOcr);
    if (!isShowModalDialogOcr) {
      dispatch(estimationDocument(params.id));
    }
  };

  const handleBatchEstimateOcr = async () => {
    setSingleEstimate(false);
    await dispatch(batchEstimationDocument(selectedDocuments));
    setIsShowModalDialogOcr(!isShowModalDialogOcr);
  };

  const handleSelectedSubmitOcr = async () => {
    await dispatch(ocrListDocumentMultiple(selectedDocuments));
    setIsShowSelectDialogOcr(false);
    setIsShowModalDialogOcr(!isShowModalDialogOcr);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const columns = [
    {
      id: 'filename',
      numeric: false,
      disablePadding: false,
      label: 'FILENAME',
    },
    {
      id: 'uploadedDate',
      numeric: false,
      disablePadding: false,
      label: 'UPLOADED DATE',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'TYPE',
    },
    {
      id: 'numPages',
      numeric: false,
      disablePadding: false,
      label: 'NUMBER OF PAGES',
    },
    {
      id: 'filesize',
      numeric: false,
      disablePadding: false,
      label: 'FILE SIZE',
    },
    {
      id: 'ocr_status',
      numeric: false,
      disablePadding: false,
      label: 'OCR STATUS',
    },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      dispatch(selectDocument(rows));
    } else {
      dispatch(selectDocument([]));
    }
  };

  const handleClick = (event, doc) => {
    const selected = selectedDocuments.find(
      (selected) => selected.id === doc.id
    );
    if (!selected) {
      dispatch(selectDocument([...selectedDocuments, doc]));
    } else {
      const newSelected = selectedDocuments.filter(
        (selected) => selected.id !== doc.id
      );
      dispatch(selectDocument(newSelected));
    }
  };

  function getStatusPillColorState(status) {
    switch (status) {
      default:
      case 'unverified':
      case 'verified':
        return {
          backgroundColor: '#acf4bc',
          color: '#239639',
        };
    }
  }

  const onDropRejected = (e) => {
    setTextErrorUploadType(e);
  };

  const handleChangePage = (event, newPage) => {
    // material-ui using zero-based page
    dispatch(changePageDataOnDocument({ currentPageNumber: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      changePageDataOnDocument({
        limit: parseInt(event.target.value, 10),
        currentPageNumber: 1,
      })
    );
  };

  const isSelected = (id) => {
    const selected = selectedDocuments.find((selected) => selected.id === id);
    return selected ? true : false;
  };

  const textDelete = (
    <div>
      <p>Are you sure want to delete this document?</p>
      <div className={classes.submitWrapperForm}>
        <Button
          type="default"
          view="flat"
          dense={true}
          onClick={handleChangeShowModalDelete}
          style={{ padding: 20 }}
          color="default"
        >
          Cancel
        </Button>
        <Button
          type="default"
          onClick={() =>
            dispatch(
              listDocumentDelete(formData.id, setIsShowModalDialogDelete)
            )
          }
          view="filled"
          loading={documentDeleteLoading}
          dense={true}
          style={{ padding: 20 }}
          color="danger"
        >
          Delete
        </Button>
      </div>
    </div>
  );

  const ocrContent = () => {
    let document = singleEstimate
      ? formData.filename
      : `${selectedDocuments.length} files`;
    return (
      <div>
        <p>
          Are you sure want to run OCR for {document}? This operation will cost
          you{' '}
          <strong>{currencyFormatter.format(comment.estimationTotal)}</strong>
        </p>
        <div className={classes.submitWrapperForm}>
          <Button
            type="default"
            view="flat"
            dense={true}
            onClick={() => setIsShowModalDialogOcr(!isShowModalDialogOcr)}
            style={{ padding: 20 }}
            color="default"
          >
            Cancel
          </Button>
          <Button
            type="default"
            onClick={() => {
              isShowSelectDialogOcr
                ? handleSelectedSubmitOcr()
                : dispatch(ocrListDocument(formData.id));
              return setIsShowModalDialogOcr(!isShowModalDialogOcr);
            }}
            view="filled"
            dense={true}
            style={{ padding: 20 }}
            color="success"
          >
            Run OCR
          </Button>
        </div>
      </div>
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    dispatch(setUploadFile(acceptedFiles));
  }, [dispatch]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    accept: allowedFiles,
    noClick: true,
    noKeyboard: true,
    onDropRejected: onDropRejected,
  });

  const handleChangeType = (selected) => {
    dispatch(changeDocumentFilter({ type: selected.value }))
  };

  const handleChangeStatus = (selected) => {
    const value = selected.value === 'In Progress' ? 'InProgress' : selected.value;
    dispatch(changeDocumentFilter({ status: value }))
  };

  const getSelectedType = (type) => {
    const reformatted = type.replace('_', ' ').toUpperCase();
    if (type) return { value: type, label: reformatted };
    return { value: '', label: 'Type' };
  };

  const getSelectedStatus = (status) => {
    if (status) return { value: status, label: status.toUpperCase() };
    return { value: '', label: 'Status' };
  };

  const rows =
    documents.length > 0 ?
    documents.map(row => {
      return createData(
        row.id,
        row.filename,
        row.uploadedDate,
        row.metadata.type,
        row.numPages,
        row.filesize,
        OCR_STATUS[row.ocr.status]
      );
    }) : [];

  return (
    <div className={pageLayoutStyle.root}>
      <MetricContainer>
        <MetricCard
          title="ALL DOCS"
          number={summary.all_docs}
          description="All Documents uploaded"
          iconBackgroundColor="var(--green50)"
          loading={documentSummaryLoading}
          icon="document"
        />
        <MetricCard
          title="NEW DOCS"
          number={summary.new_docs}
          description="Just uploaded"
          iconBackgroundColor="var(--red50)"
          loading={documentSummaryLoading}
          icon="plus"
        />
        <MetricCard
          title="QUEUE"
          number={summary.queue}
          description="In Queue"
          iconBackgroundColor="var(--green50)"
          loading={documentSummaryLoading}
          icon="list"
        />
        <MetricCard
          title="IN PROGRESS"
          number={summary.in_progress}
          description="Processing"
          iconBackgroundColor="var(--green50)"
          loading={documentSummaryLoading}
          icon="play"
        />
        <MetricCard
          title="READY"
          number={summary.ready}
          description="Ready to annotate"
          iconBackgroundColor="var(--green50)"
          loading={documentSummaryLoading}
          icon="database"
        />
      </MetricContainer>
      <div className={stylesTables.tableContainer}>
        <div className={stylesTables.tableToolbar}>
          <h1 className={stylesTables.tableTitle}>All Documents</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <Button
              type="circle"
              view="smooth"
              color="primary"
              dense={true}
              icon="refresh"
              rightIcon={false}
              loading={documentListLoading}
              disabled={documentListLoading}
              fill={false}
              style={{ marginRight: '15px' }}
              onClick={() => {
                dispatch(
                  listDocument({
                    ...filter,
                    limit: pageData.limit,
                    page: pageData.currentPageNumber,
                  })
                )
              }}
            />
            <SearchInput
              defaultValue={filter.q}
              onApplySearch={(value) => {
                dispatch(changeDocumentFilter({ q: value }));
              }}
              placeholder="Search"
              style={{ width: 170, marginRight: 10 }}
            />
            <Select
              name="type"
              value={getSelectedType(filter.type)}
              defaultValue={{ value: '', label: 'Type' }}
              onChange={(selected) => handleChangeType(selected)}
              options={[
                { value: '', label: 'Type' },
                { value: 'annual_report', label: 'ANNUAL REPORT' },
                {
                  value: 'sustainability_report',
                  label: 'SUSTAINABILITY REPORT',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Select
              name="status"
              value={getSelectedStatus(filter.status)}
              defaultValue={{ value: '', label: 'Status' }}
              onChange={(selected) => handleChangeStatus(selected)}
              options={[
                { value: '', label: 'STATUS' },
                { value: 'Queued', label: 'QUEUE' },
                {
                  value: 'In Progress',
                  label: 'IN PROGRESS',
                },
                {
                  value: 'Done',
                  label: 'DONE',
                },
                {
                  value: 'Unprocessed',
                  label: 'UNPROCESSED',
                },
                {
                  value: 'Failed',
                  label: 'FAILED',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Button
              color="primary"
              text={'Manage Groups'}
              loading={false}
              disabled={false}
              onClick={() => props.history.push(PATH_URL.DOCUMENT_GROUPS)}
              className={styles.mainButton}
            />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button
                onClick={open}
                className={styles.mainButton}
                color="primary"
                style={{
                  marginLeft: 10,
                }}
              >
                {'Upload Files'}
              </Button>
            </div>
          </FlexWrapper>{' '}
        </div>
        {textErrorUploadType && (
          <Alert severity="error" style={{ marginTop: 15, marginBottom: 15 }}>
            <AlertTitle>Error</AlertTitle>
            <strong>{'Upload Hanya PDF'}</strong>
          </Alert>
        )}
        {uploadError && uploadError.message && (
          <Alert severity="error" style={{ marginTop: 15, marginBottom: 15 }}>
            <AlertTitle>Error</AlertTitle>
            <strong>{uploadError.message}</strong>
          </Alert>
        )}
        {ocrDocumentError.payload && ocrDocumentError.payload.message && (
          <Alert severity={'error'} style={{ marginTop: 15, marginBottom: 15 }}>
            <AlertTitle>Error</AlertTitle>{' '}
            <strong>{ocrDocumentError.payload.message}</strong>
          </Alert>
        )}
        {ocrDocumentMultiple.length > 0 &&
          ocrDocumentMultiple.map((list, index) => {
            return (
              <Alert
                severity={
                  list.type === 'LIST_DOCUMENT_OCR_FAILURE'
                    ? 'error'
                    : 'success'
                }
                key={index}
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <AlertTitle>
                  {list.type === 'LIST_DOCUMENT_OCR_FAILURE'
                    ? 'Error'
                    : 'Success'}
                </AlertTitle>{' '}
                <strong>{list.payload.message}</strong>
              </Alert>
            );
          })}
        <TableContainer className={classesTab.container}>
          <Table
            className={classesTab.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classesTab}
              selectionCounter={selectedDocuments.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
            />
            {showProgress ? (
              <TableBody>
                {toArray(fileProgress).map((file, index) => {
                  const key = 'file-loading-' + Date.now() + index;
                  return (
                    <TableRow key={key}>
                      <TableCell
                        padding="checkbox"
                        className={classesTab.checkbox}
                      ></TableCell>
                      <TableCell
                        scope="row"
                        className={`${classesTab.cell}`}
                        size="medium"
                      >
                        {file.file.name > 0 ? (
                          <Icon icon="document" iconSize={18} />
                        ) : (
                          ''
                        )}
                        {file.file.name}
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        style={{ padding: 0 }}
                        className={classes.cell}
                      >
                        <ProgressBar
                          type="def"
                          view="filled"
                          color="primary"
                          animate={true}
                          style={{ width: '100%', borderRadius: 0 }}
                          stripes={true}
                          value={file.progress / 100}
                        />
                      </TableCell>
                      <TableCell
                        style={{ padding: 0 }}
                        className={classes.cell}
                      >
                        <span className={classes.nameCell}>
                          Uploading {file.progress}%
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}{' '}
              </TableBody>
            ) : null}

            <TableBody>
              {documentListLoading ||
              documentListLoadingUpload ||
              documentOcrLoading ||
              documentUpdateListLoading ? (
                <LoadingTable number="5" />
              ) : rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.filename}
                        selected={isItemSelected}
                        className={classesTab.rowContainer}
                        onClick={() => {
                          history.push(`/m/documents/${row.id}`);
                        }}
                      >
                        <TableCell
                          className={classesTab.checkbox}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell className={`${classesTab.cell}`}>
                          {/* <Link to={`/d/document-preview/${row.id}`}></Link> */}
                          {row.filename.length > 0 ? (
                            <Icon icon="document" iconSize={18} />
                          ) : undefined}
                          {row.filename}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {row.uploadedDate}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {row.type === 'annual_report'
                            ? 'Annual Report'
                            : 'Sustainability Report'}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {row.numPages}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          {convertFileSizeToReadableFormat(row.filesize)}
                        </TableCell>
                        <TableCell className={`${classesTab.cell}`}>
                          <div
                            className={classes.pills}
                            style={getStatusPillColorState(row.ocr_status)}
                          >
                            {row.ocr_status.toUpperCase()}
                          </div>
                        </TableCell>
                        <TableCell
                          className={classesTab.cell}
                          style={{ position: 'relative' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (menu === index) setMenu(null);
                              else setMenu(index);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === index && (
                            <EnhancedMenu
                              row={row}
                              setMenu={setMenu}
                              setFormData={setFormData}
                              ocrListDocument={ocrListDocument}
                              handleChangeShowModalOcr={
                                handleChangeShowModalOcr
                              }
                              handleChangeShowModalDelete={
                                handleChangeShowModalDelete
                              }
                              onClose={() => setMenu(null)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell
                    className={classes.emptyResult}
                    colSpan={12}
                    align="center"
                  >
                    <div>No results found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>{' '}
        </TableContainer>
        <Paper className={classesTab.pagination} elevation={0}>
          {selectedDocuments.length > 0 && (
            <AdvancedTableAction
              selectionCounter={selectedDocuments.length}
              actions={[
                {
                  icon: 'predictive-analysis',
                  onClick: () => {
                    setIsShowSelectDialogOcr(!isShowSelectDialogOcr);
                    handleBatchEstimateOcr();
                  },
                },
                {
                  icon: 'trash',
                  onClick: () => console.log('delete selected documents'),
                },
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, 100, 500]}
            component="div"
            count={pageData.totalItems}
            rowsPerPage={pageData.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            // material-ui using zero-based page
            page={pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>

        <DialogDelete
          type="default"
          view={'raised'}
          onClose={handleChangeShowModalDelete}
          isOpen={isShowModalDialogDelete}
          color="default"
          title="Delete Document"
        >
          {textDelete}
        </DialogDelete>
        <DialogOcr
          type="default"
          view={'raised'}
          onClose={handleChangeShowModalOcr}
          isOpen={isShowModalDialogOcr}
          color="default"
          title="Run OCR"
        >
          {ocrContent()}
        </DialogOcr>
      </div>
    </div>
  );
}

export default DocumentManagement;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    overflowX: 'auto',
  },
  emptyResult: {
    backgroundColor: '#fff',
    height: '30vh',
    color: '#000',
    textAlign: 'center',
    padding: 0,
    border: 0,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'inline-block',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  submitWrapperForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '45px',
  },
  nameCell: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
  firstCell: {
    backgroundColor: '#fff',
    verticalAlign: 'middle',
  },
  cell: {
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  pills: {
    display: 'inline',
    textAlign: 'center',
    padding: theme.spacing(0.5, 0.8),
    borderRadius: '3rem',
  },
  action: {
    borderRadius: '50%',
    backgroundColor: '#ddd !important',
    boxShadow: 'none !important',
    display: 'inline',
    margin: theme.spacing(0, 0.5),
  },
}));
