const initialState = {
  documents: {
    summary: {
      all_docs: 0,
      new_docs: 0,
      queue: 0,
      in_progress: 0,
      ready: 0,
    },
    filter: {
      q: '',
      type: '',
      status: '',
    },
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    list: [],
  },
  documentsError: '',
  ocrDocument: '',
  ocrDocumentMultiple: '',
  documentDelete: '',
  selectedDocuments: [],
};

export const actionType = {
  LIST_DOCUMENT_SUCCESS: "LIST_DOCUMENT_SUCCESS",
  GET_DOCUMENT_SUMMARY_SUCCESS: "GET_DOCUMENT_SUMMARY_SUCCESS",
  CHANGE_DOCUMENT_FILTER: 'CHANGE_DOCUMENT_FILTER',
  CHANGE_PAGE_DATA_ON_DOCUMENT: 'CHANGE_PAGE_DATA_ON_DOCUMENT',
  SELECT_DOCUMENT: "SELECT_DOCUMENT",
  LIST_DOCUMENT_FAILURE: "LIST_DOCUMENT_FAILURE",
  LIST_DOCUMENT_OCR_SUCCESS: "LIST_DOCUMENT_OCR_SUCCESS",
  LIST_DOCUMENT_OCR_MULTIPLE: "LIST_DOCUMENT_OCR_MULTIPLE",
  LIST_DOCUMENT_DELETE_SUCCESS: "LIST_DOCUMENT_DELETE_SUCCESS",
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LIST_DOCUMENT_SUCCESS: {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          pageData,
          list,
        },
      };
    }
    case actionType.GET_DOCUMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          summary: action.payload,
        },
      };
    case actionType.LIST_DOCUMENT_FAILURE:
      console.log("error", action.payload);
      return {
        ...state,
        documentsError: action.payload,
      };
    case actionType.CHANGE_DOCUMENT_FILTER:
      return {
        ...state,
        documents: {
          ...state.documents,
          filter: { ...state.documents.filter, ...action.payload },
          pageData: { ...state.documents.pageData, currentPageNumber: 1 }, // reset page
        },
      };
    case actionType.CHANGE_PAGE_DATA_ON_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          pageData: { ...state.documents.pageData, ...action.payload },
        },
      };
    case actionType.SELECT_DOCUMENT:
      return {
        ...state,
        selectedDocuments: action.payload,
      };
    case actionType.LIST_DOCUMENT_OCR_SUCCESS:
      return {
        ...state,
        ocrDocument: action.payload,
      };
    case actionType.LIST_DOCUMENT_OCR_MULTIPLE:
      return {
        ...state,
        ocrDocumentMultiple: action.payload,
      };
    case actionType.LIST_DOCUMENT_DELETE_SUCCESS:
      let pos = state.documents
        .map((e) => {
          return e.id;
        })
        .indexOf(action.params);
      if (pos !== -1) {
        state.documents.splice(pos, 1);
      }
      return {
        ...state,
        documentDelete: action.payload,
      };
    default:
      return state;
  }
};

export default documentsReducer;
