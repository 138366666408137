import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { upperFirst } from "lodash";
import Skeleton from "react-loading-skeleton";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@blueprintjs/core";
import FlexWrapper from "../../components/FlexWrapper";
import Card from "../../components/@setproduct-ui/core/Card";
import Button from "../../components/@setproduct-ui/core/Button";
import Dialog from "../../components/@setproduct-ui/core/Dialog";
import Menu from "../../components/@setproduct-ui/core/Menu";
import MenuItem from "../../components/@setproduct-ui/core/Menu/MenuItem";
import Input from "../../components/Input";
import TreeView from "./CustomTreeView";
import TemplateForm from "./TemplateForm";
import ChapterForm from "./ChapterForm";
import CreateVariableForm from "./CreateVariableForm";
import EditVariableForm from "./EditVariableForm";
import { ClickOutsideContainer } from "../../components/utils/ClickOutsideContainer";
import styles from "./style.module.css";
import {
  getTemplateList,
  addSection,
  removeSection,
  updateSection,
  getVariableList,
  removeVariable
} from "../../redux/actions/admin";
import { createLoadingSelector } from "../../redux/api/loading";

const loadingSelectorQuery = createLoadingSelector(["GET_TEMPLATE_LIST"]);
const loadingSelectorMutation = createLoadingSelector([
  "ADD_SECTION",
  "UPDATE_SECTION",
  "REMOVE_SECTION",
  "REMOVE_VARIABLE"
]);

function MessageWrapper({ text }) {
  return (
    <FlexWrapper height="100%">
      <span>{text}</span>
    </FlexWrapper>
  );
}

function getVariableType(variable) {
  const { details, isArray } = variable;
  const variableName = details[0].name.toLowerCase();
  const arrayStr = isArray ? "Array of " : "";
  return `${arrayStr}${upperFirst(variableName)}`;
}

function parseVariableType(type) {
  const typeArr = type.split("-");
  return typeArr.slice(0, typeArr.length - 1).join(" ").toUpperCase();
}

function TemplateSection(props) {
  const [openTemplateForm, setOpenTemplateForm] = useState(false);
  const [openChapterForm, setOpenChapterForm] = useState(false);
  const [openCreateVariableForm, setOpenCreateVariableForm] = useState(false);
  const [openEditVariableForm, setOpenEditVariableForm] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    type: "",
    id: null
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [menu, setMenu] = useState(null);
  const [menuVariable, setMenuVariable] = useState(null);
  const [editData, setEditData] = useState({
    index: null,
    open: false,
    name: ""
  });
  const [editVariableData, setEditVariableData] = useState({
    id: null,
    variableName: "",
    category: "",
    description: "",
    isArray: false,
    type: { value: "", label: "" }
  });
  const [expanded, setExpanded] = useState([]);
  const { getTemplateList, getVariableList } = props;

  useEffect(() => {
    getTemplateList();
  }, [getTemplateList]);

  useEffect(() => {
    if (selectedSection) {
      getVariableList(selectedSection.sectionId);
    }
  }, [getVariableList, selectedSection]);

  const handleSelectedTree = node => {
    setSelectedSection(node);
    setSelectedVariable(null);
  };

  const handleExpandedTree = nodeIds => {
    setExpanded(nodeIds);
  };

  const closeDialog = () => {
    setDialog({ open: false, type: "", sectionId: null });
    setMenu(null);
  };

  const showDialog = (id, type) => {
    setDialog({ open: true, type, id });
  };

  const toggleMenu = (e, index) => {
    e.preventDefault();
    if (index === menu) setMenu(null);
    else setMenu(index);
  };

  const toggleMenuVariable = index => {
    if (index === menuVariable) setMenuVariable(null);
    else setMenuVariable(index);
  };

  const handleDelete = async () => {
    const { id, type } = dialog;
    let isSuccess;
    if (["template", "section"].includes(type)) {
      isSuccess = await props.removeSection(id);
    } else {
      const { sectionId } = selectedSection;
      isSuccess = await props.removeVariable(id, sectionId);
    }
    if (isSuccess) {
      closeDialog();
      if (type === "template") {
        setSelectedTemplate(null);
        setSelectedSection(null);
        setMenu(null);
      } else if (type === "section") {
        setSelectedSection(null);
      }
      setSelectedVariable(null);
    }
  };

  const renderVariables = () => {
    if (!props.loadingVariable) {
      if (selectedSection !== null) {
        const displayedVariable = props.variables[selectedSection.sectionId];
        if (Array.isArray(displayedVariable)) {
          if (displayedVariable.length > 0) {
            return displayedVariable.map(variable => (
              <FlexWrapper
                key={variable._id}
                justifyContent="space-between"
                className={styles.templateList}
                padding="0.3em"
                style={{
                  background:
                    selectedVariable && selectedVariable._id === variable._id
                      ? "var(--grey5)"
                      : ""
                }}
                onClick={() => setSelectedVariable(variable)}
              >
                <>
                  <FlexWrapper>
                    <Icon
                      icon="full-circle"
                      iconSize={16}
                      style={{ color: "var(--grey70)" }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <h4 className={styles.templateName}>{variable.name}</h4>
                      <span style={{ color: "var(--grey60)", fontSize: 12 }}>
                        {getVariableType(variable)}
                      </span>
                    </div>
                  </FlexWrapper>
                  <IconButton
                    size="small"
                    onClick={() => toggleMenuVariable(variable._id)}
                  >
                    <Icon icon="more" iconSize={16} />
                  </IconButton>
                  {menuVariable === variable._id && (
                    <ClickOutsideContainer
                      onClose={() => setMenuVariable(null)}
                    >
                      <Menu
                        type="dense"
                        view="smooth"
                        color="default"
                        className={styles.menu}
                      >
                        <MenuItem
                          type="dense"
                          view="smooth"
                          color="default"
                          text="Edit variable"
                          onClick={() => {
                            setEditVariableData({
                              id: variable._id,
                              variableName: variable.name,
                              category: variable.category,
                              description: variable.description,
                              isArray: variable.isArray,
                              type: {
                                value: variable.dtype,
                                label: variable.details[0].name
                              }
                            });
                            setOpenEditVariableForm(true);
                            setMenuVariable(null);
                          }}
                        />
                        <MenuItem
                          type="dense"
                          view="smooth"
                          color="default"
                          text="Delete variable"
                          onClick={() => showDialog(variable._id, "variable")}
                        />
                      </Menu>
                    </ClickOutsideContainer>
                  )}
                </>
              </FlexWrapper>
            ));
          } else {
            return <MessageWrapper text="No variables" />;
          }
        } else return <MessageWrapper text="No variables" />;
      }
      return <MessageWrapper text="No selected section" />;
    }
    return Array(5)
      .fill(1)
      .map((_, index) => (
        <FlexWrapper
          key={index}
          justifyContent="space-between"
          className={styles.templateList}
          padding="0.3em"
        >
          <FlexWrapper>
            <Skeleton height={16} width={16} circle={true} />
            <div style={{ marginLeft: 10 }}>
              <Skeleton width={160} />
              <span style={{ color: "var(--grey60)", fontSize: 12 }}>
                <Skeleton width={160} />
              </span>
            </div>
            <Skeleton height={26} width={16} />
          </FlexWrapper>
        </FlexWrapper>
      ));
  };

  const renderVariableDetails = () => {
    const detailSchema = selectedVariable.details[0];
    let variableDetails = [];
    if (detailSchema.dtype === "basic") {
      const type = Array.isArray(detailSchema.attr) ? "ENUM" : detailSchema.name;
      variableDetails = [
        { name: selectedVariable.name, type }
      ];
    } else {
      variableDetails = detailSchema.objAttr.map(field => ({
        name: field.name,
        type: parseVariableType(field.dtype)
      }));
    }
    return variableDetails.map(detail => (
      <FlexWrapper
        key={detail.name}
        justifyContent="flex-start"
        margin="0 0 10px 0"
      >
        <Icon icon="menu" iconSize={16} style={{ color: "var(--grey70)" }} />
        <div style={{ marginLeft: 10 }}>
          <h4 className={styles.templateName}>{detail.name}</h4>
          <span style={{ color: "var(--grey60)", fontSize: 12 }}>
            {detail.type}
          </span>
        </div>
      </FlexWrapper>
    ));
  };

  return (
    <div className={styles.root}>
      <FlexWrapper
        justifyContent="flex-start"
        alignItems="stretch"
        height="85vh"
        margin="10px 0 0 0"
        className={styles.container}
      >
        <FlexWrapper
          flexFlow="column"
          justifyContent="flex-start"
          alignItems="stretch"
          height="100%"
          width="25%"
        >
          <Card view="smooth" color="default" className={styles.header}>
            <h5 className={styles.heading}>Templates</h5>
            <Button
              type="icon"
              view="filled"
              color="primary"
              icon="plus"
              loading={false}
              disabled={false}
              onClick={() => setOpenTemplateForm(true)}
            />
          </Card>
          <Card view="smooth" color="default" className={styles.flexCard}>
            {props.loadingData ? (
              Array(5)
                .fill(1)
                .map((_, index) => (
                  <FlexWrapper
                    key={index}
                    justifyContent="space-between"
                    className={styles.templateList}
                    padding="0.3em"
                  >
                    <FlexWrapper>
                      <Skeleton height={16} width={16} />
                      <div style={{ marginLeft: 10 }}>
                        <Skeleton width={160} />
                        <span style={{ color: "var(--grey60)", fontSize: 12 }}>
                          <Skeleton width={160} />
                        </span>
                      </div>
                      <Skeleton height={26} width={16} />
                    </FlexWrapper>
                  </FlexWrapper>
                ))
            ) : props.templates.length > 0 ? (
              props.templates.map((template, index) => (
                <FlexWrapper
                  key={template.sectionId}
                  justifyContent="space-between"
                  className={styles.templateList}
                  padding="0.3em"
                  style={{
                    background: selectedTemplate === index ? "var(--grey5)" : ""
                  }}
                  onClick={() => {
                    setSelectedTemplate(index);
                    setSelectedSection(null);
                    setSelectedVariable(null);
                  }}
                >
                  {editData.index !== index ? (
                    <>
                      <FlexWrapper>
                        <Icon
                          icon="folder-close"
                          iconSize={16}
                          style={{ color: "var(--grey50)" }}
                        />
                        <div style={{ marginLeft: 10 }}>
                          <h4 className={styles.templateName}>
                            {template.name}
                          </h4>
                          <span
                            style={{ color: "var(--grey60)", fontSize: 12 }}
                          >
                            {template.modelReport === "annual_report"
                              ? "Annual Report"
                              : "Sustainability Report"}
                          </span>
                        </div>
                      </FlexWrapper>
                      <IconButton
                        size="small"
                        onClick={e => toggleMenu(e, index)}
                      >
                        <Icon icon="more" iconSize={16} />
                      </IconButton>
                      {menu === index && (
                        <ClickOutsideContainer onClose={() => setMenu(null)}>
                          <Menu
                            type="dense"
                            view="smooth"
                            color="default"
                            className={styles.menu}
                          >
                            <MenuItem
                              type="dense"
                              view="smooth"
                              color="default"
                              text="Edit template"
                              onClick={() => {
                                setEditData({
                                  index,
                                  open: true,
                                  name: template.name
                                });
                                setMenu(null);
                              }}
                            />
                            <MenuItem
                              type="dense"
                              view="smooth"
                              color="default"
                              text="Delete template"
                              onClick={() =>
                                showDialog(template.sectionId, "template")
                              }
                            />
                          </Menu>
                        </ClickOutsideContainer>
                      )}
                    </>
                  ) : (
                    <FlexWrapper
                      justifyContent="space-between"
                      padding="0.3em"
                      style={{
                        borderRadius: "0.2em",
                        cursor: "pointer",
                        transition: "100ms background ease-in"
                      }}
                    >
                      <FlexWrapper>
                        <Icon
                          icon="folder-close"
                          iconSize={16}
                          style={{ color: "var(--grey50)" }}
                        />
                        <div style={{ marginLeft: 10 }}>
                          <Input
                            name="templateName"
                            type="text"
                            view="outlined"
                            color="default"
                            onInput={e =>
                              setEditData({
                                ...editData,
                                name: e.target.value
                              })
                            }
                            value={editData.name}
                            fill={true}
                            error={false}
                            dense={true}
                            errorMessage={""}
                            style={{ width: "95%" }}
                          />
                        </div>
                      </FlexWrapper>
                      <IconButton
                        size="small"
                        onClick={async () => {
                          const isSuccess =
                            editData.name === template.name ||
                            (await props.updateSection(
                              template.sectionId,
                              editData.name
                            ));
                          if (isSuccess) {
                            setEditData({
                              index: null,
                              open: false,
                              name: ""
                            });
                          }
                        }}
                        disabled={editData.name === ""}
                        style={{ opacity: editData.name !== "" ? 1 : 0.5 }}
                      >
                        <Icon icon="tick" iconSize={16} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setEditData({ index: null, open: false, name: "" });
                        }}
                      >
                        <Icon icon="cross" iconSize={16} />
                      </IconButton>
                    </FlexWrapper>
                  )}
                </FlexWrapper>
              ))
            ) : (
              <MessageWrapper text="No template" />
            )}
          </Card>
        </FlexWrapper>
        <FlexWrapper
          flexFlow="column"
          alignItems="stretch"
          height="100%"
          width="25%"
        >
          <Card view="smooth" color="default" className={styles.header}>
            <h5 className={styles.heading}>Sections</h5>
            <Button
              type="icon"
              view="filled"
              color="primary"
              icon="plus"
              loading={false}
              disabled={selectedTemplate === null}
              onClick={() => setOpenChapterForm(true)}
            />
          </Card>
          <Card view="smooth" color="default" className={styles.tableCard}>
            {selectedTemplate !== null ? (
              <>
                {openChapterForm && (
                  <ChapterForm
                    ancestor={props.templates[selectedTemplate].sectionId}
                    hide={() => setOpenChapterForm(false)}
                  />
                )}
                {props.templates[selectedTemplate].children.length > 0 ? (
                  <TreeView
                    data={props.templates[selectedTemplate].children}
                    ancestor={props.templates[selectedTemplate].sectionId}
                    expanded={expanded}
                    handleClick={handleSelectedTree}
                    handleToggle={handleExpandedTree}
                    handleDelete={sectionId => showDialog(sectionId, "section")}
                  />
                ) : (
                  !openChapterForm && <MessageWrapper text="No sections" />
                )}
              </>
            ) : (
              <MessageWrapper text="No selected template" />
            )}
          </Card>
        </FlexWrapper>
        <FlexWrapper
          flexFlow="column"
          alignItems="stretch"
          height="100%"
          width="25%"
        >
          <Card view="smooth" color="default" className={styles.header}>
            <h5 className={styles.heading}>Variables</h5>
            <Button
              type="icon"
              view="filled"
              color="primary"
              icon="plus"
              loading={false}
              disabled={selectedSection === null}
              onClick={() => setOpenCreateVariableForm(true)}
            />
          </Card>
          <Card view="smooth" color="default" className={styles.tableCard}>
            {renderVariables()}
          </Card>
        </FlexWrapper>
        <FlexWrapper
          flexFlow="column"
          alignItems="stretch"
          height="100%"
          width="25%"
        >
          <Card view="smooth" color="default" className={styles.header}>
            <h5 className={styles.heading}>Details</h5>
          </Card>
          <Card view="smooth" color="default" className={styles.tableCard}>
            {selectedVariable !== null ? (
              renderVariableDetails()
            ) : (
              <MessageWrapper text="No selected variable" />
            )}
          </Card>
        </FlexWrapper>
      </FlexWrapper>
      <TemplateForm
        isOpen={openTemplateForm}
        onClose={() => setOpenTemplateForm(false)}
      />
      <CreateVariableForm
        isOpen={openCreateVariableForm}
        sectionData={selectedSection}
        onClose={() => setOpenCreateVariableForm(false)}
      />
      <EditVariableForm
        isOpen={openEditVariableForm}
        data={editVariableData}
        sectionData={selectedSection}
        onClose={() => setOpenEditVariableForm(false)}
      />
      <Dialog
        view="raised"
        color="default"
        title={`Delete ${upperFirst(dialog.type)}`}
        text={`Are you sure you want to delete this ${dialog.type} ?`}
        backdropOpacity={40}
        rightButton={
          <>
            <Button
              view="flat"
              color="default"
              dense={false}
              disabled={props.loadingMutation}
              onClick={closeDialog}
              text="Cancel"
              style={{ marginRight: 10 }}
            />
            <Button
              view="filled"
              color="danger"
              dense={false}
              loading={props.loadingMutation}
              onClick={handleDelete}
              text="Delete"
            />
          </>
        }
        isOpen={dialog.open}
        onClose={closeDialog}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  loadingData: loadingSelectorQuery(state),
  loadingMutation: loadingSelectorMutation(state),
  loadingVariable: createLoadingSelector(["GET_VARIABLE_LIST"])(state),
  templates: state.admin.templates,
  variables: state.admin.variables
});

const mapDispatchToProps = dispatch => ({
  getTemplateList: () => dispatch(getTemplateList()),
  addSection: name => dispatch(addSection(name)),
  updateSection: (sectionId, name) => dispatch(updateSection(sectionId, name)),
  removeSection: sectionId => dispatch(removeSection(sectionId)),
  getVariableList: sectionId => dispatch(getVariableList(sectionId)),
  removeVariable: (id, sectionId) => dispatch(removeVariable(id, sectionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateSection);
