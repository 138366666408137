import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import IconButton from "@material-ui/core/IconButton";
import { Done, DoneAll, Clear as Cross } from "@material-ui/icons";
import Select from "react-select";
import FlexWrapper from "../../components/FlexWrapper";
import Button from "../../components/@setproduct-ui/core/Button";
import Heading from "../../components/Heading";
import { setSelectedSection, changeSelectedChapter, updateAnnotation, getSections } from "../../redux/actions/annotation";
import { createLoadingSelector } from "../../redux/api/loading";

const loadingSelectorMutation = createLoadingSelector(["UPDATE_ANNOTATION"]);

const displayAnnotationStatusIcon = status => {
  if (status === 2)
    return <Done style={{ fontSize: 18, color: 'var(--green50)' }} />;
  if (status === 3)
    return <DoneAll style={{ fontSize: 18, color: 'var(--green50)' }} />;
  if (status === 4)
    return <Cross style={{ fontSize: 18, color: 'var(--red50)' }} />;
  return null;
};

function isAccepted(status) {
  return status === 3;
}

function AnnotationSection(props) {
  const dispatch = useDispatch();
  const loadingMutation = useSelector(state => loadingSelectorMutation(state));
  const sections = useSelector(state => state.annotation.sections);
  const sectionId = useSelector(state => state.annotation.sectionId);
  const type = useSelector(state => state.annotation.type);
  const selectedSection = useSelector(
    state => state.annotation.selectedSection
  );
  const selectedBoundingBox = useSelector(
    state => state.annotation.selectedBoundingBox
  );
  const selectedPage = useSelector(state => state.annotation.selectedPage);
  const selectedChapter = useSelector(state => state.annotation.selectedChapter);
  const chapterList = useSelector(state => state.annotation.chapterList);
  const loadingChapterList = useSelector(state => createLoadingSelector(["GET_CHAPTER_DETAIL", "GET_CHAPTER_LIST_OPTION"])(state));
  const loadingAnnotationData = useSelector(createLoadingSelector(['GET_ANNOTATION_CHAPTER', 'GET_ANNOTATION_SUBCHAPTER', 'GET_TAKEN_BOUNDARIES']));
  const isError = useSelector(state => state.annotation.isErrorSectionAnnotation);
  const handleUpdateAnnotation = () => dispatch(updateAnnotation(selectedSection));

  return (
    <>
      <Heading text={`${type}S`} style={{ marginLeft: 10 }} />
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        padding="10px"
        style={{ fontSize: 12 }}
      >
        <div
          style={{
            maxHeight: "20vh",
            overflowY: "auto"
          }}
        >
          {!isError && Object.keys(sections).map(sectionId => (
            <FlexWrapper
              key={sectionId}
              justifyContent="space-between"
              padding="5px"
              margin="0 0 2px 0"
              style={{
                cursor: "pointer",
                background:
                  selectedSection === sectionId ? "var(--grey10)" : "",
                borderRadius: 3
              }}
              onClick={() => {
                !loadingMutation && dispatch(setSelectedSection(sectionId));
              }}
            >
              <FlexWrapper>
                <Icon
                  icon="folder-close"
                  iconSize={16}
                  style={{
                    color: "var(--grey50)",
                    marginRight: 10
                  }}
                />
                <h4 style={{ margin: 0 }}>{sections[sectionId].name}</h4>
              </FlexWrapper>
              {sections[sectionId].annotation.id &&
                displayAnnotationStatusIcon(
                  sections[sectionId].annotation.status
                )}
            </FlexWrapper>
          ))}
        </div>
      </FlexWrapper>
      {!isError && selectedPage[selectedSection] && selectedBoundingBox[selectedSection] && (
        <>
          <Heading
            text="Selected Boundaries"
            style={{ marginTop: 10, marginLeft: 10, marginBottom: 5 }}
          />
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
            style={{ fontSize: 12 }}
          >
            {selectedPage[selectedSection].length ||
            selectedBoundingBox[selectedSection].length ? (
              <div
                style={{
                  maxHeight: "20vh",
                  overflowY: "auto"
                }}
              >
                {selectedPage[selectedSection].map((page, index) => (
                  <FlexWrapper
                    key={index}
                    justifyContent="space-between"
                    padding="0 5px"
                    margin="0 0 10px 0"
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <h4
                      style={{ margin: 0, color: "var(--grey50)" }}
                      onClick={() => props.handleClickBoundary(page.pageNumber)}
                    >
                      {page.id}
                    </h4>
                    {!isAccepted(
                      sections[selectedSection].annotation.status
                    ) && (
                      <IconButton
                        size="small"
                        onClick={() => props.handleRemoveSelectedPage(page.id)}
                      >
                        <Icon
                          icon="cross"
                          iconSize={16}
                          style={{ color: "var(--grey50)" }}
                        />
                      </IconButton>
                    )}
                  </FlexWrapper>
                ))}
                {selectedBoundingBox[selectedSection].map((node, index) => (
                  <FlexWrapper
                    key={index}
                    justifyContent="space-between"
                    padding="0 5px"
                    margin="0 0 10px 0"
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <h4
                      style={{ margin: 0, color: "var(--grey50)" }}
                      onClick={() => props.handleClickBoundary(node.pageNumber)}
                    >
                      {node.id}
                    </h4>
                    {!isAccepted(
                      sections[selectedSection].annotation.status
                    ) && (
                      <IconButton
                        size="small"
                        onClick={() => props.handleRemoveSelectedBox(node.id)}
                      >
                        <Icon
                          icon="cross"
                          iconSize={16}
                          style={{ color: "var(--grey50)" }}
                        />
                      </IconButton>
                    )}
                  </FlexWrapper>
                ))}
              </div>
            ) : (
              <span style={{ marginLeft: 5, color: "var(--grey50)" }}>
                No selected boundaries
              </span>
            )}
            {type === "SUBCHAPTER" && (selectedPage[selectedSection].length || selectedBoundingBox[selectedSection].length) ? (
              <>
                <label htmlFor="chapterLocation"
                  style={{
                    marginTop: 10,
                    display: "block",
                    fontFamily: "var(--inter)",
                    fontSize: 12,
                    color: "#656565",
                    fontWeight: 500,
                    textTransform: "uppercase"
                  }}
                >
                  Chapter Location
                </label>
                <div style={{ margin: "10px 0" }}>
                  {loadingChapterList ? "Loading..." : (
                    <Select
                      name="chapterLocation"
                      value={chapterList.find(chapter => chapter.value === selectedChapter[selectedSection])}
                      options={chapterList}
                      onChange={({ value }) =>
                        !isAccepted(sections[selectedSection].annotation.status) ?
                          dispatch(changeSelectedChapter(selectedSection, value)) : null
                      }
                      styles={{
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: 80
                        })
                      }}
                    />
                  )}
                </div>
              </>
            ) : null}
            {sections[selectedSection] &&
              !isAccepted(sections[selectedSection].annotation.status) && (
                <FlexWrapper margin="10px 0 0 0">
                  <Button
                    view="flat"
                    text="Discard"
                    color="danger"
                    fill={true}
                    dense={true}
                    disabled={loadingMutation}
                    onClick={props.handleResetSelected}
                    style={{
                      fontSize: "var(--font_size_body_small)",
                      marginRight: 5
                    }}
                  />
                  <Button
                    view="filled"
                    text="Save"
                    color="primary"
                    fill={true}
                    dense={true}
                    loading={loadingMutation}
                    disabled={false}
                    onClick={handleUpdateAnnotation}
                    style={{ fontSize: "var(--font_size_body_small)" }}
                  />
                </FlexWrapper>
              )}
          </FlexWrapper>
        </>
      )}
      {isError && (
        <FlexWrapper
          flexFlow="column"
          justifyContent="flex-start"
          alignItems="stretch"
          padding="10px"
          style={{ fontSize: 12, marginTop: -20 }}
        >
          <p style={{ marginBottom: 10 }}>An error occurred</p>
          <Button
            view="filled"
            text="Reload annotation data"
            loading={loadingAnnotationData}
            disabled={false}
            style={{ marginRight: 12, fontWeight: 500 }}
            onClick={async () => {
              dispatch(getSections(sectionId))
            }}
          />
        </FlexWrapper>
      )}
    </>
  );
}

export default AnnotationSection;
