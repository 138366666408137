import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Button from '../../components/@setproduct-ui/core/Button';
import SearchInput from '../../components/SearchInput';
import Chips from '../../components/Chips';
import Menu from '../../components/@setproduct-ui/core/Menu';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import Dialog from '../../components/@setproduct-ui/core/Dialog';
import DynamicAvatar from '../../components/DynamicAvatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@blueprintjs/core';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import AdvancedTableAction from '../../components/AdvancedTable/AdvancedTableAction';
import MetricCard from '../../components/MetricCard';
import MetricContainer from '../../components/MetricContainer';
import FormCreate from './FormCreate';
import FormEdit from './FormEdit';
import styles from '../../components/AdvancedTable/table.module.css';
import pageLayoutStyle from '../../layouts/DashboardLayout/Dashboard.module.css';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import { getComparator, stableSort } from '../../components/AdvancedTable';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import {
  changePageDataOnTask,
  changeTaskFilter,
  getTaskList,
  getTaskSummary,
  removeTask,
  selectTask,
  getDocumentsYear,
} from '../../redux/actions/admin';
import PATH_URL from '../../routers/path';
import { createLoadingSelector } from '../../redux/api/loading';
import FormTransfer from './FormTransfer';

const loadingSelectorQuery = createLoadingSelector(['GET_TASK_LIST']);
const loadingSelectorMutation = createLoadingSelector(['REMOVE_TASK']);

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TASK NAME',
  },
  {
    id: 'annotator.profile.name',
    numeric: false,
    disablePadding: false,
    label: 'ANNOTATOR',
  },
  {
    id: 'reviewer.profile.name',
    numeric: false,
    disablePadding: false,
    label: 'REVIEWER',
  },
  { id: 'dtype', numeric: false, disablePadding: false, label: 'TYPE' },
  {
    id: 'document.filename',
    numeric: false,
    disablePadding: false,
    label: 'DOCUMENT',
  },
  {
    id: 'section.name',
    numeric: false,
    type: 'date',
    disablePadding: false,
    label: 'SECTION',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'LAST UPDATE',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
  { id: 'menu', numeric: false, disablePadding: false, label: '' },
];
const CHIPS_COLORS = {
  'to do': 'default',
  ongoing: 'warning',
  'under review': 'primary',
  rejected: 'danger',
  accepted: 'success',
  'need revision': 'danger-alt',
};
function EnhancedMenu(props) {
  const { row, setMenu, setFormData, setFormDialog, showDialog, onClose } =
    props;
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu type="dense" view="smooth" color="default" className={styles.menu}>
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Edit task"
          onClick={() => {
            setMenu(null);
            setFormData(row);
            setFormDialog({ open: true, type: 'edit' });
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete task"
          onClick={() => showDialog(row.id)}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}

function Tasks() {
  const dispatch = useDispatch();
  const classes = useTableStyle();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [orderType, setOrderType] = useState('string');
  const [menu, setMenu] = useState(null);
  const [formDialog, setFormDialog] = useState({ open: false, type: 'create' });
  const [formData, setFormData] = useState({});
  const [dialog, setDialog] = useState({
    open: false,
    id: null,
  });
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const {
    filter,
    summary,
    pageData,
    list: tasks,
  } = useSelector(({ admin }) => admin.tasks);
  const selectedTasks = useSelector(({ admin }) => admin.selectedTasks);
  const documentsYear = useSelector((state) => state.admin.documentsYear);
  const loading = useSelector(loadingSelectorQuery);
  const loadingYearList = useSelector(
    createLoadingSelector(['GET_DOCUMENTS_YEAR'])
  );
  const loadingMutation = useSelector(loadingSelectorMutation);
  const loadingSummary = useSelector(
    createLoadingSelector(['GET_TASK_SUMMARY'])
  );

  useEffect(() => {
    dispatch(getDocumentsYear());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTaskSummary({
        type: filter.type,
        year: filter.year,
        status: filter.status,
      })
    );
  }, [dispatch, filter.type, filter.year, filter.status]);

  useEffect(() => {
    dispatch(
      getTaskList({
        ...filter,
        limit: pageData.limit,
        page: pageData.currentPageNumber,
      })
    );
    dispatch(selectTask([]));
  }, [dispatch, filter, pageData.limit, pageData.currentPageNumber]);

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setOrderType(type);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const referencedTask = tasks[0];
      const filteredTasks = tasks.filter(
        (task) =>
          referencedTask.annotator.id === task.annotator.id &&
          referencedTask.reviewer.id === task.reviewer.id
      );
      dispatch(selectTask(filteredTasks));
    } else {
      dispatch(selectTask([]));
    }
  };

  const handleClick = (event, task) => {
    if (selectedTasks.length > 0) {
      const referencedTask = selectedTasks[0];
      if (
        referencedTask.annotator.id === task.annotator.id &&
        referencedTask.reviewer.id === task.reviewer.id
      ) {
        const selected = selectedTasks.find(
          (selected) => selected.id === task.id
        );
        if (!selected) {
          dispatch(selectTask([...selectedTasks, task]));
        } else {
          const newSelected = selectedTasks.filter(
            (selected) => selected.id !== task.id
          );
          dispatch(selectTask(newSelected));
        }
      }
    } else {
      dispatch(selectTask([...selectedTasks, task]));
    }
  };

  const handleChangePage = (event, newPage) => {
    // material-ui using zero-based page
    dispatch(changePageDataOnTask({ currentPageNumber: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      changePageDataOnTask({
        limit: parseInt(event.target.value, 10),
        currentPageNumber: 1,
      })
    );
  };

  const closeDialog = () => {
    setDialog({ open: false, id: null });
    setMenu(null);
  };

  const showDialog = (id) => {
    setDialog({ open: true, id });
  };

  const handleDelete = () => {
    const { id } = dialog;
    const isSuccess = dispatch(removeTask(id));
    if (isSuccess) {
      closeDialog();
      setMenu(null);
    }
  };

  const isSelected = (id) => {
    const selected = selectedTasks.find((selected) => selected.id === id);
    return selected ? true : false;
  };

  const getSelectedType = (type) => {
    const uppercased = type.toUpperCase();
    if (type) return { value: uppercased, label: uppercased };
    return { value: '', label: 'Type' };
  };

  const getSelectedStatus = (status) => {
    if (status) return { value: status, label: status.toUpperCase() };
    return { value: '', label: 'Status' };
  };

  return (
    <div className={pageLayoutStyle.root}>
      <MetricContainer>
        <MetricCard
          title="ALL TASKS"
          number={summary.all_tasks}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="TO DO"
          number={summary.todo}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={loadingSummary}
        />
        <MetricCard
          title="ONGOING"
          number={summary.in_progress}
          description="-24% vs Last Month"
          iconBackgroundColor="var(--red50)"
          icon="trending-down"
          loading={loadingSummary}
        />
        <MetricCard
          title="UNDER REVIEW"
          number={summary.on_review}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="REJECTED"
          number={summary.rejected}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
        <MetricCard
          title="ACCEPTED"
          number={summary.done}
          description="+24% vs Last Month"
          iconBackgroundColor="var(--green50)"
          icon="trending-up"
          loading={loadingSummary}
        />
      </MetricContainer>
      <div className={styles.tableContainer}>
        <div className={styles.tableToolbar}>
          <h1 className={styles.tableTitle}>All Tasks</h1>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <SearchInput
              defaultValue={filter.q}
              onApplySearch={(value) => {
                dispatch(changeTaskFilter({ q: value }));
              }}
              placeholder="Search"
              style={{ width: 250, marginRight: 10 }}
            />
            <Select
              name="type"
              value={getSelectedType(filter.type)}
              isSearchable={false}
              defaultValue={{ value: '', label: 'Type' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ type: selected.value }))
              }
              options={[
                { value: '', label: 'Type' },
                {
                  value: 'CHAPTER',
                  label: 'CHAPTER',
                },
                {
                  value: 'SUBCHAPTER',
                  label: 'SUBCHAPTER',
                },
                {
                  value: 'VARIABLE',
                  label: 'VARIABLE',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Select
              name="year"
              isSearchable={false}
              defaultValue={{ value: '', label: 'Year' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ year: selected.value }))
              }
              options={[
                { value: '', label: 'Year' },
                ...documentsYear.map((year) => ({
                  value: year,
                  label: year,
                })),
              ]}
              isLoading={loadingYearList}
              styles={tableSelectStyle}
            />
            <Select
              name="status"
              value={getSelectedStatus(filter.status)}
              isSearchable={false}
              defaultValue={{ value: '', label: 'Status' }}
              onChange={(selected) =>
                dispatch(changeTaskFilter({ status: selected.value }))
              }
              options={[
                { value: '', label: 'Status' },
                {
                  value: 'todo',
                  label: 'TODO',
                },
                {
                  value: 'ongoing',
                  label: 'ONGOING',
                },
                {
                  value: 'under review',
                  label: 'UNDER REVIEW',
                },
                {
                  value: 'rejected',
                  label: 'REJECTED',
                },
                {
                  value: 'accepted',
                  label: 'ACCEPTED',
                },
                {
                  value: 'need revision',
                  label: 'NEED REVISION',
                },
              ]}
              styles={tableSelectStyle}
            />
            <Button
              view="filled"
              color="primary"
              text="Assign Task"
              loading={false}
              disabled={false}
              onClick={() => setFormDialog({ open: true, type: 'create' })}
              className={styles.mainButton}
              style={{
                background: 'var(--blue70)',
                height: 42,
                boxShadow: 'none',
              }}
            />
          </FlexWrapper>
        </div>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <AdvancedTableHeader
              classes={classes}
              selectionCounter={selectedTasks.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={tasks.length}
              columns={columns}
            />
            <TableBody>
              {loading ? (
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell className={classes.checkbox}>
                        <Skeleton
                          height={20}
                          width={20}
                          style={{ marginLeft: 10 }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.cell}
                      >
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Skeleton
                            circle={true}
                            height={30}
                            width={30}
                            style={{ marginRight: 10 }}
                          />
                          <Skeleton width={80} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Skeleton
                            circle={true}
                            height={30}
                            width={30}
                            style={{ marginRight: 10 }}
                          />
                          <Skeleton width={80} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={30} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : tasks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(tasks, getComparator(order, orderBy, orderType)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.checkbox}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                          className={classes.cell}
                        >
                          <Link
                            to={PATH_URL.SPECTATE.replace(':task_id', row.id)}
                          >
                            {row.name}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <DynamicAvatar
                              photoUrl={row.annotator.photoUrl}
                              name={row.annotator.profile.name}
                              color="var(--green50)"
                            />
                            <span className={classes.nameCell}>
                              {row.annotator.profile.name}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <DynamicAvatar
                              photoUrl={row.reviewer.photoUrl}
                              name={row.reviewer.profile.name}
                              color="var(--green50)"
                            />
                            <span className={classes.nameCell}>
                              {row.reviewer.profile.name}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.dtype}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.document.filename}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.dtype === 'CHAPTER'
                            ? '-'
                            : row.section && row.section.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.updatedAt &&
                            format(new Date(row.updatedAt), 'LLL d, yyyy')}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Chips
                            color={
                              CHIPS_COLORS[row.status.toLowerCase()] ||
                              'default'
                            }
                            text={row.status.toUpperCase()}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ position: 'relative' }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (menu === row.id) setMenu(null);
                              else setMenu(row.id);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === row.id && (
                            <EnhancedMenu
                              row={row}
                              setMenu={setMenu}
                              showDialog={showDialog}
                              setFormDialog={setFormDialog}
                              setFormData={setFormData}
                              onClose={() => setMenu(null)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          {selectedTasks.length > 0 && (
            <AdvancedTableAction
              selectionCounter={selectedTasks.length}
              actions={[
                {
                  icon: 'exchange',
                  onClick: () => {
                    setOpenTransferDialog(true);
                  },
                },
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30]}
            component="div"
            count={pageData.totalItems}
            rowsPerPage={pageData.limit}
            labelDisplayedRows={() => 'rows'}
            labelRowsPerPage={'Show'}
            // material-ui using zero-based page
            page={pageData.currentPageNumber - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Dialog
        view="raised"
        title="Delete Task"
        text="Are you sure you want to delete this task?"
        backdropOpacity={40}
        rightButton={
          <>
            <Button
              view="flat"
              color="default"
              dense={false}
              onClick={closeDialog}
              disabled={loadingMutation}
              text="Cancel"
              style={{ marginRight: 10 }}
            />
            <Button
              view="filled"
              color="danger"
              dense={false}
              onClick={handleDelete}
              loading={loadingMutation}
              text="Delete"
            />
          </>
        }
        isOpen={dialog.open}
        onClose={closeDialog}
      />
      <FormCreate
        isOpen={formDialog.open && formDialog.type === 'create'}
        onClose={() => setFormDialog({ ...formDialog, open: false })}
      />
      <FormEdit
        isOpen={formDialog.open && formDialog.type === 'edit'}
        onClose={() => setFormDialog({ ...formDialog, open: false })}
        form={formData}
      />
      <FormTransfer
        isOpen={openTransferDialog}
        onClose={() => {
          dispatch(selectTask([]));
          setOpenTransferDialog(false);
        }}
      />
    </div>
  );
}

export default Tasks;
