import { apiRequest } from '../utils/apiRequest';

export const getUserList = () => async (dispatch) => {
  await apiRequest(dispatch, 'GET_USER_LIST', 'get', '/auth/list');
};

export const selectUser = (user) => ({
  type: 'SELECT_USER',
  payload: user,
});

export const acceptUser =
  (id, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const data = [
      {
        id,
        block_status: false,
      },
    ];
    const actionDispatched = await apiRequest(
      dispatch,
      'ACCEPT_USER',
      'put',
      '/auth/changeAuth',
      data
    );
    if (actionDispatched.type === 'ACCEPT_USER_SUCCESS') {
      if (config.refetchAll) dispatch(getUserList());
      else {
        const id = getState().admin.users.selected.id;
        dispatch(getUserDetail(id));
      }
    }
  };

export const acceptSelectedUser = () => async (dispatch, getState) => {
  const { selectedUsers } = getState().admin;
  const data = selectedUsers
    .filter((user) => user.emailVerified)
    .map((user) => ({
      id: user.id,
      block_status: false,
    }));
  if (data.length > 0) {
    const actionDispatched = await apiRequest(
      dispatch,
      'ACCEPT_USER',
      'put',
      '/auth/changeAuth',
      data
    );
    if (actionDispatched.type === 'ACCEPT_USER_SUCCESS') {
      dispatch(getUserList());
    }
  }
};

export const declineUser =
  (id, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const data = [
      {
        id,
        block_status: true,
      },
    ];
    const actionDispatched = await apiRequest(
      dispatch,
      'DECLINE_USER',
      'put',
      '/auth/changeAuth',
      data
    );
    if (actionDispatched.type === 'DECLINE_USER_SUCCESS') {
      if (config.refetchAll) dispatch(getUserList());
      else {
        const id = getState().admin.users.selected.id;
        dispatch(getUserDetail(id));
      }
      return true;
    }
  };

export const declineSelectedUser = () => async (dispatch, getState) => {
  const { selectedUsers } = getState().admin;
  const data = selectedUsers
    .filter((user) => user.emailVerified)
    .map((user) => ({
      id: user.id,
      block_status: true,
    }));
  if (data.length > 0) {
    const actionDispatched = await apiRequest(
      dispatch,
      'DECLINE_USER',
      'put',
      '/auth/changeAuth',
      data
    );
    if (actionDispatched.type === 'DECLINE_USER_SUCCESS') {
      dispatch(getUserList());
    }
  }
};

export const deleteUser = (profileId, uid) => async (dispatch) => {
  const { type } = await apiRequest(
    dispatch,
    'DELETE_USER',
    'delete',
    '/auth/delete',
    {
      id: profileId,
      uid,
    }
  );
  if (type === 'DELETE_USER_SUCCESS') {
    dispatch(getUserList());
    return true;
  }
};

export const changeRole =
  (id, role, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const data = [
      {
        id,
        role,
      },
    ];
    const actionDispatched = await apiRequest(
      dispatch,
      'CHANGE_USER_ROLE',
      'put',
      '/auth/changeAuth',
      data
    );
    if (actionDispatched.type === 'CHANGE_USER_ROLE_SUCCESS') {
      if (config.refetchAll) dispatch(getUserList());
      else {
        const id = getState().admin.users.selected.id;
        dispatch(getUserDetail(id));
      }
      return true;
    }
  };

export const getTemplateList = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_TEMPLATE_LIST',
    'get',
    '/sections/list/template/chapter/sub'
  );
};

export const addSection = (data) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'ADD_SECTION',
    'post',
    `sections/add`,
    [data]
  );
  if (actionDispatched.type === 'ADD_SECTION_SUCCESS') {
    dispatch(getTemplateList());
    return true;
  }
};

export const updateSection = (sectionId, name) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'UPDATE_SECTION',
    'put',
    `sections/update/${sectionId}`,
    { name }
  );
  if (actionDispatched.type === 'UPDATE_SECTION_SUCCESS') {
    dispatch(getTemplateList());
    return true;
  }
};

export const removeSection = (sectionId) => async (dispatch) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'REMOVE_SECTION',
    'delete',
    `sections/delete/${sectionId}`
  );
  if (actionDispatched.type === 'REMOVE_SECTION_SUCCESS') {
    dispatch(getTemplateList());
    return true;
  }
};

export const getTaskList = (params) => async (dispatch, getState) => {
  await apiRequest(dispatch, 'GET_TASK_LIST', 'get', '/tasks/list', params);
};

export const getTaskSummary = (filter) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_TASK_SUMMARY',
    'get',
    '/tasks/summary',
    filter
  );
};

export const selectTask = (tasks) => ({
  type: 'SELECT_TASK',
  payload: tasks,
});

export const changeTaskFilter = (filter) => ({
  type: 'CHANGE_TASK_FILTER',
  payload: filter,
});

export const changePageDataOnTask = (pageData) => ({
  type: 'CHANGE_PAGE_DATA_ON_TASK',
  payload: pageData,
});

export const assignTask =
  (data, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const actionDispatched = await apiRequest(
      dispatch,
      'ASSIGN_TASK',
      'post',
      '/tasks/add',
      data
    );
    if (actionDispatched.type === 'ASSIGN_TASK_SUCCESS') {
      if (config.refetchAll) {
        const { filter, pageData } = getState().admin.tasks;
        dispatch(
          getTaskList({
            ...filter,
            limit: pageData.limit,
            page: pageData.currentPageNumber,
          })
        );
      } else {
        const { id, role } = getState().admin.users.selected;
        dispatch(getUserTasks(id, role));
      }
      return true;
    }
  };

export const updateTask =
  (data, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const actionDispatched = await apiRequest(
      dispatch,
      'UPDATE_TASK',
      'put',
      '/tasks/update',
      { ...data, action: 'updateData' }
    );
    if (actionDispatched.type === 'UPDATE_TASK_SUCCESS') {
      if (config.refetchAll) {
        const { filter, pageData } = getState().admin.tasks;
        dispatch(
          getTaskList({
            ...filter,
            limit: pageData.limit,
            page: pageData.currentPageNumber,
          })
        );
      } else {
        const { id, role } = getState().admin.users.selected;
        dispatch(getUserTasks(id, role));
      }
      return true;
    }
  };

export const removeTask =
  (id, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const actionDispatched = await apiRequest(
      dispatch,
      'REMOVE_TASK',
      'delete',
      `tasks/delete/${id}`
    );
    if (actionDispatched.type === 'REMOVE_TASK_SUCCESS') {
      if (config.refetchAll) {
        const { filter, pageData } = getState().admin.tasks;
        dispatch(
          getTaskList({
            ...filter,
            limit: pageData.limit,
            page: pageData.currentPageNumber,
          })
        );
      } else {
        const { id, role } = getState().admin.users.selected;
        dispatch(getUserTasks(id, role));
      }
      return true;
    }
  };

export const getTemplates = () => async (dispatch) => {
  await apiRequest(dispatch, 'GET_TEMPLATES', 'get', '/sections/list/template');
};

export const getDocumentList = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_DOCUMENT_LIST',
    'get',
    '/annotation/documents/list'
  );
};

export const getAvailableAnnotators = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_AVAILABLE_ANNOTATORS',
    'get',
    '/tasks/person/annotator'
  );
};

export const getAvailableReviewers = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_AVAILABLE_REVIEWERS',
    'get',
    '/tasks/person/reviewer'
  );
};

export const getChapterList = (templateId) => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_CHAPTER_LIST',
    'get',
    `/sections/expand/${templateId}`
  );
};

export const getDocumentGroupList = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_DOCUMENT_GROUP_LIST',
    'get',
    `/groups/document`
  );
};

export const getAnnotatorTeamList = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    'GET_ANNOTATOR_TEAM_LIST',
    'get',
    `/groups/annotator`
  );
};

export const getDataTypeList = () => async (dispatch) => {
  await apiRequest(dispatch, 'GET_DATATYPE_LIST', 'get', `datatypes`);
};

export const addVariable = (data) => async (dispatch) => {
  const { type } = await apiRequest(
    dispatch,
    'ADD_VARIABLE',
    'post',
    `variables`,
    data
  );
  if (type === 'ADD_VARIABLE_SUCCESS') {
    const {
      sectionProbs: { id },
    } = data;
    dispatch(getVariableList(id));
    return true;
  }
};

export const updateVariable = (id, data) => async (dispatch) => {
  const { type } = await apiRequest(
    dispatch,
    'UPDATE_VARIABLE',
    'put',
    `variables/${id}`,
    data
  );
  if (type === 'UPDATE_VARIABLE_SUCCESS') {
    const {
      sectionProbs: { id: sectionId },
    } = data;
    dispatch(getVariableList(sectionId));
    return true;
  }
};

export const removeVariable = (id, sectionId) => async (dispatch) => {
  const { type } = await apiRequest(
    dispatch,
    'REMOVE_VARIABLE',
    'delete',
    `variables/${id}`
  );
  if (type === 'REMOVE_VARIABLE_SUCCESS') {
    dispatch(getVariableList(sectionId));
    return true;
  }
};

export const getVariableList = (sectionId) => async (dispatch, getState) => {
  const actionDispatched = await apiRequest(
    dispatch,
    'GET_VARIABLE_LIST',
    'get',
    `variables/${sectionId}`
  );
  if (actionDispatched.type === 'GET_VARIABLE_LIST_SUCCESS') {
    const { variables } = getState().admin;
    const { payload } = actionDispatched;
    const copy = Object.assign({}, variables);
    copy[sectionId] = payload;
    dispatch(setVariableList(copy));
  }
};

export const getVariableListDeep =
  (sectionId) => async (dispatch, getState) => {
    const actionDispatched = await apiRequest(
      dispatch,
      'GET_VARIABLE_LIST_DEEP',
      'get',
      `sections/expand/${sectionId}`
    );
    if (actionDispatched.type === 'GET_VARIABLE_LIST_DEEP_SUCCESS') {
      const { chapterVariables } = getState().admin;
      const { payload } = actionDispatched;
      const variables = payload.variables.map((v) => ({
        ...v,
        chapterId: sectionId,
      }));
      dispatch(setChapterVariable(chapterVariables.concat(variables)));
    }
  };

export const setVariableList = (variables) => ({
  type: 'SET_VARIABLE_LIST',
  payload: variables,
});

export const setChapterVariable = (variables) => ({
  type: 'SET_CHAPTER_VARIABLES',
  payload: variables,
});

export const resetChapterVariable = () => ({
  type: 'RESET_CHAPTER_VARIABLES',
});

export const selectUserDetail = (userData) => ({
  type: 'SELECT_USER_DETAIL',
  payload: userData,
});

export const getUserDetail = (id) => async (dispatch, getState) =>
  apiRequest(dispatch, 'GET_USER_DETAIL', 'get', `auth/detail/${id}`);

export const getUserTasks = (id, role) => async (dispatch, getState) =>
  apiRequest(dispatch, 'GET_USER_TASKS', 'get', `tasks/filter`, {
    id,
    role,
  });

export const transferTasks =
  (data, config = { refetchAll: true }) =>
  async (dispatch, getState) => {
    const actionDispatched = await apiRequest(
      dispatch,
      'TRANSFER_TASKS',
      'put',
      '/tasks/bulk-update',
      { ...data }
    );
    if (actionDispatched.type === 'TRANSFER_TASKS_SUCCESS') {
      const { filter, pageData } = getState().admin.tasks;
      if (config.refetchAll) {
        dispatch(
          getTaskList({
            ...filter,
            limit: pageData.limit,
            page: pageData.currentPageNumber,
          })
        );
      } else {
        const { id, role } = getState().admin.users.selected;
        dispatch(getUserTasks(id, role));
      }
      return true;
    }
  };

export const getCompanyTicker = () => async (dispatch) =>
  await apiRequest(dispatch, 'GET_COMPANY_TICKER', 'get', `/companies/tickers`);

export const getDocumentsYear = () => async (dispatch) =>
  await apiRequest(dispatch, 'GET_DOCUMENTS_YEAR', 'get', `/documents/years`);

export const getAnnotationsOverview =
  ({ page, limit, q }) =>
  async (dispatch, getState) => {
    const {
      limit: currentLimit,
      filter: { refId, yearRange, ticker, field1, field2 },
    } = getState().admin.variableOverview;
    const mapping = [field1, field2]
      .filter((index) => index > -1)
      .map((index) => `objAttr[${index}]`);
    dispatch(setAppliedMappingFilter(mapping));
    await apiRequest(
      dispatch,
      'GET_ANNOTATION_OVERVIEW',
      'post',
      '/annotations/overview',
      {
        page,
        limit: limit || currentLimit,
        refId,
        yearRange,
        ticker,
        mapping,
        ...(q ? { q } : {}),
      }
    );
  };

export const getVariableStructure = () => async (dispatch) =>
  await apiRequest(
    dispatch,
    'GET_VARIABLE_STRUCTURE',
    'get',
    '/variables/structures'
  );

export const setAnnotationOverviewFilter = (filter) => ({
  type: 'SET_ANNOTATION_OVERVIEW_FILTER',
  payload: filter,
});

export const getAnnotationsOverviewStats =
  (q) => async (dispatch, getState) => {
    const {
      filter: { refId, yearRange, ticker, field1, field2 },
    } = getState().admin.variableOverview;
    const mapping = [field1, field2]
      .filter((index) => index > -1)
      .map((index) => `objAttr[${index}]`);
    await apiRequest(
      dispatch,
      'GET_ANNOTATION_OVERVIEW_STATS',
      'post',
      '/annotations/overview/stats',
      {
        refId,
        yearRange,
        ticker,
        mapping,
        ...(q ? { q } : {}),
      }
    );
  };

const setAppliedMappingFilter = (mapping) => ({
  type: 'SET_APPLIED_MAPPING_FILTER',
  payload: mapping,
});

export const acceptAnnotationField =
  (id, mapping, position) => async (dispatch) => {
    const { type } = await apiRequest(
      dispatch,
      `ACCEPT_ANNOTATION_FIELD`,
      'post',
      `annotations/accept-field`,
      { id, mapping, position },
      'application/json'
    );
    return type === 'ACCEPT_ANNOTATION_FIELD_SUCCESS';
  };

export const rejectAnnotationField =
  (id, mapping, position) => async (dispatch) => {
    const { type } = await apiRequest(
      dispatch,
      `REJECT_ANNOTATION_FIELD`,
      'post',
      `annotations/reject-field`,
      { id, mapping, position },
      'application/json'
    );
    return type === 'REJECT_ANNOTATION_FIELD_SUCCESS';
  };
