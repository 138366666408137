import React from "react";

function BoundingBox(props) {
  const { isSelected } = props;

  return (
    <div
      style={{
        position: "absolute",
        top: props.topLeft.y,
        left: props.topLeft.x,
        height: props.height,
        width: props.width,
        cursor: "pointer",
        border: isSelected
          ? "2px solid var(--blue40)"
          : "2px solid var(--yellow40)",
        background: isSelected && "rgba(118, 169, 255, 0.2)",
        ...props.style
      }}
      onClick={props.onClick}
    ></div>
  );
}

export default BoundingBox;
