import { actionType } from "../reducers/document";
import { apiRequest } from "../utils/apiRequest";

const listDocumentSuccess = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_SUCCESS,
    payload: authData,
  };
};

const listDocumentFailure = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_FAILURE,
    payload: authData,
  };
};

const listDocumentOcrSuccess = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_OCR_SUCCESS,
    payload: authData,
  };
};

const listDocumentOcrFailure = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_OCR_FAILURE,
    payload: authData,
  };
};

const listDocumentOcrMultipleSuccess = (authData) => {
  return {
    type: actionType.LIST_DOCUMENT_OCR_MULTIPLE,
    payload: authData,
  };
};

export const listDocument = (data) => async (dispatch) => {
  const documentList = await apiRequest(
    dispatch,
    "LIST_DOCUMENT",
    "get",
    "/annotation/documents/list",
    data
  );
  if (documentList.type === actionType.LIST_DOCUMENT_SUCCESS) {
    dispatch(listDocumentSuccess(documentList.payload));
  } else {
    dispatch(listDocumentFailure(documentList));
  }
};

export const getDocumentSummary = () => async (dispatch) => {
  await apiRequest(
    dispatch,
    "GET_DOCUMENT_SUMMARY",
    "get",
    "/annotation/documents/summary"
  );
};

export const changeDocumentFilter = (filter) => ({
  type: 'CHANGE_DOCUMENT_FILTER',
  payload: filter,
});

export const changePageDataOnDocument = (pageData) => ({
  type: 'CHANGE_PAGE_DATA_ON_DOCUMENT',
  payload: pageData,
});

export const ocrListDocumentMultiple = (documents) => {
  return async (dispatch) => {
    let result = await Promise.all(
      documents.map((item) => {
        return apiRequest(
          dispatch,
          "LIST_DOCUMENT_OCR",
          "post",
          `/annotation/document/${item.id}/preprocessing`,
          {
            storeWords: true,
          }
        );
      })
    );
    dispatch(listDocumentOcrMultipleSuccess(result));
  };
};

export const ocrListDocument = (paramsId) => async (dispatch) => {
  const listDocumentOcr = await apiRequest(
    dispatch,
    "LIST_DOCUMENT_OCR",
    "post",
    `/annotation/document/${paramsId}/preprocessing`,
    {
      storeWords: true,
    }
  );
  if (listDocumentOcr.type === "LIST_DOCUMENT_OCR_SUCCESS") {
    dispatch(listDocumentOcrSuccess(listDocumentOcr));
  }
};

const listDocumentDeleteSuccess = (authData, paramsId) => {
  return {
    type: "LIST_DOCUMENT_DELETE_SUCCESS",
    payload: authData,
    params: paramsId,
  };
};

const listDocumentDeleteFailure = (authData) => {
  return {
    type: "LIST_DOCUMENT_DELETE_FAILURE",
    payload: authData,
  };
};

export const listDocumentDelete = (
  paramsId,
  setIsShowModalDialogDelete
) => async (dispatch) => {
  const deleteListDocument = await apiRequest(
    dispatch,
    "LIST_DOCUMENT_DELETE",
    "delete",
    `/annotation/document/${paramsId}`
  );
  if (deleteListDocument.type === "LIST_DOCUMENT_DELETE_SUCCESS") {
   // dispatch(listDocumentDeleteSuccess(deleteListDocument, paramsId));
    setIsShowModalDialogDelete(false);
  } else {
   // dispatch(listDocumentDeleteFailure(deleteListDocument));
    setIsShowModalDialogDelete(true);
  }
};

export const selectDocument = (documents) => ({
  type: "SELECT_DOCUMENT",
  payload: documents,
});
