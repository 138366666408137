import api from "../../utils/api";

const { NODE_ENV } = process.env;

export const apiRequest = async (
  dispatch,
  actionType,
  method,
  url,
  data,
  contentType = "application/json",
  onSuccess
) => {
  dispatch({ type: `${actionType}_REQUEST` });
  const dataOrParams = ["get", "delete"].includes(method) ? "params" : "data";
  try {
    let response = await api.request({
      url,
      method,
      headers: { "content-type": contentType },
      [dataOrParams]: data
    });

    // log API Request in development. disable on staging and production
    if (NODE_ENV === "development") {
      console.log(response.data.data);
    }

    return dispatch({
      type: `${actionType}_SUCCESS`,
      payload: response.data.data
    });

    // if (onSuccess) {
    //     return dispatch(onSuccess(data));
    // } else {
    //     return;
    // }
  } catch (error) {
    if (typeof error.response != `undefined`) {
      error = error.response.data
    }
    return dispatch({
      type: `${actionType}_FAILURE`,
      payload: error
    });
  }
};

export const apiAction = (
  actionType = "API_CALL",
  method = "get",
  url = "",
  data = null,
  onSuccess = () => {},
  onFailure = () => {}
) => async dispatch =>
  apiRequest(dispatch, actionType, method, url, data, onSuccess);
