import { modifyFiles } from "../../utils/uploadFile";
import uploadFileTypes from "../actions/uploadFileTypes";

const initialState = {
  upload: "",
  uploadError: "",
  showProgress: false,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BULK_UPLOAD_DOCUMENT_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        upload: action.payload,
      };
    case "BULK_UPLOAD_DOCUMENT_FAILURE":
      return {
        ...state,
        uploadError: action.payload,
      };
    case "BULK_UPLOAD_DOCUMENT_RENDER":
      return {
        ...state,
        uploadError: "",
      };
    case uploadFileTypes.SET_UPLOAD_FILE:
      console.log(action.payload);
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload),
        },
      };

    case uploadFileTypes.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
            numPages: action.payload.numPages,
          },
        },
        showProgress: true,
      };

    case uploadFileTypes.SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        showProgress: false,
        fileProgress: {},
      };

    case uploadFileTypes.FAILURE_UPLOAD_FILE:
      return {
        ...state,
        showProgress: false,
        fileProgress: {},
      };
    default:
      return state;
  }
};

export default uploadReducer;
