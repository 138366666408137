const PRIVATE = '/';
const LOGIN = '/a/login';
const DASHBOARD = '/m/';
const TASKS = '/m/tasks';
const USERS = '/m/users';
const DOCUMENTS = '/m/documents';
const DOCUMENTSDETAIL = '/m/documents/:id';
const COMPANIES = '/m/companies';
const ANNOTATORS = '/m/annotators';
const TEMPLATES = '/m/templates';
const DOCUMENT_GROUPS = '/m/documents-groups';
const ANNOTATORS_TEAMS = '/m/annotators-team';
const REVIEWERS = '/m/reviewers';
const FORGOT_PASSWORD = '/a/forgot-password';
const REGISTER = '/a/register';
const RESET_PASSWORD = '/a/reset-password';
const VERIFY_EMAIL = '/verify-email';
const ADMIN = '/admin';
const EMAIL_ACTION = '/email';
const WAITING_APPROVAL = '/waiting-approval';
const DOCUMENT_PREVIEW = '/d/document-preview/:document_id';
const ANNOTATION = '/d/annotation/:task_id';
const REVIEW = '/d/review/:task_id';
const UNVERIFIED_EMAIL = '/unverified-email';
const NOT_FOUND = '*';
const SPECTATE = '/d/spectate/:task_id';
const USER_DETAIL = '/m/users/:id';
const VARIABLES = '/m/variables';

const PATH_URL = {
  DASHBOARD,
  COMPANIES,
  USERS,
  USER_DETAIL,
  TASKS,
  PRIVATE,
  ADMIN,
  LOGIN,
  EMAIL_ACTION,
  WAITING_APPROVAL,
  UNVERIFIED_EMAIL,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  FORGOT_PASSWORD,
  DOCUMENTS,
  DOCUMENTSDETAIL,
  REGISTER,
  DOCUMENT_PREVIEW,
  ANNOTATION,
  REVIEW,
  ANNOTATORS,
  REVIEWERS,
  TEMPLATES,
  DOCUMENT_GROUPS,
  NOT_FOUND,
  ANNOTATORS_TEAMS,
  SPECTATE,
  VARIABLES,
};

export default PATH_URL;
