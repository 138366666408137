import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { DoneAll, Clear as Cross } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FlexWrapper from '../../components/FlexWrapper';
import Heading from '../../components/Heading';
import Button from '../../components/@setproduct-ui/core/Button';
import {
  setSelectedSection,
  acceptAnnotation,
  rejectAnnotation,
  undoAcceptAnnotation,
  updateAnnotation,
} from '../../redux/actions/annotation';
import { createLoadingSelector } from '../../redux/api/loading';
import Spinner from '@material-ui/core/CircularProgress';

const loadingSelectorMutation = createLoadingSelector([
  'ACCEPT_ANNOTATION',
  'REJECT_ANNOTATION',
  'UNDO_ACCEPT_ANNOTATION',
  'UPDATE_ANNOTATION',
]);

function AnnotationSection(props) {
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.annotation.sections);
  const type = useSelector((state) => state.annotation.type);
  const selectedSection = useSelector(
    (state) => state.annotation.selectedSection
  );
  const selectedBoundingBox = useSelector(
    (state) => state.annotation.selectedBoundingBox
  );
  const selectedPage = useSelector((state) => state.annotation.selectedPage);
  const selectedChapter = useSelector(
    (state) => state.annotation.selectedChapter
  );
  const chapterList = useSelector((state) => state.annotation.chapterList);
  const loadingChapterList = useSelector((state) =>
    createLoadingSelector(['GET_CHAPTER_DETAIL', 'GET_CHAPTER_LIST_OPTION'])(
      state
    )
  );
  const loadingMutation = useSelector(loadingSelectorMutation);
  const handleUpdateAnnotation = () =>
    dispatch(updateAnnotation(selectedSection));

  return (
    <>
      <Heading text={type} style={{ marginLeft: 10 }} />
      <FlexWrapper
        flexFlow="column"
        justifyContent="flex-start"
        alignItems="stretch"
        padding="10px"
        style={{ fontSize: 12 }}
      >
        <div
          style={{
            maxHeight: '25vh',
            overflowY: 'auto',
          }}
        >
          {Object.keys(sections).map((sectionId) => (
            <FlexWrapper
              key={sectionId}
              justifyContent="space-between"
              padding="5px"
              margin="0 0 2px 0"
              style={{
                cursor: 'pointer',
                background:
                  selectedSection === sectionId ? 'var(--grey10)' : '',
                borderRadius: 3,
              }}
              onClick={() =>
                !loadingMutation && dispatch(setSelectedSection(sectionId))
              }
            >
              <FlexWrapper>
                <Icon
                  icon="folder-close"
                  iconSize={16}
                  style={{
                    color: 'var(--grey50)',
                    marginRight: 10,
                  }}
                />
                <h4 style={{ margin: 0 }}>{sections[sectionId].name}</h4>
              </FlexWrapper>
              {sections[sectionId].annotation.id && (
                <FlexWrapper justifyContent="space-between">
                  {loadingMutation && (
                    <Spinner size={16} variant="indeterminate" />
                  )}
                  {!loadingMutation &&
                    sections[sectionId].annotation.status === 3 && (
                      <>
                        <DoneAll
                          style={{
                            fontSize: 18,
                            color: 'var(--green50)',
                            marginRight: 5,
                          }}
                        />
                        <IconButton
                          size="small"
                          title="Undo Accept"
                          onClick={() =>
                            dispatch(
                              undoAcceptAnnotation(
                                sectionId,
                                sections[sectionId].annotation.id
                              )
                            )
                          }
                          style={{
                            background: 'var(--grey0)',
                          }}
                        >
                          <Icon
                            icon="undo"
                            iconSize={12}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                      </>
                    )}
                  {!loadingMutation &&
                    sections[sectionId].annotation.status === 4 && (
                      <>
                        <Cross
                          style={{
                            fontSize: 18,
                            color: 'var(--red50)',
                            marginRight: 3,
                          }}
                        />
                        <IconButton
                          size="small"
                          title="Undo Accept"
                          onClick={() =>
                            dispatch(
                              undoAcceptAnnotation(
                                sectionId,
                                sections[sectionId].annotation.id
                              )
                            )
                          }
                          style={{
                            background: 'var(--grey0)',
                          }}
                        >
                          <Icon
                            icon="undo"
                            iconSize={12}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                      </>
                    )}
                  {!loadingMutation &&
                    sections[sectionId].annotation.status === 2 && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() =>
                            dispatch(
                              rejectAnnotation(
                                sectionId,
                                sections[sectionId].annotation.id
                              )
                            )
                          }
                          style={{
                            background: 'var(--grey0)',
                            marginRight: 5,
                          }}
                        >
                          <Icon
                            icon="cross"
                            iconSize={12}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() =>
                            dispatch(
                              acceptAnnotation(
                                sectionId,
                                sections[sectionId].annotation.id
                              )
                            )
                          }
                          style={{ background: 'var(--grey0)' }}
                        >
                          <Icon
                            icon="tick"
                            iconSize={12}
                            style={{ color: 'var(--grey50)' }}
                          />
                        </IconButton>
                      </>
                    )}
                </FlexWrapper>
              )}
            </FlexWrapper>
          ))}
        </div>
      </FlexWrapper>
      {selectedPage[selectedSection] && selectedBoundingBox[selectedSection] && (
        <>
          <Heading
            text="Selected Boundaries"
            style={{ marginTop: 10, marginLeft: 10 }}
          />
          <FlexWrapper
            flexFlow="column"
            justifyContent="flex-start"
            alignItems="stretch"
            padding="10px"
            style={{ fontSize: 12 }}
          >
            {selectedPage[selectedSection].length ||
            selectedBoundingBox[selectedSection].length ? (
              <>
                <div
                  style={{
                    maxHeight: '25vh',
                    overflowY: 'auto',
                  }}
                >
                  {selectedPage[selectedSection].map((page, index) => (
                    <FlexWrapper
                      key={index}
                      justifyContent="space-between"
                      padding="0 5px"
                      margin="0 0 10px 0"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                          color: 'var(--grey50)',
                        }}
                        onClick={() =>
                          props.handleClickBoundary(page.pageNumber)
                        }
                      >
                        {page.id}
                      </h4>
                      <IconButton
                        size="small"
                        onClick={() => props.handleRemoveSelectedPage(page.id)}
                      >
                        <Icon
                          icon="cross"
                          iconSize={16}
                          style={{ color: 'var(--grey50)' }}
                        />
                      </IconButton>
                    </FlexWrapper>
                  ))}
                  {selectedBoundingBox[selectedSection].map((node, index) => (
                    <FlexWrapper
                      key={index}
                      justifyContent="space-between"
                      padding="0 5px"
                      margin="0 0 10px 0"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <h4
                        style={{ margin: 0, color: 'var(--grey50)' }}
                        onClick={() =>
                          props.handleClickBoundary(node.pageNumber)
                        }
                      >
                        {node.id}
                      </h4>
                      <IconButton
                        size="small"
                        onClick={() => props.handleRemoveSelectedBox(node.id)}
                      >
                        <Icon
                          icon="cross"
                          iconSize={16}
                          style={{ color: 'var(--grey50)' }}
                        />
                      </IconButton>
                    </FlexWrapper>
                  ))}
                </div>
              </>
            ) : (
              <span style={{ marginLeft: 5, color: 'var(--grey50)' }}>
                No selected boundaries
              </span>
            )}
            {type === 'SUBCHAPTER' &&
            (selectedPage[selectedSection].length ||
              selectedBoundingBox[selectedSection].length) ? (
              <>
                <label
                  htmlFor="chapterLocation"
                  style={{
                    marginTop: 20,
                    display: 'block',
                    fontFamily: 'var(--inter)',
                    fontSize: 12,
                    color: '#656565',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                  }}
                >
                  Chapter Location
                </label>
                <div style={{ margin: '10px 0' }}>
                  {loadingChapterList ? (
                    'Loading...'
                  ) : (
                    <h5
                      style={{
                        border: '1px solid var(--grey20)',
                        borderRadius: 5,
                        padding: 10,
                        fontSize: 12,
                        fontFamily: 'var(--inter)',
                        color: 'var(--grey60)',
                      }}
                    >
                      {chapterList.length
                        ? chapterList.find(
                            (chapter) =>
                              chapter.value === selectedChapter[selectedSection]
                          ).label
                        : ''}
                    </h5>
                  )}
                </div>
              </>
            ) : null}
            {sections[selectedSection] && (
              <FlexWrapper margin="10px 0 0 0">
                <Button
                  view="flat"
                  text="Discard"
                  color="danger"
                  fill={true}
                  dense={true}
                  disabled={loadingMutation}
                  onClick={props.handleResetSelected}
                  style={{
                    fontSize: 'var(--font_size_body_small)',
                    marginRight: 5,
                  }}
                />
                <Button
                  view="filled"
                  text="Save"
                  color="primary"
                  fill={true}
                  dense={true}
                  loading={loadingMutation}
                  disabled={false}
                  onClick={handleUpdateAnnotation}
                  style={{ fontSize: 'var(--font_size_body_small)' }}
                />
              </FlexWrapper>
            )}
          </FlexWrapper>
        </>
      )}
    </>
  );
}

export default AnnotationSection;
