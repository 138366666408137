import React, { useRef } from 'react';
import FlexWrapper from '../../components/FlexWrapper';
import Button from '../../components/@setproduct-ui/core/Button';
import Checkbox from '../../components/@setproduct-ui/core/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@blueprintjs/core';
import styles from './style.module.css';

function Toolbar(props) {
  let inputRef = useRef();

  const parseOcrStatus = (status) => {
    switch (status) {
      case 1:
        return 'Unprocessed';
      case 2:
        return 'Queued';
      default:
        return 'In Progress';
    }
  };

  return (
    <FlexWrapper
      justifyContent="space-between"
      style={{
        border: '1px solid var(--grey10)',
        fontFamily: 'var(--inter)',
      }}
    >
      <FlexWrapper justifyContent="flex-start" padding="9px">
        {props.ocrStatus === 4 ? (
          <>
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf('BLOCK') !== -1}
              label="B"
              onChange={() => props.changeBoundingBoxType('BLOCK')}
            />
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf('PARAGRAPH') !== -1}
              label="P"
              onChange={() => props.changeBoundingBoxType('PARAGRAPH')}
            />
            <Checkbox
              type="dense"
              color="primary"
              checked={props.data.boundingBoxType.indexOf('WORD') !== -1}
              label="W"
              onChange={() => props.changeBoundingBoxType('WORD')}
            />
          </>
        ) : (
          <span style={{ color: 'var(--grey60)', fontSize: 12 }}>
            OCR Status: <b>{parseOcrStatus(props.ocrStatus)}</b>
          </span>
        )}
      </FlexWrapper>
      <FlexWrapper className={styles.pageTools}>
        <IconButton
          size="small"
          onClick={props.prevPage}
          disabled={props.data.currentPage === 1 || !props.data.pageLoaded}
        >
          <Icon icon="chevron-left" iconSize={20} />
        </IconButton>
        <span style={{ margin: '0 5px' }}>
          <input
            ref={(el) => (inputRef = el)}
            type="text"
            value={props.data.currentPage}
            onChange={props.handlePageChange}
            onClick={({ target: { value } }) => {
              inputRef.setSelectionRange(0, value.length);
            }}
            className={styles.input}
          />{' '}
          / {props.data.numPages}
        </span>
        <IconButton
          size="small"
          onClick={props.nextPage}
          disabled={
            props.data.currentPage === props.data.numPages ||
            !props.data.pageLoaded
          }
        >
          <Icon icon="chevron-right" iconSize={20} />
        </IconButton>
      </FlexWrapper>
      <FlexWrapper padding="9px">
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="wrench"
          disabled={props.data.loadingShiftBoundaries}
          onClick={props.handleShiftBoundaries}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="trash"
          loading={props.data.loadingRemoveBoundaries}
          disabled={props.data.selectedBoxCount === 0}
          onClick={props.handleRemoveBoundaries}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-in"
          onClick={props.zoomIn}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-out"
          disabled={props.data.pageScale === 0.25}
          onClick={props.zoomOut}
        />
        <Button
          type="icon"
          view="flat"
          color="default"
          dense={true}
          icon="zoom-to-fit"
          onClick={props.resetPageScale}
        />
      </FlexWrapper>
    </FlexWrapper>
  );
}

export default Toolbar;
