import { range } from 'lodash';

const initialState = {
  users: {
    summary: {},
    list: [],
    selected: {
      id: null,
      role: null,
      uid: null,
      fullName: '',
      email: '',
      photoUrl: '',
      status: null,
      tasks: [],
    },
  },
  selectedUsers: [],
  selectedAnnotatorTeams: [],
  templates: [],
  chapters: [],
  tasks: {
    summary: {},
    filter: {
      q: '',
      type: '',
      status: '',
      year: '',
    },
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    list: [],
  },
  selectedTasks: [],
  chapterVariables: [],
  dataTypes: [],
  variables: {},
  variableStructures: {},
  documents: [],
  documentGroups: [],
  availableAnnotators: [],
  availableReviewers: [],
  annotatorTeams: [],
  companyTicker: [],
  documentsYear: [],
  variableOverview: {
    currentPageNumber: 1,
    currentPageSize: 0,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
    filter: {
      q: '',
      refId: '',
      ticker: '*',
      yearRange: [0, 0],
      field1: null,
      field2: null,
    },
    appliedMapping: [],
    list: [],
    stats: {
      filled: 0,
      total: 0,
    },
  },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_LIST_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
      };
    case 'SELECT_USER':
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case 'GET_TEMPLATES_SUCCESS':
    case 'GET_TEMPLATE_LIST_SUCCESS':
      return {
        ...state,
        templates: action.payload,
      };
    case 'GET_TASK_LIST_SUCCESS': {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          pageData,
          list,
        },
      };
    }
    case 'GET_TASK_SUMMARY_SUCCESS':
      return {
        ...state,
        tasks: {
          ...state.tasks,
          summary: action.payload,
        },
      };
    case 'CHANGE_TASK_FILTER':
      return {
        ...state,
        tasks: {
          ...state.tasks,
          filter: { ...state.tasks.filter, ...action.payload },
          pageData: { ...state.tasks.pageData, currentPageNumber: 1 }, // reset page
        },
      };
    case 'CHANGE_PAGE_DATA_ON_TASK':
      return {
        ...state,
        tasks: {
          ...state.tasks,
          pageData: { ...state.tasks.pageData, ...action.payload },
        },
      };
    case 'SELECT_TASK':
      return {
        ...state,
        selectedTasks: action.payload,
      };
    case 'GET_DOCUMENT_LIST_SUCCESS':
      return {
        ...state,
        documents: action.payload.list,
      };
    case 'GET_AVAILABLE_ANNOTATORS_SUCCESS':
      return {
        ...state,
        availableAnnotators: action.payload.list,
      };
    case 'GET_AVAILABLE_REVIEWERS_SUCCESS':
      return {
        ...state,
        availableReviewers: action.payload.list,
      };
    case 'GET_CHAPTER_LIST_SUCCESS':
      return {
        ...state,
        chapters: action.payload.children,
      };
    case 'GET_DOCUMENT_GROUP_LIST_SUCCESS':
      return {
        ...state,
        documentGroups: action.payload,
      };
    case 'GET_ANNOTATOR_TEAM_LIST_SUCCESS':
      return {
        ...state,
        annotatorTeams: action.payload,
      };
    case 'GET_DATATYPE_LIST_SUCCESS':
      return {
        ...state,
        dataTypes: action.payload,
      };
    case 'SET_VARIABLE_LIST':
      return {
        ...state,
        variables: action.payload,
      };
    case 'SET_CHAPTER_VARIABLES':
      return {
        ...state,
        chapterVariables: action.payload,
      };
    case 'RESET_CHAPTER_VARIABLES':
      return {
        ...state,
        chapterVariables: initialState.chapterVariables,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    case 'SELECT_USER_DETAIL':
    case 'GET_USER_DETAIL_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          selected: {
            ...state.users.selected,
            ...action.payload,
          },
        },
      };
    case 'GET_USER_TASKS_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          selected: {
            ...state.users.selected,
            tasks: action.payload,
          },
        },
      };
    case 'GET_COMPANY_TICKER_SUCCESS':
      return {
        ...state,
        companyTicker: action.payload,
      };
    case 'GET_DOCUMENTS_YEAR_SUCCESS':
      return {
        ...state,
        documentsYear: range(
          parseInt(action.payload[0]),
          parseInt(action.payload[1]) + 1
        ),
      };
    case 'SET_ANNOTATION_OVERVIEW_FILTER': {
      return {
        ...state,
        variableOverview: {
          ...state.variableOverview,
          filter: {
            ...state.variableOverview.filter,
            ...action.payload,
          },
        },
      };
    }
    case 'SET_APPLIED_MAPPING_FILTER': {
      return {
        ...state,
        variableOverview: {
          ...state.variableOverview,
          appliedMapping: action.payload,
        },
      };
    }
    case 'GET_ANNOTATION_OVERVIEW_SUCCESS': {
      const { list, pageData } = action.payload;
      return {
        ...state,
        variableOverview: {
          ...state.variableOverview,
          ...pageData,
          list,
        },
      };
    }
    case 'GET_VARIABLE_STRUCTURE_SUCCESS':
      return {
        ...state,
        variableStructures: action.payload,
      };
    case 'GET_ANNOTATION_OVERVIEW_STATS_SUCCESS': {
      return {
        ...state,
        variableOverview: {
          ...state.variableOverview,
          stats: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default adminReducer;
