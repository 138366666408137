import { goBack } from 'connected-react-router';
import { cloneDeep, get, uniq } from 'lodash';
import { apiRequest } from '../utils/apiRequest';
import { setBoundingBoxType } from './documentPreview';

export const getTaskData = taskId => async (dispatch, getState) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_TASK_DATA",
    "get",
    `/tasks/detail/${taskId}`
  );
  if (actionDispatched.type === "GET_TASK_DATA_FAILURE") {
    dispatch(goBack());
  } else {
    const { payload } = actionDispatched;
    if (["CHAPTER", "SUBCHAPTER"].includes(payload.dtype)) {
      dispatch(setBoundingBoxType(["BLOCK"]));
      if (payload.dtype === "SUBCHAPTER") {
        dispatch(getChapterList(payload.section.id));
      }
    } else if (payload.dtype === "VARIABLE") {
      dispatch(getTaskVariableDetail(payload.section));
      dispatch(setBoundingBoxType(["WORD", "FIELD"]));
    }
  }
};

export const updateTaskStatus = (taskId, status) => async (
  dispatch,
  getState
) => {
  const { type } = await apiRequest(
    dispatch,
    "UPDATE_TASK_STATUS",
    "put",
    `/tasks/update`,
    {
      id: taskId,
      status,
      action: "changeStatus"
    }
  );
  if (type === "UPDATE_TASK_STATUS_SUCCESS") {
    dispatch(setTaskStatus(status));
  }
};

export const setTaskStatus = status => ({
  type: "SET_TASK_STATUS",
  payload: status
});

export const getSections = (sectionId, mode = "annotate") => async (
  dispatch,
  getState
) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_SECTIONS",
    "get",
    `/sections/expand/${sectionId}`
  );
  if (actionDispatched.type === "GET_SECTIONS_SUCCESS") {
    const { payload } = actionDispatched;
    if (payload.children.length) {
      dispatch(setSelectedSection(payload.children[0].sectionId));
    }
    if (mode === "annotate") {
      processSectionAnnotate(payload, mode, dispatch, getState);
    } else if (mode === "review") {
      processSectionReview(payload, mode, dispatch, getState);
    }
  }
};

const processSectionAnnotate = (sections, mode, dispatch, getState) => {
  const { type: taskType, sectionId } = getState().annotation;
  const result = sections.children.reduce(
    (acc, item) => {
      acc.wrapper[item.sectionId] = [];
      acc.chapter[item.sectionId] = sectionId;
      acc.sections[item.sectionId] = {
        ...item,
        annotation: { id: null, status: 1 }
      };
      return acc;
    },
    { wrapper: {}, sections: {}, chapter: {} }
  );
  dispatch(setupSections(result.sections));
  dispatch(setupSelectedBoundingBox({ ...result.wrapper }));
  dispatch(setupSelectedPage({ ...result.wrapper }));
  dispatch(setupSelectedChapter(result.chapter));
  if (taskType === "CHAPTER") dispatch(getAnnotationChapter(mode));
  else if (taskType === "SUBCHAPTER")
    dispatch(getAnnotationSubchapter(sectionId, mode));
};

const processVariableAnnotate = (variables, dispatch) => {
  const result = variables.reduce((acc, variable) => {
    acc.wrapper[variable.id] = [];
    acc.formula[variable.id] = {};

    acc.sections[variable.id] = {
      ...variable,
      annotation: { id: null, status: 1 }
    };
    return acc
  }, { wrapper: {}, sections: {}, formula: {} });
  dispatch(setupSections(result.sections));
  dispatch(setupSelectedBoundingBox({ ...result.wrapper }));
  dispatch(setupSelectedPage({ ...result.wrapper }));
  dispatch(setupAnnotatedField({ ...result.wrapper }));
  dispatch(setupFormulatedField({ ...result.formula }));
}

const processSectionReview = (sections, mode, dispatch, getState) => {
  const { type: taskType, sectionId, annotators } = getState().annotation;
  const result = sections.children.reduce(
    (acc, item) => {
      acc.wrapper[item.sectionId] = Object.keys(annotators).reduce(
        (obj, annotatorId) => {
          obj[annotatorId] = [];
          return obj;
        },
        {}
      );
      acc.chapter[item.sectionId] = sectionId;
      acc.sections[item.sectionId] = {
        ...item,
        annotation: { id: null, status: 1 }
      };
      return acc;
    },
    { wrapper: {}, sections: {}, chapter: {} }
  );
  dispatch(setupSections(result.sections));
  dispatch(setupSelectedBoundingBox({ ...result.wrapper }));
  dispatch(setupSelectedPage({ ...result.wrapper }));
  dispatch(setupSelectedChapter(result.chapter));
  if (taskType === "CHAPTER") dispatch(getAnnotationChapter(mode));
  else if (taskType === "SUBCHAPTER")
    dispatch(getAnnotationSubchapter(sectionId, mode));
};

export const setSelectedSection = sectionId => ({
  type: "SET_SELECTED_SECTION",
  payload: sectionId
});

export const setupSelectedBoundingBox = wrapper => ({
  type: "SETUP_SELECTED_BOUNDING_BOX",
  payload: wrapper
});

export const setupSelectedPage = wrapper => ({
  type: "SETUP_SELECTED_PAGE",
  payload: wrapper
});

export const setupSelectedChapter = wrapper => ({
  type: "SETUP_SELECTED_CHAPTER",
  payload: wrapper
});

export const setupAnnotatedField = wrapper => ({
  type: "SETUP_ANNOTATED_VARIABLE_FIELD",
  payload: wrapper
});

export const setupSections = sections => ({
  type: "SETUP_SECTIONS",
  payload: sections
});

export const setSelectedBoundingBox = (sectionId, nodeIds) => (
  dispatch,
  getState
) => {
  const { selectedBoundingBox } = getState().annotation;
  const copy = Object.assign({}, selectedBoundingBox);
  copy[sectionId] = nodeIds;
  dispatch({
    type: "SET_SELECTED_BOUNDING_BOX",
    payload: copy
  });
};

export const addSelectedBoundingBox = (nodeId, pageNumber) => ({
  type: "ADD_SELECTED_BOUNDING_BOX",
  payload: { id: nodeId, pageNumber }
});

export const removeSelectedBoundingBox = nodeId => ({
  type: "REMOVE_SELECTED_BOUNDING_BOX",
  payload: nodeId
});

export const setSelectedPage = (sectionId, nodeIds) => (dispatch, getState) => {
  const { selectedPage } = getState().annotation;
  const copy = Object.assign({}, selectedPage);
  copy[sectionId] = nodeIds;
  dispatch({
    type: "SET_SELECTED_PAGE",
    payload: copy
  });
};

export const setSelectedBoundingBoxReview = (
  sectionId,
  annotatorId,
  nodeIds
) => (dispatch, getState) => {
  const { selectedBoundingBox } = getState().annotation;
  const copy = Object.assign({}, selectedBoundingBox);
  copy[sectionId] = {
    ...copy[sectionId],
    [annotatorId]: nodeIds
  };
  dispatch({
    type: "SET_SELECTED_BOUNDING_BOX_REVIEW",
    payload: copy
  });
};

export const setSelectedPageReview = (sectionId, annotatorId, nodeIds) => (
  dispatch,
  getState
) => {
  const { selectedPage } = getState().annotation;
  const copy = Object.assign({}, selectedPage);
  copy[sectionId] = {
    ...copy[sectionId],
    [annotatorId]: nodeIds
  };
  dispatch({
    type: "SET_SELECTED_PAGE_REVIEW",
    payload: copy
  });
};

const setSectionAnnotationData = (sectionId, data) => ({
  type: "SET_SECTION_ANNOTATION_DATA",
  payload: { sectionId, data }
});

const processAnnotationData = (results, mode, dispatch, getState) => {
  const { annotator } = getState().annotation;
  results.sections.forEach((section, index) => {
    if (mode === "annotate") {
      const current = section.annotations[0];
      if (current) {
        const boundaries = current.data.boundaries.reduce(
          (acc, curr) => {
            if (isPageBoundary(curr.id)) acc.page.push(curr);
            else acc.bounding.push(curr);
            return acc;
          },
          { bounding: [], page: [] }
        );
        dispatch(
          setSectionAnnotationData(section.id, {
            id: current._id,
            status: current.status
          })
        );
        dispatch(setSelectedBoundingBox(section.id, boundaries.bounding));
        dispatch(setSelectedPage(section.id, boundaries.page));
        dispatch(changeSelectedChapter(section.id, current.data.sectionId));
      }
    } else if (mode === "review") {
      section.annotations.forEach(annotation => {
        if (annotation.annotator.id === annotator.id) {
          dispatch(
            setSectionAnnotationData(section.id, {
              id: annotation._id,
              status: annotation.status
            })
          );
          if (annotation.data.sectionId) {
            dispatch(changeSelectedChapter(section.id, annotation.data.sectionId));
          }
        }
        const boundaries = annotation.data.boundaries.reduce(
          (obj, node) => {
            if (isPageBoundary(node.id)) obj.page.push(node);
            else obj.bounding.push(node);
            return obj;
          },
          { bounding: [], page: [] }
        );
        dispatch(
          setSelectedBoundingBoxReview(
            section.id,
            annotation.annotator.id,
            boundaries.bounding
          )
        );
        dispatch(
          setSelectedPageReview(
            section.id,
            annotation.annotator.id,
            boundaries.page
          )
        );
      });
    }
  });
};

export const getAnnotationChapter = mode => async (dispatch, getState) => {
  const { documentId, taskId } = getState().annotation;
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_ANNOTATION_CHAPTER",
    "get",
    `/annotation/document/${documentId}/annotations`,
    { taskId }
  );
  if (actionDispatched.type === "GET_ANNOTATION_CHAPTER_SUCCESS") {
    const { payload } = actionDispatched;
    processAnnotationData(payload, mode, dispatch, getState);
  }
};

export const getAnnotationSubchapter = (sectionId, mode) => async (
  dispatch,
  getState
) => {
  const { documentId, taskId, annotator } = getState().annotation;
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_ANNOTATION_SUBCHAPTER",
    "get",
    `/annotation/document/${documentId}/annotations`,
    {
      taskId,
      refId: sectionId
    }
  );
  await apiRequest(
    dispatch,
    "GET_TAKEN_BOUNDARIES",
    "post",
    `/annotation/checking`,
    {
      documentId,
      annotatorId: annotator.id
    }
  )
  if (actionDispatched.type === "GET_ANNOTATION_SUBCHAPTER_SUCCESS") {
    const { payload } = actionDispatched;
    processAnnotationData(payload, mode, dispatch, getState);
  }
};

export const updateAnnotation = (selectedSection) => async (dispatch, getState) => {
  const {
    documentId,
    type: taskType,
    sections,
    selectedBoundingBox,
    selectedPage,
    selectedChapter
  } = getState().annotation;
  const data = {
    data: {
      boundaries: [
        ...selectedPage[selectedSection],
        ...selectedBoundingBox[selectedSection]
      ]
    }
  };
  if (taskType === "SUBCHAPTER") {
    data.data.sectionId = selectedChapter[selectedSection];
  }
  const { type } = await apiRequest(
    dispatch,
    "UPDATE_ANNOTATION",
    "patch",
    `/annotation/document/${documentId}/annotation/${sections[selectedSection].annotation.id}`,
    data
  );
  if (type === "UPDATE_ANNOTATION_SUCCESS") {
    dispatch(
      setSectionAnnotationData(selectedSection, {
        id: sections[selectedSection].annotation.id,
        status: 2
      })
    );
    const { taskId, taskStatus } = getState().annotation;
    if (taskStatus === "rejected") {
      const sectionKeys = Object.keys(sections);
      const rejected = sectionKeys.reduce((acc, sectionId) => {
        if (sections[sectionId].annotation.status === 4 && sectionId !== selectedSection) acc = acc.concat(sectionId);
        return acc;
      }, []);
      if (rejected.length === 0) {
        dispatch(updateTaskStatus(taskId, "under review"));
      }
    } else if (['todo', 'ongoing'].includes(taskStatus)) {
      const sectionKeys = Object.keys(sections);
      const annotated = sectionKeys.reduce((acc, sectionId) => {
        if (sections[sectionId].annotation.status > 1) acc = acc.concat(sectionId);
        return acc;
      }, [selectedSection]);
      const uniqAnnotated = uniq(annotated);
      if (uniqAnnotated.length === sectionKeys.length) {
        dispatch(updateTaskStatus(taskId, "under review"));
      } else if (taskStatus === 'todo') {
        dispatch(updateTaskStatus(taskId, "ongoing"));
      }
    }
  }
};

export const getTaskNote = taskId => async dispatch => {
  await apiRequest(dispatch, "GET_TASK_NOTE", "get", `/annotation/comments`, {
    taskId: taskId
  });
};

export const addTaskNote = (taskId, text) => async dispatch => {
  const data = {
    taskId,
    text
  };
  const { type } = await apiRequest(
    dispatch,
    "ADD_TASK_NOTE",
    "post",
    `/annotation/comment/`,
    data
  );
  if (type === "ADD_TASK_NOTE_SUCCESS") {
    dispatch(getTaskNote(taskId));
    return true;
  }
};

export const updateTaskNote = (commentId, text) => async dispatch => {
  const data = {
    text
  };
  const { type } = await apiRequest(
    dispatch,
    "UPDATE_TASK_NOTE",
    "patch",
    `/annotation/comment/${commentId}`,
    data
  );
  return type === "UPDATE_TASK_NOTE_SUCCESS";
};

export const getTaskAnnotatorList = taskName => async (dispatch, getState) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_TASK_ANNOTATOR_LIST",
    "get",
    "/tasks/similar",
    { name: taskName }
  );
  if (actionDispatched.type === "GET_TASK_ANNOTATOR_LIST_SUCCESS") {
    const { sectionId } = getState().annotation;
    dispatch(getSections(sectionId, "review"));
  }
};

export const setDisplayedAnnotators = annotatorId => ({
  type: "SET_DISPLAYED_ANNOTATORS",
  payload: annotatorId
});

export const acceptAnnotation = (sectionId, annotationId) => async (dispatch, getState) => {
  const {
    taskId,
    taskStatus,
  } = getState().annotation;
  const { type } = await apiRequest(
    dispatch,
    "ACCEPT_ANNOTATION",
    "patch",
    `/annotation/annotation/review`,
    {
      id: annotationId,
      status: 3
    }
  );
  if (type === "ACCEPT_ANNOTATION_SUCCESS") {
    dispatch(
      setSectionAnnotationData(sectionId, {
        id: annotationId,
        status: 3
      })
    );
    if (taskStatus === "under review") {
      const { sections } = getState().annotation;
      const sectionKeys = Object.keys(sections);
      const status = sectionKeys.reduce((acc, sectionId) => {
        if (sections[sectionId].annotation.status === 3) {
          acc = { ...acc, accepted: acc.accepted.concat(sectionId) }
        } else if (sections[sectionId].annotation.status === 4) {
          acc = { ...acc, rejected: acc.rejected.concat(sectionId) }
        }
        return acc;
      }, { accepted: [sectionId], rejected: [] });
      const totalAccepted = uniq(status.accepted).length;
      const totalRejected = status.rejected.length;
      if (totalAccepted === sectionKeys.length) {
        dispatch(updateTaskStatus(taskId, "accepted"));
      } else if (totalAccepted + totalRejected === sectionKeys.length) {
        dispatch(updateTaskStatus(taskId, "rejected"));
      }
    }
  }
};

export const rejectAnnotation = (sectionId, annotationId) => async (dispatch, getState) => {
  const {
    taskId,
    taskStatus,
  } = getState().annotation;
  const { type } = await apiRequest(
    dispatch,
    "REJECT_ANNOTATION",
    "patch",
    `/annotation/annotation/review`,
    {
      id: annotationId,
      status: 4
    }
  );
  if (type === "REJECT_ANNOTATION_SUCCESS") {
    dispatch(
      setSectionAnnotationData(sectionId, {
        id: annotationId,
        status: 4
      })
    );
    if (taskStatus === "under review") {
      const { sections } = getState().annotation;
      const sectionKeys = Object.keys(sections);
      const acceptedAndRejected = sectionKeys.reduce((acc, sectionId) => {
        if (sections[sectionId].annotation.status > 2) acc = acc.concat(sectionId);
        return acc;
      }, [sectionId]);
      const uniqAcceptedAndRejected = uniq(acceptedAndRejected);
      if (uniqAcceptedAndRejected.length === sectionKeys.length) {
        dispatch(updateTaskStatus(taskId, "rejected"));
      }
    }
  }
};

export const undoAcceptAnnotation = (sectionId, annotationId) => async (dispatch, getState) => {
  const {
    taskId,
    taskStatus,
  } = getState().annotation;
  const { type } = await apiRequest(
    dispatch,
    "UNDO_ACCEPT_ANNOTATION",
    "patch",
    `/annotation/annotation/review`,
    {
      id: annotationId,
      status: 2
    }
  );
  if (type === "UNDO_ACCEPT_ANNOTATION_SUCCESS") {
    dispatch(
      setSectionAnnotationData(sectionId, {
        id: annotationId,
        status: 2
      })
    );
    if (taskStatus === "accepted" || taskStatus === "rejected") {
      dispatch(updateTaskStatus(taskId, "under review"));
    }
  }
};

export const resetTaskData = () => ({ type: "RESET_TASK_DATA" });

export const getVariableDetail = (variableId, dtype, onEmptyCallback = () => {}) => async (dispatch, getState) => {
  const actionDispatched = await apiRequest(
    dispatch,
    "GET_VARIABLE_DETAIL",
    "GET",
    `/datatypes/${dtype}`
  );
  const result = await dispatch(getAnnotationVariable(variableId));
  if (actionDispatched.type === "GET_VARIABLE_DETAIL_SUCCESS" && result.type === "GET_ANNOTATION_VARIABLE_SUCCESS") {
    const { sections, selectedSection } = getState().annotation;
    const currentVariable = sections[selectedSection];
    const { payload } = actionDispatched;
    const structure = payload[0];
    // if currently selected variable is different with variable id being used
    // to get annotation data then exit this function
    if (currentVariable.id !== variableId) return;
    processVariableStructure(structure);
    dispatch(setVariableStructure(variableId, structure));
    const cloneStructure = cloneDeep(structure);
    // the result of endpoint get annotation
    const { payload: annotation } = result;
    const { _id: annotationId, data: { dataVariables: annotations }, status } = annotation;
    dispatch(setVariableAnnotationId(variableId, annotationId));
    dispatch(setVariableAnnotationStatus(variableId, status));
    // if annotation data exist
    if (annotation.data.dataVariables.length > 0) {
      // if datatype from variable detail is different with datatype from annotation data
      if (annotations[0].dataTypeId !== currentVariable.dtype) {
        // use structure from variable detail
        dispatch(setVariableAnnotationItems(variableId, [cloneStructure]));
        // update annotation
        dispatch(updateAnnotationVariable([cloneStructure], annotationId, variableId))
      } else {
        // if datatypes are same, and if annotation data is more than one but
        // variable detail isArray property is false means that changing variable from array to not an array
        const annotatedFields = [];
        const formulatedField = {};
        if (annotations.length > 1 && !currentVariable.isArray) {
          // parse annotated field from the first annotation data
          const variableName = `${compact(currentVariable.name)}[0]`;
          parseAnnotatedVariableField(annotations[0], [variableName], annotatedFields, null, formulatedField);
          dispatch(setAnnotatedVariableField(variableId, annotatedFields));
          dispatch(setFormulatedVariableField(variableId, formulatedField));
          // set annotation items to be the first item in annotation data
          dispatch(setVariableAnnotationItems(variableId, [annotations[0]]));
          // update annotation
          dispatch(updateAnnotationVariable([annotations[0]], annotationId, variableId))
        } else {
          // parse annotated field from all annotations data
          annotations.forEach((annotation, index) => {
            const variableName = `${compact(currentVariable.name)}[${index}]`;
            parseAnnotatedVariableField(annotation, [variableName], annotatedFields, null, formulatedField);
          });
          dispatch(setAnnotatedVariableField(variableId, annotatedFields));
          dispatch(setFormulatedVariableField(variableId, formulatedField));
          // set annotation items from annotation data
          dispatch(setVariableAnnotationItems(variableId, annotations));
        }
      }
    } else {
      // if annotation data is empty then set the variable item from variable detail
      dispatch(setVariableAnnotationItems(variableId, [cloneStructure]));
      onEmptyCallback(currentVariable.name);
    }
  }
}

export const addVariableAnnotationItem = () => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const currentVariable = sections[selectedSection];
  const cloneStructure = cloneDeep(currentVariable.structure);
  const updatedAnnotationItems = currentVariable.annotationItems.concat(cloneStructure);
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const removeVariableAnnotationItem = index => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const currentVariable = sections[selectedSection];
  const updatedAnnotationItems = currentVariable.annotationItems.filter((_, itemIndex) => itemIndex !== index);
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const addVariableItem = (annotationItemIndex, parents) => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const currentVariable = sections[selectedSection];
  const { annotationItems } = currentVariable;
  const copyStructure = cloneDeep(annotationItems[annotationItemIndex]);
  _addVariableItem(copyStructure, parents);
  const updatedAnnotationItems = annotationItems.reduce((acc, currItem, index) => {
    if (index === annotationItemIndex) acc = acc.concat(copyStructure)
    else acc = acc.concat(currItem)
    return acc
  }, [])
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const removeVariableItem = (annotationItemIndex, parents, index) => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const currentVariable = sections[selectedSection];
  const { annotationItems } = currentVariable;
  const copyStructure = cloneDeep(annotationItems[annotationItemIndex]);
  _removeVariableItem(copyStructure, parents, index);
  const updatedAnnotationItems = annotationItems.reduce((acc, currItem, index) => {
    if (index === annotationItemIndex) acc = acc.concat(copyStructure)
    else acc = acc.concat(currItem)
    return acc
  }, [])
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const saveAnnotationVariable = (formData, dirty, ignoreStatus = false) => async (dispatch, getState) => {
  const { sections, selectedSection, annotatedField, formulatedField } = getState().annotation;
  if (selectedSection) {
    let data = dirty.reduce((acc, field) => {
      acc[field] = {};
      return acc;
    }, {});
    // if form input changes
    if (dirty.length > 0) {
      // combine form data with structure data
      Object.keys(sections).forEach(sectionId => {
        const section = sections[sectionId];
        const fieldName = compact(section.name);
        // only process dirty field
        if (dirty.includes(fieldName)) {
          const annotatedData = annotatedField[sectionId].reduce((acc, curr) => {
            const keyMapArr = curr.keyMap.split(".");
            // extract index
            const index = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
            acc[index] = { ...acc[index], [keyMapArr.slice(1).join(".")]: { coordinates: curr.coordinates, pageNumber: curr.pageNumber } }
            return acc;
          }, []);
          const formulatedData = Object.keys(formulatedField[sectionId]).reduce((acc, keyMap) => {
            const keyMapArr = keyMap.split(".");
            // extract index
            const index = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
            acc[index] = { ...acc[index], [keyMapArr.slice(1).join(".")]: formulatedField[sectionId][keyMap] }
            return acc;
          }, []);
          const combined = [];
          section.annotationItems.forEach((annotation, index) => {
            const root = cloneDeep(annotation);
            combine(root, [], formData[fieldName][index], annotatedData[index], formulatedData[index], ignoreStatus);
            combined.push(root);
          });
          data[fieldName] = {
            variableId: section.id,
            annotationId: section.annotation.id,
            annotationData: combined,
            dataType: section.dtype,
          }
        }
      })
      const responses = [];
      let totalChangePerson = 0;
      for (let fieldName of Object.keys(data)) {
        const current = data[fieldName];
        const annotationId = current.annotationId;
        responses.push(await dispatch(updateAnnotationVariable(current.annotationData, annotationId, current.variableId)));
        if (current.dataType === 'person-derivative') totalChangePerson += 1;
      };
      const { documentId, taskId, taskStatus } = getState().annotation;
      // if there is variable with type person changed, then update suggestion
      if (totalChangePerson > 0) dispatch(getAnnotationSuggestions(taskId));
      // if current task status is rejected
      if (taskStatus === "rejected") {
        // then check annotation status
        const { type: actionType, payload } = await apiRequest(
          dispatch,
          'CHECK_REVISED_ANNOTATION',
          'post',
          `/annotation/review/checking`,
          {
            documentId,
            taskId
          }
        );
        if (actionType === 'CHECK_REVISED_ANNOTATION_SUCCESS') {
          const { status } = payload;
          // after getting rejected if checking result is incomplete task
          // then change the task status into `under review` so the reviewer
          // notice if the task is revised
          if (status === 'incomplete tasks') {
            dispatch(updateTaskStatus(taskId, "under review"));
          }
        }
      }
      return responses;
    }
  }
}

export const submitAnnotationVariable = () => async (dispatch, getState) => {
  const { taskId, taskStatus } = getState().annotation;
  if (["todo", "ongoing"].includes(taskStatus)) {
    await dispatch(updateTaskStatus(taskId, "under review"));
  }
}

export const updateAnnotationVariable = (formData, annotationId, variableId, action = "annotate") => async (dispatch, getState) => {
  const {
    taskId,
    documentId,
    taskStatus
  } = getState().annotation;
  const data = {
    refId: variableId,
    data: {
      dataVariables: formData
    }
  };
  const { payload, type } = await apiRequest(
    dispatch,
    "UPDATE_ANNOTATION_VARIABLE",
    "patch",
    `/annotation/document/${documentId}/annotation/${annotationId}`,
    data
  );
  if (type === "UPDATE_ANNOTATION_VARIABLE_SUCCESS") {
    if (action === "annotate" && ["todo", "under review"].includes(taskStatus)) {
      await dispatch(updateTaskStatus(taskId, "ongoing"));
    }
    dispatch(setVariableAnnotationStatus(variableId, payload.status))
    return true;
  }
  return false;
};

export const updateReviewAnnotationVariable = (formData, annotationId, variableId) => async (dispatch, getState) => {
  const {
    documentId,
  } = getState().annotation;
  const data = {
    refId: variableId,
    data: {
      dataVariables: formData
    }
  };
  const { payload, type } = await apiRequest(
    dispatch,
    "UPDATE_REVIEW_ANNOTATION_VARIABLE",
    "patch",
    `/annotation/document/${documentId}/annotation/${annotationId}`,
    data
  );
  if (type === "UPDATE_REVIEW_ANNOTATION_VARIABLE_SUCCESS") {
    dispatch(setVariableAnnotationStatus(variableId, payload.status))
    return true;
  }
  return false;
};

const getAnnotationVariable = (variableId) => async (dispatch, getState) => {
  const { documentId, taskId } = getState().annotation;
  const data = {
    taskId,
    refId: variableId
  }
  const result = await apiRequest(
    dispatch,
    "GET_ANNOTATION_VARIABLE",
    "get",
    `/annotation/document/${documentId}/annotations`,
    data
  );
  if (result.type === "GET_ANNOTATION_VARIABLE_SUCCESS") {
    return {
      type: result.type,
      payload: result.payload
    };
  }
  return {
    type: "GET_ANNOTATION_VARIABLE_FAILED"
  };
}

export const acceptAnnotationVariable = (annotationItemIndex, keyMap) => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  changeStatusAnnotationVariable(selectedVariable, selectedSection, annotationItemIndex, keyMap, 3, dispatch);
}

export const rejectAnnotationVariable = (annotationItemIndex, keyMap) => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  changeStatusAnnotationVariable(selectedVariable, selectedSection, annotationItemIndex, keyMap, 4, dispatch);
}

export const undoAcceptAnnotationVariable = (annotationItemIndex, keyMap) => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  changeStatusAnnotationVariable(selectedVariable, selectedSection, annotationItemIndex, keyMap, 2, dispatch);
}

export const acceptAnnotationVariableAll = () => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  const result = [];
  selectedVariable.annotationItems.forEach((structure, index) => {
    const copyStructure = cloneDeep(structure);
    _changeStatusAnnotationVariable(copyStructure, [], [], 3, true);
    result.push(copyStructure);
  });
  dispatch(setVariableAnnotationItems(selectedSection, result));
}

export const acceptAnnotationVariableItem = annotationItemIndex => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  const copyStructure = cloneDeep(selectedVariable.annotationItems[annotationItemIndex]);
  _changeStatusAnnotationVariable(copyStructure, [], [], 3, true);
  const updatedAnnotationItems = selectedVariable.annotationItems.reduce((acc, currItem, index) => {
    if (index === annotationItemIndex) acc = acc.concat(copyStructure)
    else acc = acc.concat(currItem)
    return acc
  }, [])
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const rejectAnnotationVariableAll = () => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  const result = [];
  selectedVariable.annotationItems.forEach((structure, index) => {
    const copyStructure = cloneDeep(structure);
    _changeStatusAnnotationVariable(copyStructure, [], [], 4, true);
    result.push(copyStructure);
  });
  dispatch(setVariableAnnotationItems(selectedSection, result));
}

export const rejectAnnotationVariableItem = annotationItemIndex => (dispatch, getState) => {
  const { sections, selectedSection } = getState().annotation;
  const selectedVariable = sections[selectedSection];
  const copyStructure = cloneDeep(selectedVariable.annotationItems[annotationItemIndex]);
  _changeStatusAnnotationVariable(copyStructure, [], [], 4, true);
  const updatedAnnotationItems = selectedVariable.annotationItems.reduce((acc, currItem, index) => {
    if (index === annotationItemIndex) acc = acc.concat(copyStructure)
    else acc = acc.concat(currItem)
    return acc
  }, [])
  dispatch(setVariableAnnotationItems(selectedSection, updatedAnnotationItems));
}

export const submitReviewAnnotationVariable = () => async (dispatch, getState) => {
  const { documentId, taskId } = getState().annotation;
  const { type: actionType, payload } = await apiRequest(
    dispatch,
    'CHECK_REVIEW_ANNOTATION',
    'post',
    `/annotation/review/checking`,
    {
      documentId,
      taskId
    }
  );
  if (actionType === 'CHECK_REVIEW_ANNOTATION_SUCCESS') {
    const { status } = payload;
    if (status !== 'incomplete tasks') {
      dispatch(updateTaskStatus(taskId, status));
    }
    return status;
  }
}

export const saveReviewAnnotationVariable = (dirty) => async (dispatch, getState) => {
  const { sections } = getState().annotation;
    // if something changes
    if (dirty.length > 0) {
      let responses = [];
      for (let fieldName of dirty) {
        const current = sections[fieldName];
        const annotationId = current.annotation.id;
        // update annotation
        const isSuccess = await dispatch(updateReviewAnnotationVariable(current.annotationItems, annotationId, fieldName));
        responses.push(isSuccess);
      }
      return responses;
  }
}

const changeStatusAnnotationVariable = (selectedVariable, variableId, annotationItemIndex, keyMap, status, dispatch) => {
  const copyStructure = cloneDeep(selectedVariable.annotationItems[annotationItemIndex]);
  const keyMapArr = keyMap.split(".");
  _changeStatusAnnotationVariable(copyStructure, [], keyMapArr.slice(1), status);
  const updatedAnnotationItems = selectedVariable.annotationItems.reduce((acc, currItem, index) => {
    if (index === annotationItemIndex) acc = acc.concat(copyStructure)
    else acc = acc.concat(currItem)
    return acc
  }, [])
  dispatch(setVariableAnnotationItems(variableId, updatedAnnotationItems));
}

const isDerivative = type => type.includes("derivative");

const processVariableStructure = structure => {
  if (structure.objAttr) {
    for (let field of structure.objAttr) {
      if (isDerivative(field.dtype)) {
        processVariableStructure(field.child);
        if (field.array) {
          const childTemplate = cloneDeep(field.child);
          field.children = [childTemplate];
        }
      } else {
        field.child.status = 1;
        field.child.rawData = "";
        field.child.coordinates = null;
        field.child.pageNumber = null;
        if (field.array) {
          const childTemplate = cloneDeep(field.child);
          field.children = [childTemplate];
        }
      } 
    }
  } else {
    structure.status = 1;
    structure.rawData = "";
    structure.coordinates = null;
    structure.pageNumber = null;
  }
}

const combine = (root, parents, formData, annotatedData, formulatedData, ignoreStatus) => {
  if (root.objAttr) {
    for (let field of root.objAttr) {
      if (field.array) {
        let index = 0;
        const newChildren = [];
        for (let currentChild of field.children) {
          const fieldsMap = parents.concat(`${compact(field.name)}[${index}]`);
          if (isDerivative(currentChild.dtype)) {
            combine(currentChild, fieldsMap, formData, annotatedData, formulatedData, ignoreStatus);
          } else {
            const keyMap = fieldsMap.join(".");
            const data = annotatedData && annotatedData[keyMap];
            let rawData = get(formData, keyMap);
            if (currentChild.child) {
              if (typeof rawData === "string" && !Array.isArray(currentChild.child.attr)) {
                rawData = rawData.replace(/(<([^>]+)>)/gi, "");
              }
              currentChild.child.pageNumber = data ? data.pageNumber : null;
              currentChild.child.coordinates = data ? data.coordinates : null;
              currentChild.child.rawData = rawData;
              if (formulatedData && formulatedData.hasOwnProperty(keyMap)) currentChild.child.formula = formulatedData[keyMap];
              if (!ignoreStatus && (!currentChild.child.status || [1, 4].includes(currentChild.child.status))) {
                currentChild.child.status = 2;
              }
            } else {
              if (typeof rawData === "string" && !Array.isArray(currentChild.attr)) {
                rawData = rawData.replace(/(<([^>]+)>)/gi, "");
              }
              rawData = typeof rawData === "string" ? rawData.replace(/(<([^>]+)>)/gi, "") : rawData;
              currentChild.pageNumber = data ? data.pageNumber : null;
              currentChild.coordinates = data ? data.coordinates : null;
              currentChild.rawData = rawData;
              if (formulatedData && formulatedData.hasOwnProperty(keyMap)) currentChild.formula = formulatedData[keyMap];
              if (!ignoreStatus && (!currentChild.status || [1, 4].includes(currentChild.status))) {
                currentChild.status = 2;
              }
            }
          }
          newChildren.push(currentChild);
          index++;
        }
        field.children = newChildren;
      } else {
        const fieldsMap = parents.concat(compact(field.name));
        if (isDerivative(field.dtype)) {
          combine(field.child, fieldsMap, formData, annotatedData, formulatedData, ignoreStatus);
        } else {
          const keyMap = fieldsMap.join(".");
          const data = annotatedData && annotatedData[keyMap];
          let rawData = get(formData, keyMap);
          rawData = typeof rawData === "string" && !Array.isArray(field.child.attr)
            ? rawData.replace(/(<([^>]+)>)/gi, "") : rawData;
          field.child.pageNumber = data ? data.pageNumber : null;
          field.child.coordinates = data ? data.coordinates : null;
          field.child.rawData = rawData;
          if (formulatedData && formulatedData.hasOwnProperty(keyMap)) field.child.formula = formulatedData[keyMap];
          if (!ignoreStatus && (!field.child.status || [1, 4].includes(field.child.status))) {
            field.child.status = 2;
          }
        }
      }
    }
  } else {
    if (formData) {
      let rawData = formData[compact(root.name)];
      const data = annotatedData && annotatedData[compact(root.name)];
      rawData = typeof rawData === "string" && !Array.isArray(root.attr) ? rawData.replace(/(<([^>]+)>)/gi, "") : rawData;
      root.pageNumber = data ? data.pageNumber : null;
      root.coordinates = data ? data.coordinates : null;
      root.rawData = rawData;
      if (formulatedData && formulatedData[compact(root.name)]) root.formula = formulatedData[compact(root.name)];
      if (!ignoreStatus && (!root.status || [1, 4].includes(root.status))) {
        root.status = 2;
      }
    }
  }
}

const compact = name => name.toLowerCase().replaceAll(" ", "-");

const _addVariableItem = (structure, parents) => {
  if (structure.objAttr) {
    const currentParent = parents[0];
    for (let field of structure.objAttr) {
      const fieldName = compact(field.name);
      if (parents.length === 1 && fieldName === currentParent && field.array) {
        const childTemplate = cloneDeep(field.child);
        field.children = [...field.children, childTemplate];
        return;
      }
      if (isDerivative(field.dtype) && fieldName === currentParent) {
        _addVariableItem(field.child, parents.slice(1));
      } 
    }
  }
}

const _removeVariableItem = (structure, parents, index) => {
  if (structure.objAttr) {
    const currentParent = parents[0];
    for (let field of structure.objAttr) {
      const fieldName = compact(field.name);
      if (parents.length === 1 && fieldName === currentParent && field.array) {
        field.children = field.children.filter((_, childIndex) => childIndex !== index);
        return;
      }
      if (isDerivative(field.dtype) && fieldName === currentParent) {
        _removeVariableItem(field.child, parents.slice(1), index);
      } 
    }
  }
}

const parseAnnotatedVariableField = (structure, parents, annotatedField, rawDataField, formulatedField) => {
  if (structure.objAttr) {
    for (let field of structure.objAttr) {
      if (field.array) {
        let index = 0;
        for (let currentChild of field.children) {
          const fieldName = `${compact(field.name)}[${index}]`;
          if (isDerivative(currentChild.dtype)) {
            parseAnnotatedVariableField(currentChild, parents.concat(fieldName), annotatedField, rawDataField, formulatedField);
          } else {
            const keyMap = parents.concat(compact(fieldName)).join(".");
            if (Array.isArray(currentChild.coordinates)) {
              const data = { keyMap, coordinates: currentChild.coordinates, pageNumber: currentChild.pageNumber };
              annotatedField.push(data);
            }
            if (Array.isArray(rawDataField) && currentChild.rawData) {
              rawDataField.push({ keyMap, rawData: currentChild.rawData, fieldType: currentChild.dataTypeId })
            }
            if (formulatedField && currentChild.formula) {
              formulatedField[keyMap] = currentChild.formula;
            }
          }
          index++;
        }
      } else {
        if (isDerivative(field.dtype)) {
          parseAnnotatedVariableField(field.child, parents.concat(compact(field.name)), annotatedField, rawDataField, formulatedField);
        } else {
          const keyMap = parents.concat(compact(field.name)).join(".");
          if (Array.isArray(field.child.coordinates)) {
            const data = { keyMap, coordinates: field.child.coordinates, pageNumber: field.child.pageNumber };
            annotatedField.push(data)
          }
          if (Array.isArray(rawDataField) && field.child.rawData) {
            rawDataField.push({ keyMap, rawData: field.child.rawData, fieldType: field.child.dataTypeId })
          }
          if (formulatedField && field.child.formula) {
            formulatedField[keyMap] = field.child.formula;
          }
        } 
      }
    }
  } else {
    const keyMap = parents.concat(compact(structure.name)).join(".");
    if (Array.isArray(structure.coordinates)) {
      const data = { keyMap, coordinates: structure.coordinates, pageNumber: structure.pageNumber };
      annotatedField.push(data);
    }
    if (Array.isArray(rawDataField) && structure.rawData) {
      rawDataField.push({ keyMap, rawData: structure.rawData, fieldType: structure.dataTypeId })
    }
    if (formulatedField && structure.formula) {
      formulatedField[keyMap] = structure.formula;
    }
  }
}

function checkFieldMap(parentsMap, keyMap) {
  if (keyMap.length <= parentsMap.length) {
    const slicedParents = parentsMap.slice(0, keyMap.length);
    const lastIndex = keyMap.length - 1;
    let match = true;
    for (let i = 0; i < keyMap.length && match; i++) {
      if (i === lastIndex && keyMap[i] !== slicedParents[i]) {
        const bracketIdx = slicedParents[i].indexOf("[");
        if (bracketIdx !== -1) {
          if (slicedParents[i].slice(0, bracketIdx) !== keyMap[i]) match = false;
        } else {
          match = false;
        }
      } else {
        if (keyMap[i] !== slicedParents[i]) match = false;
      }
    }
    return match;
  }
  return false;
}

const _changeStatusAnnotationVariable = (structure, parents, keyMap, status, changeAll = false) => {
  if (structure.objAttr) {
    for (let field of structure.objAttr) {
      if (field.array) {
        let index = 0;
        const updatedChild = [];
        for (let currentChild of field.children) {
          const fieldName = `${compact(field.name)}[${index}]`;
          if (isDerivative(currentChild.dtype)) {
            _changeStatusAnnotationVariable(currentChild, parents.concat(fieldName), keyMap, status, changeAll);
          } else {
            const parentsMap = parents.concat(compact(fieldName));
            if (changeAll || checkFieldMap(parentsMap, keyMap)) {
              if (currentChild.child) {
                currentChild.child.status = status;
              } else {
                currentChild.status = status;
              }
            }
          }
          updatedChild.push(currentChild);
          index++;
        }
        field.children = updatedChild;
      } else {
        if (isDerivative(field.dtype)) {
          _changeStatusAnnotationVariable(field.child, parents.concat(compact(field.name)), keyMap, status, changeAll);
        } else {
          const parentsMap = parents.concat(compact(field.name));
          if (changeAll || checkFieldMap(parentsMap, keyMap)) {
            if (field.child) {
              field.child.status = status;
            } else {
              field.status = status;
            }
          }
        } 
      }
    }
  } else {
    const parentsMap = parents.concat(compact(structure.name));
    if (changeAll || checkFieldMap(parentsMap, keyMap)) {
      structure.status = status;
    }
  }
}

export const setVariableStructure = (variableId, data) => ({
  type: "SET_VARIABLE_STRUCTURE",
  payload: { variableId, data }
})

export const setVariableAnnotationId = (variableId, id) => ({
  type: "SET_VARIABLE_ANNOTATION_ID",
  payload: { variableId, id }
})

export const setVariableAnnotationStatus = (variableId, status) => ({
  type: "SET_VARIABLE_ANNOTATION_STATUS",
  payload: { variableId, status }
})

export const setVariableAnnotationItems = (variableId, data) => ({
  type: "SET_VARIABLE_ANNOTATION_ITEMS",
  payload: { variableId, data }
})

export const setSelectedVariableField = data => ({
  type: "SET_SELECTED_VARIABLE_FIELD",
  payload: data
})

export const resetSelectedVariableField = data => ({
  type: "RESET_SELECTED_VARIABLE_FIELD"
})

export const setAnnotatedVariableField = (variableId, data) => ({
  type: "SET_ANNOTATED_VARIABLE_FIELD",
  payload: { variableId, data }
});

export const addAnnotatedVariableField = (variableId, data) => ({
  type: "ADD_ANNOTATED_VARIABLE_FIELD",
  payload: { variableId, data }
});

function isPageBoundary(sectionId) {
  return sectionId.split("_")[0] === "page";
}

export const getAnnotationTable = (id) => async (dispatch) => {
	// const { documentId } = getState().annotation;
	await apiRequest(
		dispatch,
		'GET_ANNOTATION_TABLE',
		'get',
		`/annotation/document/${id}/annotations`,
		{ type: 'table' }
	);
};

export const addAnnotationTable = (formBody, id) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'ADD_ANNOTATION_TABLE',
		'post',
		`/annotation/document/${id}/annotation?type=table`,
		formBody
	);
	if (actionDispatched.type === 'ADD_ANNOTATION_TABLE_SUCCESS') {
		// const { payload } = actionDispatched;
		// saveAnnotationTable(payload);
		// console.log(payload);
		dispatch(getAnnotationTable(id));
	}
};

export const updateAnnotationTable = (formBody, documentId, annotationId) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'UPDATE_ANNOTATION_TABLE',
		'patch',
		`/annotation/document/${documentId}/annotation/${annotationId}`,
		formBody
	);
	if (actionDispatched.type === 'UPDATE_ANNOTATION_TABLE_SUCCESS') {
        dispatch(getAnnotationTable(documentId));
	}
};

export const saveAnnotationTable = (annotations) => ({
	type: 'GET_ANNOTATION_TABLE',
	payload: annotations
});

export const deleteAnnotationTable = (docName, id) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'DELETE_ANNOTATION_TABLE',
		'delete',
		`annotation/document/${docName}/annotation/${id}`
	);
	if (actionDispatched.type === 'DELETE_ANNOTATION_TABLE_SUCCESS') {
		dispatch(getAnnotationTable(docName));
	}
};

export const acceptAnnotationTable = (annotationId, id) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'ACCEPT_ANNOTATION_TABLE',
		'patch',
		`/annotation/annotation/${annotationId}/review`,
		{
			status: 3
		}
	);
	console.log(actionDispatched, annotationId, id);
	if (actionDispatched.type === 'ACCEPT_ANNOTATION_TABLE_SUCCESS') {
		dispatch(getAnnotationTable(id));
	}
};
export const rejectAnnotationTable = (annotationId, id) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'REJECT_ANNOTATION_TABLE',
		'patch',
		`/annotation/annotation/${annotationId}/review`,
		{
			status: 4
		}
	);
	console.log(actionDispatched, annotationId, id);
	if (actionDispatched.type === 'REJECT_ANNOTATION_TABLE_SUCCESS') {
		dispatch(getAnnotationTable(id));
	}
};
export const undoAnnotationTable = (annotationId, id) => async (dispatch) => {
	const actionDispatched = await apiRequest(
		dispatch,
		'REJECT_ANNOTATION_TABLE',
		'patch',
		`/annotation/annotation/${annotationId}/review`,
		{
			status: 2
		}
	);
	console.log(actionDispatched, annotationId, id);
	if (actionDispatched.type === 'REJECT_ANNOTATION_TABLE_SUCCESS') {
		dispatch(getAnnotationTable(id));
	}
};

export const getChapterList = chapterId => async dispatch => {
  const detailRequest = await apiRequest(
    dispatch,
    `GET_CHAPTER_DETAIL`,
    'get',
    `sections/detail/${chapterId}`
  );
  if (detailRequest.type === "GET_CHAPTER_DETAIL_SUCCESS") {
    const { payload } = detailRequest;
    await apiRequest(
      dispatch,
      `GET_CHAPTER_LIST_OPTION`,
      'get',
      `sections/expand/${payload.parent}`
    );
  }
}

export const getTaskVariableDetail = variableIds => async dispatch => {
  const actionDispatched = await apiRequest(
    dispatch,
    `GET_TASK_VARIABLE_DETAIL`,
    'post',
    `variables/list`,
    variableIds
  );
  if (actionDispatched.type === "GET_TASK_VARIABLE_DETAIL_SUCCESS") {
    const { payload } = actionDispatched;
    processVariableAnnotate(payload, dispatch);
  }
}

export const changeSelectedChapter = (sectionId, data) => ({
  type: "CHANGE_SELECTED_CHAPTER",
  payload: {
    sectionId,
    data
  }
})

export const setTakenBoundaries = boundaries => ({
  type: "SET_TAKEN_BOUNDARIES",
  payload: boundaries
});

export const setSectionListScrollPosition = scrollPos => ({
  type: "SET_SECTION_LIST_SCROLL_POSITION",
  payload: scrollPos
});

export const setVariableFormScrollPosition = scrollPos => ({
  type: "SET_VARIABLE_FORM_SCROLL_POSITION",
  payload: scrollPos
});

export const acceptAllVariables = taskId => async dispatch => {
   const { type } = await apiRequest(
    dispatch,
    `ACCEPT_ALL_VARIABLES`,
    'post',
    `annotations/accept_all`,
    { taskId },
    'application/json',
  );
  if (type === 'ACCEPT_ALL_VARIABLES_SUCCESS') {
    dispatch(setTaskStatus("accepted"));
    dispatch(updateLoadedVariables(3));
  }
}

export const rejectAllVariables = taskId => async dispatch => {
   const { type } = await apiRequest(
    dispatch,
    `REJECT_ALL_VARIABLES`,
    'post',
    `annotations/reject_all`,
    { taskId },
    'application/json',
  );
  if (type === 'REJECT_ALL_VARIABLES_SUCCESS') {
    dispatch(setTaskStatus("rejected"));
    dispatch(updateLoadedVariables(4));
  }
}

export const runParseAnnotatedVariableField = (variable, annotationItemIndex) => (dispatch, getState) => {
  const { annotatedField, formulatedField } = getState().annotation;
  const annotatedFields = [];
  const rawDataFields = [];
  const newFormulatedField = {};
  const rootName = `${compact(variable.name)}[${annotationItemIndex}]`;
  const currentAnnotationItem = variable.annotationItems[annotationItemIndex];
  // re-parse fields from specific annotation item
  parseAnnotatedVariableField(currentAnnotationItem, [rootName], annotatedFields, rawDataFields, newFormulatedField);
  // remove existing annotated field
  const filteredAnnotatedFields = annotatedField[variable.id].filter(field => {
    const keyMapArr = field.keyMap.split('.');
    return keyMapArr[0] !== rootName
  });
  const filteredFormulatedFields = Object.keys(formulatedField[variable.id]).reduce((acc, keyMap) => {
    const formula = formulatedField[variable.id][keyMap]
    const keyMapArr = keyMap.split('.');
    if (keyMapArr[0] !== rootName) {
      return { ...acc, [keyMap]: formula };
    }
    return acc;
  }, {});
  dispatch(setAnnotatedVariableField(variable.id, filteredAnnotatedFields.concat(annotatedFields)));
  dispatch(setFormulatedVariableField(variable.id, { ...filteredFormulatedFields, ...newFormulatedField }));
  dispatch(setAnnotatedFieldStaleStatus(variable.id, false));
  return rawDataFields;
}

export const refreshAnnotationVariable = (variableId) => async (dispatch, getState) => {
  const { payload, type } = await dispatch(getAnnotationVariable(variableId));
  if (type === "GET_ANNOTATION_VARIABLE_SUCCESS") {
    const { sections } = getState().annotation;
    const currentVariable = sections[variableId];
    const { data: { dataVariables: annotations } } = payload;
    // if annotation data exist
    if (annotations.length > 0) {
      const annotatedFields = [];
      // parse annotated field from all annotations data
      annotations.forEach((annotation, index) => {
        const variableName = `${compact(currentVariable.name)}[${index}]`;
        parseAnnotatedVariableField(annotation, [variableName], annotatedFields);
      });
      dispatch(setAnnotatedVariableField(variableId, annotatedFields));
      // set annotation items from annotation data
      dispatch(setVariableAnnotationItems(variableId, annotations));
    }
  }
}

const updateLoadedVariables = (status) => (dispatch, getState) => {
  const { sections } = getState().annotation;
  const loadedVariable = Object.keys(sections).filter(
    variableId => sections[variableId].hasOwnProperty("structure")
  );
  loadedVariable.forEach(variableId => {
    dispatch(setVariableAnnotationStatus(variableId, status));
    dispatch(refreshAnnotationVariable(variableId));
  });
}

export const getAnnotationSuggestions = (taskId) => async dispatch => {
   return await apiRequest(
    dispatch,
    `GET_ANNOTATION_SUGGESTIONS`,
    'get',
    `annotation/suggestions`,
    { taskId }
  );
}

export const applySuggestion = (suggestionName, variableId, index) => ({
  type: 'APPLY_ANNOTATION_SUGGESTION',
  payload: {
    suggestionName,
    variableId,
    index,
  },
});

export const setAnnotatedFieldStaleStatus = (variableId, status) => ({
  type: 'SET_ANNOTATED_FIELD_STALE_STATUS',
  payload: {
    variableId,
    status
  },
});

export const moveAnnotationItemPosition = (variableId, fromIndex, toIndex) => ({
  type: 'MOVE_ANNOTATION_ITEM_POSITION',
  payload: {
    variableId,
    fromIndex,
    toIndex,
  }
});

export const clearMovedAnnotationItem = () => ({
  type: 'CLEAR_MOVED_ANNOTATION_ITEM'
});

export const reorderAnnotationData = (variableId, from, to, formData) => (dispatch, getState) => {
  const { annotatedField, formulatedField } = getState().annotation;
  // Shifting order on form data
  const rawDataFields = cloneDeep(formData);
  const moved = cloneDeep(formData[from]);
  rawDataFields.splice(from, 1);
  rawDataFields.splice(to, 0, moved);
  // Shifting order on annotated field data
  const annotatedFields = annotatedField[variableId];
  const shift = from < to ? 'up' : 'down';
  const shiftedFields = annotatedFields.map(field => {
    const keyMapArr = field.keyMap.split('.');
    const index = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
    if (index === from) {
      keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${to}]`);
    } else {
      if (shift === 'up' && index > from && index <= to) {
        keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${index - 1}]`);
      } else if (shift === 'down' && index < from && index >= to) {
        keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${index + 1}]`);
      }
    }
    return {
      ...field,
      keyMap: keyMapArr.join('.')
    }
  });
  const shiftedFormulatedField = Object.keys(formulatedField[variableId]).reduce((acc, keyMap) => {
    const formula = formulatedField[variableId][keyMap];
    const keyMapArr = keyMap.split('.');
    const index = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
    if (index === from) {
      keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${to}]`);
    } else {
      if (shift === 'up' && index > from && index <= to) {
        keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${index - 1}]`);
      } else if (shift === 'down' && index < from && index >= to) {
        keyMapArr[0] = keyMapArr[0].replace(/\[\d+\]/, `[${index + 1}]`);
      }
    }
    return {
      ...acc,
      [keyMapArr.join('.')]: formula
    }
  }, {})
  dispatch(setAnnotatedVariableField(variableId, shiftedFields));
  dispatch(setFormulatedVariableField(variableId, shiftedFormulatedField));
  dispatch(clearMovedAnnotationItem());
  dispatch(clearStoredFormData());
  return rawDataFields;
}

export const setExpandedAnnotationItems = (expandedItems) => ({
  type: 'SET_EXPANDED_ANNOTATION_ITEMS',
  payload: expandedItems
});

export const setExpandedAnnotationFields = (expandedFields) => ({
  type: "SET_EXPANDED_ANNOTATION_FIELDS",
  payload: expandedFields
});

export const setupFormulatedField = wrapper => ({
  type: "SETUP_FORMULATED_VARIABLE_FIELD",
  payload: wrapper
});

export const setFormulatedVariableField = (variableId, data) => ({
  type: "SET_FORMULATED_VARIABLE_FIELD",
  payload: {
    variableId,
    data
  }
});

export const storeFormulatedVariableField = (variableId, fieldName, formula) => ({
  type: "STORE_FORMULATED_VARIABLE_FIELD",
  payload: {
    variableId,
    fieldName,
    formula
  }
});

export const moveAnnotationFieldPosition = (variableId, annotationItemIndex, fieldName, fromIndex, toIndex) => ({
  type: 'MOVE_ANNOTATION_FIELD_POSITION',
  payload: {
    variableId,
    annotationItemIndex,
    fieldName,
    fromIndex,
    toIndex
  }
});

export const reorderAnnotationField = (variableId, from, to, formData, annotationItemIndex, fieldName) => (dispatch, getState) => {
  const { annotatedField, formulatedField } = getState().annotation;
  // Shifting order on form data
  const rawDataFields = cloneDeep(formData);
  const moved = cloneDeep(formData[from]);
  rawDataFields.splice(from, 1);
  rawDataFields.splice(to, 0, moved);
  // Shifting order on annotated field data
  const annotatedFields = annotatedField[variableId];
  const shift = from < to ? 'up' : 'down';
  const shiftedFields = annotatedFields.map(field => {
    const keyMapArr = field.keyMap.split('.');
    const annotationIndex = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
    const fieldIndexStr = keyMapArr[1].match(/\[\d+\]/);
    const currentFieldName = keyMapArr[1].slice(0, keyMapArr[1].indexOf('['));
    if (annotationIndex === annotationItemIndex && fieldName === currentFieldName && fieldIndexStr) {
      const index = parseInt(fieldIndexStr[0].match(/\d+/));
      if (index === from) {
        keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${to}]`);
      } else {
        if (shift === 'up' && index > from && index <= to) {
          keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${index - 1}]`);
        } else if (shift === 'down' && index < from && index >= to) {
          keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${index + 1}]`);
        }
      }
    }
    return {
      ...field,
      keyMap: keyMapArr.join('.')
    }
  });
  const shiftedFormulatedField = Object.keys(formulatedField[variableId]).reduce((acc, keyMap) => {
    const formula = formulatedField[variableId][keyMap];
    const keyMapArr = keyMap.split('.');
    const annotationIndex = parseInt(keyMapArr[0].match(/\[\d+\]/)[0].match(/\d+/));
    const fieldIndexStr = keyMapArr[1].match(/\[\d+\]/);
    const currentFieldName = keyMapArr[1].slice(0, keyMapArr[1].indexOf('['));
    if (annotationIndex === annotationItemIndex && fieldName === currentFieldName && fieldIndexStr) {
      const index = parseInt(fieldIndexStr[0].match(/\d+/));
      if (index === from) {
        keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${to}]`);
      } else {
        if (shift === 'up' && index > from && index <= to) {
          keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${index - 1}]`);
        } else if (shift === 'down' && index < from && index >= to) {
          keyMapArr[1] = keyMapArr[1].replace(/\[\d+\]/, `[${index + 1}]`);
        }
      }
    }
    return {
      ...acc,
      [keyMapArr.join('.')]: formula
    }
  }, {})
  dispatch(setAnnotatedVariableField(variableId, shiftedFields));
  dispatch(setFormulatedVariableField(variableId, shiftedFormulatedField));
  dispatch(clearMovedAnnotationItem());
  dispatch(clearStoredFormData());
  return rawDataFields;
}

export const storeFormData = (formData) => ({
  type: 'STORE_FORM_DATA',
  payload: formData
});

export const clearStoredFormData = () => ({
  type: 'CLEAR_STORED_FORM_DATA'
});

export const setSelectedPageRange = (sectionId, selectedPages) => ({
  type: 'SET_SELECTED_PAGE_RANGE',
  payload: {
    sectionId,
    selectedPages
  }
});

export const removeSelectedPageRange = (sectionId, selectedPageIds) => ({
  type: 'REMOVE_SELECTED_PAGE_RANGE',
  payload: {
    sectionId,
    selectedPageIds
  }
});

export const changeReportType = (id, reportType) => ({
  type: 'CHANGE_REPORT_TYPE',
  payload: {
    id,
    reportType
  }
});

export const confirmRevisedReport = (id) => async dispatch => {
   const { type, payload } = await apiRequest(
    dispatch,
    `CONFIRM_REVISED_REPORT`,
    'post',
    `/comments/confirm-revised/${id}`,
  );
  if (type === 'CONFIRM_REVISED_REPORT_SUCCESS') {
    dispatch(changeReportType(id, 'REPORT-DONE'))
    if (payload.reportStatus === 'complete') {
      dispatch(setTaskStatus("accepted"));
      dispatch(updateLoadedVariables(3));
    }
  }
}

export const storeReportScrollPosition = (scrollPosition) => ({
  type: 'STORE_REPORT_SCROLL_POSITION',
  payload: scrollPosition
});

export const storeNoteScrollPosition = (scrollPosition) => ({
  type: 'STORE_NOTE_SCROLL_POSITION',
  payload: scrollPosition
});
