export function isFieldPersonName(dataType, fieldName) {
  const arrFieldName = fieldName.split('.');
  return dataType.toLowerCase() === 'person' && arrFieldName[1] === 'name';
}

export function isDerivative(type) {
  return type.includes('derivative');
}

export function isEnum(attr) {
  return Array.isArray(attr) && typeof attr[0] !== 'object';
}

export function compact(name) {
  return name.toLowerCase().replaceAll(' ', '-');
}

export function getIconName(type) {
  const ICONS = {
    'text-basic': 'text-highlight',
    'number-basic': 'numerical',
    'date-basic': 'calendar',
    'percentage-basic': 'percentage',
    enum: 'menu',
  };
  return ICONS[type];
}
