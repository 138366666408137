import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import AdvancedTableHeader from '../../components/AdvancedTable/AdvancedTableHeader';
import AdvancedTableAction from '../../components/AdvancedTable/AdvancedTableAction';
import { getComparator, stableSort } from '../../components/AdvancedTable';
import Chips from '../../components/Chips';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from 'react-loading-skeleton';
import Button from '../../components/@setproduct-ui/core/Button';
import SearchInput from './SearchInput';
import Select from 'react-select';
import FlexWrapper from '../../components/FlexWrapper';
import PATH_URL from '../../routers/path';
import tableStyles from '../../components/AdvancedTable/table.module.css';
import { useTableStyle } from '../../components/AdvancedTable/tableStyle';
import { tableSelectStyle } from '../../components/Select/tableSelectStyle';
import { ClickOutsideContainer } from '../../components/utils/ClickOutsideContainer';
import Menu from '../../components/@setproduct-ui/core/Menu';
import MenuItem from '../../components/@setproduct-ui/core/Menu/MenuItem';
import FormCreateTask from './FormCreateTask';
import FormEditTask from './FormEditTask';
import DeleteTaskDialog from './DeleteTaskDialog';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../redux/api/loading';
import { removeTask } from '../../redux/actions/admin';
import { makeStyles } from '@material-ui/core';
import FormTransfer from './FormTransfer';

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TASK NAME',
  },
  {
    id: 'document',
    numeric: false,
    disablePadding: false,
    label: 'DOCUMENT',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
  { id: 'section', numeric: false, disablePadding: false, label: 'SECTION' },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
  { id: 'menu', numeric: false, disablePadding: false, label: '' },
];

function EnhancedMenu(props) {
  const { row, setMenu, setFormData, setFormDialog, showDialog, onClose } =
    props;
  return (
    <ClickOutsideContainer onClose={onClose}>
      <Menu
        type="dense"
        view="smooth"
        color="default"
        className={tableStyles.menu}
      >
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Edit task"
          onClick={() => {
            setMenu(null);
            setFormData({
              id: row.id,
              name: row.name,
              annotator: {
                id: row.annotator.id,
                name: row.annotator.name,
              },
              reviewer: {
                id: row.reviewer.id,
                name: row.reviewer.name,
              },
            });
            setFormDialog({ open: true, type: 'edit' });
          }}
        />
        <MenuItem
          type="dense"
          view="smooth"
          color="default"
          text="Delete task"
          onClick={() => showDialog(row.id)}
        />
      </Menu>
    </ClickOutsideContainer>
  );
}

const slimTableSelectStyle = {
  ...tableSelectStyle,
  control: (provided) => ({
    ...provided,
    background: 'var(--blue70)',
    borderColor: 'var(--blue70)',
    height: 37,
  }),
};

const CHIP_COLORS = {
  todo: 'default',
  ongoing: 'primary',
  'under review': 'warning',
  accepted: 'success',
  rejected: 'danger',
  'need revision': 'danger-alt',
};
const ROWS_PER_PAGE = 50;

export default function TableTask() {
  const dispatch = useDispatch();
  const classes = useTableStyle();
  const customClasses = useCustomStyles();
  const [menu, setMenu] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    id: null,
  });
  const [formDialog, setFormDialog] = useState({ open: false, type: 'create' });
  const [formData, setFormData] = useState({});
  const [search, setSearch] = useState({
    name: '',
    type: { value: '', label: 'Type' },
    status: { value: '', label: 'Status' },
    applied: [],
  });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('task_title');
  const [page, setPage] = useState(0);
  const [typeStats, setTypeStats] = useState({
    chapter: 0,
    subchapter: 0,
    variable: 0,
  });
  const [statusStats, setStatusStats] = useState({
    todo: 0,
    ongoing: 0,
    'under review': 0,
    accepted: 0,
    rejected: 0,
    'need revision': 0,
  });
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const loading = useSelector(createLoadingSelector(['GET_USER_TASKS']));
  const loadingMutation = useSelector(createLoadingSelector(['REMOVE_TASK']));
  const currentUser = useSelector((state) => state.admin.users.selected);
  const tasks = useSelector((state) => state.admin.users.selected.tasks);
  const [selectedTasks, selectTask] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const referencedTask = tasks[0];
      const filteredTasks = tasks
        .filter(applySearch)
        .filter(
          (task) =>
            referencedTask.annotator.id === task.annotator.id &&
            referencedTask.reviewer.id === task.reviewer.id
        );
      selectTask(filteredTasks);
    } else {
      selectTask([]);
    }
  };

  const handleClick = (event, task) => {
    if (selectedTasks.length > 0) {
      const referencedTask = selectedTasks[0];
      if (
        referencedTask.annotator.id === task.annotator.id &&
        referencedTask.reviewer.id === task.reviewer.id
      ) {
        const selected = selectedTasks.find(
          (selected) => selected.id === task.id
        );
        if (!selected) {
          selectTask([...selectedTasks, task]);
        } else {
          const newSelected = selectedTasks.filter(
            (selected) => selected.id !== task.id
          );
          selectTask(newSelected);
        }
      }
    } else {
      selectTask([...selectedTasks, task]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeDialog = () => {
    setDialog({ open: false, id: null });
    setMenu(null);
  };

  const showDialog = (id) => {
    setDialog({ open: true, id });
  };

  const handleDelete = () => {
    const { id } = dialog;
    const isSuccess = dispatch(removeTask(id, { refetchAll: false }));
    if (isSuccess) {
      closeDialog();
      setMenu(null);
    }
  };

  const applySearch = useCallback(
    (task) => {
      const { name, type, status } = search;
      let isMatch = true;
      if (name) {
        const regex = RegExp(`${name}`, 'i');
        isMatch =
          isMatch &&
          (regex.test(task.name) ||
            regex.test(task.document) ||
            regex.test(task.section));
      }
      if (type.value) {
        isMatch = isMatch && task.type === type.value;
      }
      if (status.value) {
        isMatch = isMatch && task.status === status.value;
      }
      return isMatch;
    },
    [search]
  );

  const isSelected = (id) => {
    const selected = selectedTasks.find((selected) => selected.id === id);
    return selected ? true : false;
  };

  const isInactiveOrAdmin =
    currentUser.status === 'inactive' || currentUser.role === 'admin';

  useEffect(() => {
    const initialStats = {
      type: { chapter: 0, subchapter: 0, variable: 0 },
      status: {
        todo: 0,
        ongoing: 0,
        'under review': 0,
        accepted: 0,
        rejected: 0,
        'need revision': 0,
      },
    };
    const updatedStats = tasks.filter(applySearch).reduce((acc, task) => {
      const typeName = task.type.toLowerCase();
      return {
        status: { ...acc.status, [task.status]: acc.status[task.status] + 1 },
        type: {
          ...acc.type,
          [typeName]: acc.type[typeName] + 1,
        },
      };
    }, initialStats);
    const applied = search.applied;
    if (!applied.includes('status') || applied.includes('name'))
      setStatusStats(updatedStats.status);
    if (!applied.includes('type') || applied.includes('name'))
      setTypeStats(updatedStats.type);
  }, [dispatch, tasks, search.applied, applySearch]);

  return (
    <>
      <div className={tableStyles.tableContainer}>
        <div className={tableStyles.tableToolbar} style={{ padding: 12 }}>
          <FlexWrapper
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <SearchInput
              defaultValue={search.taskName}
              onApplySearch={(value) => {
                setSearch(({ applied, ...prev }) => {
                  return {
                    ...prev,
                    name: value,
                    applied:
                      value === ''
                        ? applied.filter((i) => i !== 'name')
                        : !applied.includes('name')
                        ? applied.concat('name')
                        : applied,
                  };
                });
              }}
              placeholder="Search"
              style={{ width: 'calc(100% - 477px)', marginRight: 15 }}
            />
            <FlexWrapper
              justifyContent="space-between"
              alignItems="center"
              width="477px"
            >
              <Select
                name="type"
                value={search.type}
                isSearchable={false}
                defaultValue={{ value: '', label: 'Type' }}
                onChange={(selected) => {
                  setSearch(({ applied }) => {
                    return {
                      ...search,
                      type: selected,
                      applied:
                        selected.value === ''
                          ? applied.filter((i) => i !== 'type')
                          : !applied.includes('type')
                          ? applied.concat('type')
                          : applied,
                    };
                  });
                }}
                options={[
                  { value: '', label: 'All Type' },
                  {
                    value: 'Chapter',
                    label: `Chapter (${typeStats.chapter})`,
                  },
                  {
                    value: 'Subchapter',
                    label: `Sub Chapter (${typeStats.subchapter})`,
                  },
                  {
                    value: 'Variable',
                    label: `Variable (${typeStats.variable})`,
                  },
                ]}
                styles={slimTableSelectStyle}
              />
              <Select
                name="status"
                value={search.status}
                isSearchable={false}
                defaultValue={{ value: '', label: 'Status' }}
                onChange={(selected) => {
                  setSearch(({ applied }) => {
                    return {
                      ...search,
                      status: selected,
                      applied:
                        selected.value === ''
                          ? applied.filter((i) => i !== 'status')
                          : !applied.includes('status')
                          ? applied.concat('status')
                          : applied,
                    };
                  });
                }}
                options={[
                  { value: '', label: 'All Status' },
                  {
                    value: 'todo',
                    label: `To Do (${statusStats.todo})`,
                  },
                  {
                    value: 'ongoing',
                    label: `Ongoing (${statusStats.ongoing})`,
                  },
                  {
                    value: 'under review',
                    label: `Under Review (${statusStats['under review']})`,
                  },
                  {
                    value: 'accepted',
                    label: `Accepted (${statusStats.accepted})`,
                  },
                  {
                    value: 'rejected',
                    label: `Rejected (${statusStats.rejected})`,
                  },
                  {
                    value: 'need revision',
                    label: `Need Revision (${statusStats['need revision']})`,
                  },
                ]}
                styles={{
                  ...slimTableSelectStyle,
                  container: (provided) => ({
                    ...provided,
                    width: 170,
                    marginRight: 15,
                    zIndex: 9,
                  }),
                }}
              />
              <Button
                view="filled"
                color={isInactiveOrAdmin ? 'default' : 'success'}
                text="Assign Task"
                loading={false}
                disabled={
                  currentUser.status === 'inactive' ||
                  currentUser.role === 'admin'
                }
                onClick={() => setFormDialog({ open: true, type: 'create' })}
                className={tableStyles.mainButton}
                style={{
                  background: isInactiveOrAdmin
                    ? 'var(--grey5)'
                    : 'var(--green70)',
                  height: 37,
                  boxShadow: 'none',
                }}
              />
            </FlexWrapper>
          </FlexWrapper>
        </div>
        <TableContainer className={customClasses.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <AdvancedTableHeader
              classes={classes}
              selectionCounter={selectedTasks.length}
              order={order}
              orderBy={orderBy}
              onSelect={handleSelectAllClick}
              onSort={handleRequestSort}
              rowCount={tasks.filter(applySearch).length}
              columns={columns}
            />
            <TableBody>
              {loading ? (
                Array(4)
                  .fill(1)
                  .map((_, index) => (
                    <TableRow key={index} role="checkbox">
                      <TableCell className={classes.checkbox}>
                        <Skeleton
                          height={20}
                          width={20}
                          style={{ marginLeft: 10 }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.cell}
                      >
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={100} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Skeleton width={30} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : tasks.filter(applySearch).length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} className={classes.emptyResult}>
                    No results found
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(
                  tasks.filter(applySearch),
                  getComparator(order, orderBy)
                )
                  .slice(
                    page * ROWS_PER_PAGE,
                    page * ROWS_PER_PAGE + ROWS_PER_PAGE
                  )
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.checkbox}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                          className={classes.cell}
                        >
                          <Link
                            to={PATH_URL.SPECTATE.replace(':task_id', row.id)}
                          >
                            {row.name}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.document}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.type}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.section}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Chips
                            color={CHIP_COLORS[row.status]}
                            text={row.status.toUpperCase()}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{ position: 'relative' }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (menu === row.id) setMenu(null);
                              else setMenu(row.id);
                            }}
                          >
                            <Icon icon="more" iconSize={16} />
                          </IconButton>
                          {menu === row.id && (
                            <EnhancedMenu
                              row={row}
                              setMenu={setMenu}
                              showDialog={showDialog}
                              setFormDialog={setFormDialog}
                              setFormData={setFormData}
                              onClose={() => setMenu(null)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
            <TableBody>
              <TableRow style={{ height: 18 }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.pagination} elevation={0}>
          {selectedTasks.length > 0 && (
            <AdvancedTableAction
              selectionCounter={selectedTasks.length}
              actions={[
                {
                  icon: 'exchange',
                  onClick: () => {
                    setOpenTransferDialog(true);
                  },
                },
              ]}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={tasks.filter(applySearch).length}
            rowsPerPage={ROWS_PER_PAGE}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} of ${count} rows`
            }
            labelRowsPerPage={'Show'}
            page={page}
            onChangePage={handleChangePage}
          />
        </Paper>
      </div>
      <DeleteTaskDialog
        data={dialog}
        onCloseDialog={closeDialog}
        handleDelete={handleDelete}
        loading={loadingMutation}
      />
      <FormCreateTask
        isOpen={formDialog.open && formDialog.type === 'create'}
        onClose={() => setFormDialog({ ...formDialog, open: false })}
        user={currentUser}
      />
      <FormEditTask
        isOpen={formDialog.open && formDialog.type === 'edit'}
        onClose={() => setFormDialog({ ...formDialog, open: false })}
        form={formData}
        user={currentUser}
      />
      <FormTransfer
        selectedTasks={selectedTasks}
        isOpen={openTransferDialog}
        onClose={() => {
          setOpenTransferDialog(false);
        }}
        onReset={() => {
          selectTask([]);
        }}
      />
    </>
  );
}

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxHeight: 'calc(100vh - 420px)',
    flexDirection: 'column',
  },
}));
