import AdminAnnotator from '../Pages/AdminAnnotator';
import Annotation from '../Pages/Annotation';
import AnnotatorDashboard from '../Pages/AnnotatorDashboard';
import AnnotatorsTeam from '../Pages/AnnotatorsTeam';
import Companies from '../Pages/Companies';
import DocumentGroup from '../Pages/DocumentGroup';
import DocumentManagement from '../Pages/DocumentManagement';
import DocumentDetail from '../Pages/DocumentDetail';
import DocumentPreview from '../Pages/DocumentPreview';
import ForgotPassword from '../Pages/ForgotPassword';
import Login from '../Pages/Login';
import Register from '../Pages/Register';
import ResetPassword from '../Pages/ResetPassword';
// TODO: Remove it later once annotate review page is stable
// import Review from '../Pages/Review'; 
import AnnotateReview from '../Pages/AnnotateReview';
import ReviewerDashboard from '../Pages/ReviewerDashboard';
import Spectate from '../Pages/Spectate';
import Tasks from '../Pages/Tasks';
import Templates from '../Pages/TemplateSection';
import UnverifiedEmail from '../Pages/UnverifiedEmail';
import Users from '../Pages/Users';
import VerifyEmail from '../Pages/VerifyEmail';
import WaitingApproval from '../Pages/WaitingApproval';
import TasksReviewer from '../Pages/TasksReviewer';
import UserDetail from '../Pages/UserDetail';
import OverviewVariable from '../Pages/VariableOverview';
import EmailAction from '../Routes/EmailAction';
import {
  UserIsAdmin,
  UserIsAnnotator,
  UserIsReviewer,
  UserWaitingApproval,
} from '../utils/Routes/AuthRoutingHOC';
import PATH_URL from './path';

const routes = {
  authenticated: {
    admin: [
      {
        layout: 'MainLayout',
        routes: [
          {
            path: PATH_URL.USERS,
            component: UserIsAdmin(Users),
            title: 'Users',
            icon: 'home',
          },
          {
            path: PATH_URL.TASKS,
            component: UserIsAdmin(Tasks),
            title: 'Tasks',
            icon: 'home',
          },
          {
            path: PATH_URL.DOCUMENTS,
            component: UserIsAdmin(DocumentManagement),
            title: 'Documents',
            icon: 'home',
          },
          {
            path: PATH_URL.DOCUMENTSDETAIL,
            component: UserIsAdmin(DocumentDetail),
            title: 'Documents - Dashboard',
            icon: 'home',
          },
          {
            path: PATH_URL.DOCUMENT_GROUPS,
            component: UserIsAdmin(DocumentGroup),
            title: 'Document Group',
            icon: 'folder',
          },
          {
            path: PATH_URL.COMPANIES,
            component: UserIsAdmin(Companies),
            title: 'Companies',
            icon: 'home',
          },
          {
            path: PATH_URL.TEMPLATES,
            component: UserIsAdmin(Templates),
            title: 'Templates',
            icon: 'home',
          },
          {
            path: PATH_URL.ANNOTATORS_TEAMS,
            component: UserIsAdmin(AnnotatorsTeam),
            title: 'Annotator Team',
            icon: 'home',
          },
          {
            path: PATH_URL.ANNOTATORS,
            component: UserIsAdmin(AdminAnnotator),
            title: 'Annotators',
            icon: 'home',
          },
          {
            path: PATH_URL.WAITING_APPROVAL,
            component: UserWaitingApproval(WaitingApproval),
            title: 'Annotators',
            icon: 'home',
          },
          {
            path: PATH_URL.USER_DETAIL,
            component: UserIsAdmin(UserDetail),
            title: 'Users - Dashboard',
            icon: 'home',
          },
          {
            path: PATH_URL.VARIABLES,
            component: UserIsAdmin(OverviewVariable),
            title: 'Variables',
            icon: 'variable',
          },
        ],
      },
      {
        layout: 'FullLayout',
        routes: [
          {
            path: PATH_URL.DOCUMENT_PREVIEW,
            component: UserIsAdmin(DocumentPreview),
            title: 'Document Preview',
            icon: 'home',
          },
          {
            path: PATH_URL.UNVERIFIED_EMAIL,
            component: UnverifiedEmail,
            title: 'Annotators',
            icon: 'home',
          },
          {
            path: PATH_URL.SPECTATE,
            component: UserIsAdmin(Spectate),
            title: 'Spectate',
            icon: 'home',
          },
        ],
      },
    ],
    annotator: [
      {
        layout: 'MainLayout',
        routes: [
          {
            path: PATH_URL.DASHBOARD,
            component: UserIsAnnotator(AnnotatorDashboard),
          },
        ],
      },
      {
        layout: 'FullLayout',
        routes: [
          {
            path: PATH_URL.ANNOTATION,
            component: UserIsAnnotator(Annotation),
            title: 'Annotate',
          },
        ],
      },
    ],
    reviewer: [
      {
        layout: 'MainLayout',
        routes: [
          {
            path: PATH_URL.DASHBOARD,
            component: UserIsReviewer(ReviewerDashboard),
          },
          {
            path: PATH_URL.TASKS,
            component: UserIsReviewer(TasksReviewer),
          },
        ],
      },
      {
        layout: 'FullLayout',
        routes: [
          {
            path: PATH_URL.REVIEW,
            component: UserIsReviewer(AnnotateReview),
            title: 'Review',
          },
        ],
      },
    ],
  },
  notAuthenticated: {
    user: [
      {
        layout: 'AuthLayout',
        routes: [
          {
            path: PATH_URL.LOGIN,
            component: Login,
            title: 'Login',
          },
          {
            path: PATH_URL.REGISTER,
            component: Register,
            title: 'Register',
          },
          {
            path: PATH_URL.FORGOT_PASSWORD,
            component: ForgotPassword,
            title: 'Forgot password',
          },
          {
            path: PATH_URL.RESET_PASSWORD,
            component: ResetPassword,
            title: 'Reset Password',
          },
        ],
      },
    ],
  },
  public: {
    user: [
      {
        layout: 'FullLayout',
        routes: [
          {
            path: PATH_URL.EMAIL_ACTION,
            component: EmailAction,
          },
        ],
      },
      {
        layout: 'AuthLayout',
        routes: [
          {
            path: PATH_URL.VERIFY_EMAIL,
            component: VerifyEmail,
          },
        ],
      },
    ],
  },
};

export default routes;
